
import styled from "styled-components";
import { FaMagento } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Container } from '../../globalStyles';
import { MdClose } from 'react-icons/md';

export const Background = styled.div`
  width: 100%;
  height: 100%;
 /*  background: rgba(0, 0, 0, 0.8); */
 background: white;
 /* border: 3px dotted red; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 72;
`;

export const CloseButtonWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:flex-end;
background:red;
 /* background:purple; */
/* height: 60px; */
padding: 20x 10px 5px 10px;
min-width: 350px;
 font-size: 1.8rem !important;
/* margin-bottom: 10px; */
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 1.2rem !important;
   padding: 5px 10px 2px 10px ;
  /* align-items: center; */
  min-width: 80%;
 }
 
`

export const LabelWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:flex-start;
align-self: left;
/* background:yellow; */
 /* background:purple; */
/* height: 60px; */
padding: 0px 10px;
min-width: 350px;
 font-size: 1.0rem !important;
  font-weight: 500 !important;        
    color :#006633 !important;
/* margin: 0px; */
/* margin-bottom: 10px; */
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 0.9rem !important;
   padding: 0px 5px;
  /* align-items: center; */
  min-width: 80%;
 }
 
`

export const ReceiptItemWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:flex-start;
align-self: left;
/* background:yellow; */
 /* background:purple; */
/* height: 60px; */
padding: 0px 20px;
min-width: 350px;
 font-size: 1rem !important;
  font-weight: 300 !important;        
    color :#000000 !important;
/* margin: 0px; */
margin-top: -4px;
margin-bottom: 5px;
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 1.0rem !important;
   padding: 0px 20px;
  /* align-items: center; */
  min-width: 80%;
 }
 
`


export const PWrapper = styled.div`
  display: flex;
flex-direction: column;
justify-content:center;
align-self: center;
text-align: center;
/* border: 1px solid #063; */
/* background:purple; */
 /* background:purple; */
/* height: 60px; */
/* padding: 0px 10px; */
width: 350px;

/* margin: 0px; */
 font-size: 0.9rem !important;
/* margin-bottom: 10px; */
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 0.9rem !important;
     padding: 5px 10px 2px 10px ;
   /* padding: 0px 10px; */
  /* align-items: center; */
  min-width: 80%;
 }
`;


export const ModalWrapper = styled.div`
  width: 90%;
  height: 90%;
   /* border: 3px dotted black; */
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: absolute;
  z-index: 73;
  border-radius: 10px;
`;


export const ModalContent = styled.div`
background: #ffffff;
/* border: 3px dotted red; */
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  z-index: 74;
  width: 100%;
  
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  /* position: absolute; */
  /* top: 20px; */
  /* right: 20px; */
  /* width: 32px; */
  /* height: 32px; */
  /* padding: 0; */
  z-index: 55;
`;


export const TranWraper = styled.div`
 
display: flex;
flex-direction: row;
justify-content:flex-start;
align-items: center                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             ;
/* align-self: center; */
background:${props => (props.color || '#00FFFF')}  ; 
/* color:${props => (props.bgColor || '#00FFFF')}  ;  */
/* background:red;  */

/* height: 60px; */
padding: 2px 10px 2px 10px;
width: 97%;
margin: 0px;
margin-bottom: 10px;
border-radius: 5px;
  border: 1px solid #063;
 
`
export const TranRowWraper = styled.div`
 
display: flex;
flex-direction: column;
justify-content:flex-start;
align-self: center;
/* background:${props => (props.bgColor || '#00FFFF')}  ;  */
/* color:${props => (props.bgColor || '#00FFFF')}  ;  */
/* background:red;  */

/* height: 60px; */
/* padding: 2px 10px 2px 10px; */
width: 100%;
/* margin: 0px; */
/* margin-bottom: 10px; */
border-radius: 5px;
  /* border: 1px solid #063; */
 
`
export const TranRowImgWraper = styled.div`
 
display: flex;
flex-direction: column;
justify-content:flex-start;
align-items: center;
/* background:${props => (props.bgColor || '#00FFFF')}  ;  */
/* color:${props => (props.bgColor || '#00FFFF')}  ;  */
/* background:red;  */

/* height: 60px; */
/* padding: 2px 10px 2px 10px; */
/* width: 97%; */
/* margin: 0px; */
/* margin-bottom: 10px; */
border-radius: 5px;
  /* border: 1px solid #063; */
 
`

export const RefWraper = styled.div`
/* height: 70%; */
display: flex;
justify-content:flex-start;
align-items: left;
  /* border: 1px solid red; */
`

export const EmailRefTo = styled.h1`
    font-weight: 400;    
    font-size: 1rem !important;
    color :black !important;
   /*  padding: 0px 0px 10px 10px ; */
    text-align: left;
     width: 75%;  flex-grow: 3;
     align-items: center;
       /* border: 1px solid black; */
       
@media screen and (max-width:768px) {
    font-size: 0.8rem !important;
  /*   padding: 0px 0px 20px 10px ;   */
  align-items: center;
 }
`
export const EmailRefFrom = styled.h1`
    font-weight: 400;    
    font-size: 1rem !important;
    color :black !important;
   /*  padding: 0px 0px 10px 10px ; */
    text-align: left;
     width: 75%; 
     align-items: center;
     flex-grow: 3;
@media screen and (max-width:768px) {
    font-size: 0.8rem !important;
  /*   padding: 0px 0px 20px 10px ;   */
  align-items: center;
 }
`

export const BalanceRef = styled.h1`
    font-weight: 500;    
    font-size: 1rem !important;
    color: ${props => (props.balanceColor || '#000000')} !important ; 
   /*  padding: 0px 10px 10px 0px ; */
    text-align: right;
     width: 25%;
     flex-grow: 3;
@media screen and (max-width:768px) {
    font-size: 0.8rem !important;
 /*    padding: 0px 10px 10px 0px ;  */
  color: ${props => (props.balanceColor || '#FFFF00')} ;  
 
 }
`
export const TimeRef = styled.h1`
    font-weight: 300;    
    font-size: 0.8rem !important;
    color :black !important;
    padding: 5px 0px 0px 10px ;
    text-align: left;
@media screen and (max-width:768px) {
    font-size: 0.6rem !important;
    padding: 2px 0px 0px 10px ;  
 }
`

export const NavbarContainer = styled(Container)`
display: flex;
justify-content: space-between;
height: 80px;

${Container}

`

export const NavLogo = styled(Link)`
color:#fff; 
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
display: flex;
font-size: 2rem;
align-items: center;


`

export const NavIcon = styled(FaMagento)`
 margin-right: 0.5rem;

`
export const IconWrapper = styled.div`
max-width: 32px;
display: flex;
margin-right: 8px;
/* justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')}; */


`

export const MobileIcon = styled.div`
 display: none;
 background:#006633;


@media screen and (max-width: 960px) {
  display: block;
  background:#006633;
  position: absolute;
  top:0;
  right:0;
  transform: translate(-100%, 60%);
  font-size:1.8rem;
  cursor:pointer;
  
 }

`

export const NavMenu = styled.ul`
 display:flex;
 align-items: center;
 list-style: none;
 text-align: center;

 @media screen and (max-width: 960px) {
  display: flex;
  background:#006633;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  position: absolute;
  top:80px;
  left: ${({ click }) => (click ? 0 : '-100%')};
  opacity: 1;
  transition: all 0.5s ease;

 }
  
`

export const NavItem = styled.li`
height: 80px;
border-bottom: 2px solid transparent;

  &:hover  {
   border-bottom: 3px solid #03fcc2;
  
 }
  @media screen and (max-width: 960px) {
   width: 100%;
   
   &:hover  {
   border:none;
  
 }
 }

`
export const NavLinks = styled(Link)`

 color : #fff;
 display: flex;
 align-items: center;
 text-decoration: none;
 padding: 0.5rem 1rem;
 height: 100%;

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover  {
    color: #4b59f7;
    transition: all 0.3s ease;
   
    }
 
 }
`

export const NavItemBtn = styled.li`
@media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
 
 }
`

export const NavBtnLink = styled(Link)`
@media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
 
 }
`
export const EmailWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:center;
align-self: center;
/* background:purple; */
 /* background:purple; */
/* height: 60px; */
/* padding: 0px 10px; */
min-width: 350px;
/* margin: 0px; */
 font-size: 1.5rem !important;
/* margin-bottom: 10px; */
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 1.2rem !important;
   /* padding: 0px 10px; */
  /* align-items: center; */
  min-width: 80%;
 }
 
`

export const ImgT = styled.img`
/* padding: 8px; */
/* border: 0; */
 /* border: 2px solid purple; */
/* width: 40px;
height: 40px; */
width: 32px;
height:32px;
margin-right: 5px;
vertical-align: middle;
justify-content:center;
/* display: inline-block; */
/* margin-left: -40px;  */
@media screen and (max-width:768px) {   
    
   width: 24px;
height:24px;

 }


`