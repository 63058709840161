import logoWhite from "./PD_logo_white.svg";
import logoRed from "./PD_logo_red.svg";
import logoGreen from "./PD_logo_green.svg";
import logoYellow from "./PD_logo_yellow.svg";

import cancelRed from "./cancel.svg";
import checkGreen from "./check.png";
import plus from "./Plus.svg";
import minus from "./Minus.svg";
import equal from "./Equal.svg";
import down from "./down_svg.svg";

import logoTrSend from "./PD_tr_send.svg";
import logoTrRec from "./PD_tr_rec.svg";
import logoPySend from "./PD_py_send.svg";
import logoPyRec from "./PD_py_rec.svg";
// import logoFlSend from "./PD_logo_yellow.svg";
// import logoFlRec from "./PD_logo_yellow.svg";
import logoTdSend from "./PD_logo_red.svg";
import logoTdRec from "./PD_logo_green.svg";
import logoFlMob from "./PD_fl_mobilis.svg";
import logoFlOrd from "./PD_fl_ooredoo.svg";
import logoFlJez from "./PD_fl_jezzy.svg";



export const logoSVG_White = logoWhite;
export const logoSVG_green = logoGreen;
export const logoSVG_red = logoRed;
export const logoSVG_yellow = logoYellow;

export const cancelSvg = cancelRed;
export const checkSvg = checkGreen;
export const icon_plus = plus;
export const icon_minus = minus;
export const icon_equal = equal;
export const icon_down = down;

export const logo_TrSend = logoTrSend;
export const logo_TrRec = logoTrRec;
export const logo_PySend = logoPySend;
export const logo_PyRec = logoPyRec;
// export const logo_FlSend = logoFlSend;
// export const logo_FlRec = logoFlRec;
export const logo_TdSend = logoTdSend;
export const logo_TdRec = logoTdRec;
export const logo_FlMob = logoFlMob;
export const logo_FlOrd = logoFlOrd;
export const logo_FlJez = logoFlJez;
