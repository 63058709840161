import React from 'react';
import { createContext, useContext, useEffect, useState } from "react";
import {
 createUserWithEmailAndPassword,
 signInWithEmailAndPassword,
 onAuthStateChanged,
 signOut 
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

import { auth, usersCollectionRef } from "../Firebase";
//import { auth, usersCollectionRef } from "../Firebase";
//import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
//import { useNavigate } from "react-router-dom";


const userAuthContext = createContext();

//const INITIAL_STATE = {
// currentUser: JSON.parse(localStorage.getItem("user")) || null,
//};

//{console.log('initial state from storage',INITIAL_STATE)}
//context provider
export function UserAuthContextProvider({ children })
{
      const [isSignedIn, setIsSignedIn] = useState(false);
      const [user, setUser] = useState({});
      const [userData, setUserData] = useState({});
      const [userContacts, setUserContacts] = useState([]);
      const [loading, setLoading] = useState(true); 

      function signIn(email, password) {
      return signInWithEmailAndPassword(auth, email, password);
      }
      function signUp(email, password) {
      return createUserWithEmailAndPassword(auth, email, password);
      }




      function logOut() {
      return signOut(auth);
      }


   useEffect(() => {
          console.log("**  Started runing useEffect insid auth :  **");

            const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {

            /*    console.log("jamal from Auth change Auth user :", currentuser);
               console.log("jamal from Auth change Auth user.uid", currentuser.uid); */
               setUserContacts({});

                  setUser(currentuser);
                  //===================
                  if (currentuser) {
                     const docRef = doc(usersCollectionRef, currentuser.uid);
                     const docSnap = await getDoc(docRef);                     
                           if (docSnap.exists()) {
                              //console.log("jimjam Document data:", docSnap.data());
                              setUserData(docSnap.data());
                              let tArray = [];
                              for (let i = 0; i < docSnap.data().transactions.length; i++) {
                                 tArray[2*i] = docSnap.data().transactions[i].from;
                                 tArray[2 * i + 1] = docSnap.data().transactions[i].to;                                 
                              }
                              let uniqueList = [...new Set(tArray)];
                              for (let j = 0; j < uniqueList.length; j++) {
                                 if (uniqueList[j] === currentuser.email) {
                                    uniqueList.splice(j, 1);
                                 }
                              }
                              setUserContacts(uniqueList);

                              
                        } else {
                           // doc.data() will be undefined in this case
                           //console.log("jimjamNo such document!");
                        }
                  }  //=====================
               localStorage.setItem("user", JSON.stringify(currentuser));
               localStorage.setItem("userData", JSON.stringify(userData));
               setLoading(false);
            });

            return () => {unsubscribe();    };
      }, []);

      return (
         <userAuthContext.Provider value={{
            user, userData, setUserData, userContacts,
            setUserContacts, signIn, signUp, logOut,
            isSignedIn, setIsSignedIn
         }} >
            {!loading && children}
      </userAuthContext.Provider>
      );
}

      export function useUserAuth() {
      return useContext(userAuthContext);
      }
