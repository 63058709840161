
import styled from "styled-components";
import { FaMagento } from 'react-icons/fa'
import { Link } from 'react-router-dom'



export const NavD = styled.nav`
background:#006633;
height: 60px;
display: flex;
flex-direction: row;
/* justify-self: flex-end; */
/* justify-content:center; */
justify-content:space-between ;
align-self:flex-end ;
align-items: center;
font-size: 1.2rem;
/* position: fixed; 
bottom:0;
right:0; */
width: 100%;
z-index: 999;
display:none;
padding-top:10px 10px 10px 10px;

@media screen and (max-width:768px) {
/*   display: block; */
  flex-direction: row;
  display:flex;
  position: fixed; 
  bottom:0;
  right:0;


  
 }



`

export const SLinkContainer = styled.div`           
   /*  margin: 8px 0; */
    display: flex;
    flex-direction: column;
    width:100%;
    height:60px;
    justify-content: center;
     align-items: center;
     text-align: center;    

     padding: 0px 5px 0px 5px;
   /*   border: 2px solid red; */

   @media screen and (max-width:768px) {
    /*   display: block; */
     justify-content: center;
      align-items: center;
     /*    border: 2px solid #ddd; */
 }
    :hover {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.bg3};
    }
`;

export const SLink = styled(Link)`               
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    font-size: 12px;
   padding: 0px  0px 0px  0px;
    width:100%;
  @media screen and (max-width:768px) {
    /*   display: block; */
     justify-content: center; 
     text-align: center;  
     align-items: center; 
     color:inherit;
     width:100%;
      padding: 2px  0px 0px  0px;
    /*  border: 2px solid black; */
 }
    
`;
export const SLinkIcon = styled.div`

    padding: 0px 0px;
    display: flex;
    width:100%;
    svg {
        font-size: 18px;
    }
    @media screen and (max-width:768px) {              
    /*   display: block; */
    width:100%;
     justify-content: center;
     align-items: center;
     padding: 0px  0px 2px  0px;
     
     
      

  
 }
`;


export const NavLogo = styled(Link)`
color:#fff; 
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
display: flex;
font-size: 2rem;
align-items: center;
background: white;


`

export const NavIcon = styled(FaMagento)`
 margin-right: 0.5rem;

` 
export const IconWrapper = styled.div`
max-width: 32px;
display: flex;
margin-right: 8px;
/* justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')}; */


`

export const MobileIcon = styled.div`
 display: none;
 background:#006633;


@media screen and (max-width: 960px) {
  display: block;
  background:#006633;
  position: absolute;
  top:0;
  right:0;
  transform: translate(-100%, 60%);
  font-size:1.8rem;
  cursor:pointer;
  
 }

`



export const NavItem = styled.li`
height: 80px;
border-bottom: 2px solid transparent;

  &:hover  {
   border-bottom: 3px solid #03fcc2;
  
 }
  @media screen and (max-width: 960px) {
   width: 100%;
   
   &:hover  {
   border:none;
  
 }
 }

`
export const NavLinks = styled(Link)`

 color : #fff;
 display: flex;
 align-items: center;
 text-decoration: none;
 padding: 0.5rem 1rem;
 height: 100%;

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover  {
    color: #4b59f7;
    transition: all 0.3s ease;
   
    }
 
 }
`

export const NavItemBtn = styled.li`
@media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
 
 }
`

export const NavBtnLink = styled(Link)`
@media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
 
 }
`

export const SLinkLabel = styled.span`
    display: block;
    flex: 1;
    color:#fff;
    
`;