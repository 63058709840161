import React from 'react';
import { partnersObjOne} from '../Data';

import { InfoSecPar} from '../../Components';
// import Navbar from '../../Components/Navbar/Navbar'; 
// import NavbarD from '../../Components/NavbarD/NavbarD'; 
// import Sidebar from '../../Components/Sidebar/Sidebar'; 
// import SidebarD from '../../Components/SidebarD/SidebarD'; 
//import { tradeObjOne, tradeObjTwo, tradeObjThree, tradeObjFour, tradeObjFive, tradeObjSix, tradeObjSeven } from '../Data';

 const Partners = () => {
 return (
  <>
   <InfoSecPar {...partnersObjOne} />
  </>
 )
}

export default Partners;