import React,{ StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'

import i18next from "i18next";
import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

/* i18next.use(HttpApi).init(i18nextOptions); */

i18next.use(LanguageDetector).use(HttpApi).init({
  interpolation: { escapeValue: false },
  /* lng: document.querySelector('html').lang,  */
  supportedLngs:['en','ar'],
  fallbackLang: "en",
  detection: {
    order: ['cookie','htmlTag', 'localStorage','path', 'subdomain'],
    caches:['cookie'],
  },
  backend: {
    loadPath: '/assets/locales/{{lng}}/translation.json',
  },
  react:{useSuspense:false},
  
});



const rootElement =  document.getElementById('root');
const root = createRoot(rootElement);


root.render(
  <StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </StrictMode>,
);