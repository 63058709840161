import { initializeApp } from "firebase/app";
import { getFirestore,collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// Follow this pattern to import other Firebase services
// import {getDatabase } from 'firebase/database';
//import { collection, addDoc , getDocs} from "firebase/firestore"; 

// If you're not using Code Sandbox, never hard-code the keys! Add them in your .env file and link them here
 const firebaseConfig ={
   apiKey: "AIzaSyAVOiQ97q2nuJoRmpdKydyduHmqBTwhcYQ",
  authDomain: "dzdinar-c02f1.firebaseapp.com",
  databaseURL: "https://dzdinar-c02f1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dzdinar-c02f1",
  storageBucket: "dzdinar-c02f1.appspot.com",
  messagingSenderId: "573902340318",
  appId: "1:573902340318:web:446c0e4b60bd7493da824f"
}; 

/* const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}; 

apiKey: "AIzaSyAVOiQ97q2nuJoRmpdKydyduHmqBTwhcYQ",
*/


const firebaseApp = initializeApp(firebaseConfig);


export const auth = getAuth(firebaseApp);
export const dbb = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const usersCollectionRef = collection(dbb, "users");

 
//app.auth()
//export default app


// try {
//   const docRef =  addDoc(collection(db, "users"), {
//     first: "Alan",
//     middle: "Mathison",
//     last: "Turing",
//     born: 1912
//   });

//   console.log("Document written with ID: ", docRef.id);
// } catch (e) {
//   console.error("Error adding document: ", e);
// }





export function getFirebaseConfig() {
  
  return firebaseApp;
}
