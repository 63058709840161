import styled, {createGlobalStyle } from 'styled-components';
//  import { Link } from 'react-router-dom'
import { dataObject } from './Pages/Data';

const GlobalStyle = createGlobalStyle`

  *, *::before, *::after{    
  box-sizing:border-box;
  margin:0;
  padding:0;
  font-family: 'Roboto', sans-serif;
 /*  max-height: 100vh; */
   letter-spacing: .6px;
  }

 `;

export const Container = styled.div`

  z-index: 1;
  width: 100%;
  height:100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
//border: 5px dotted cyan; 
 @media screen and (max-width: 991) {

  padding-right: 30px;
  padding-left: 30px;
  
 }

`

export const Container2 = styled.div`

  z-index: 1;
  width: 100%;
  height:100vh;
  /* max-width: 1300px; */
  margin-right: 0;
  margin-left: 0;
  padding-right: 0px;
  padding-left: 0px;
  background: Gold ;
 /* overflow-y: scroll;  */


 @media screen and (max-width: 991) {

  padding-right: 30px;
  padding-left: 30px;
  
 }

`
export const InfoRow = styled.div`
color: ${dataObject.mainCol};
background: ${dataObject.mainBg};


display: flex;
 flex-direction: row;
 height:100vh;
 max-width: 100vw;
 z-index: 1;
@media screen and (max-width:768px) {
/*   display: block; */
  flex-direction: column;
 }
`;


export const InfoColumnLeft = styled.div`
color: ${dataObject.leftmainCol};      
background: ${dataObject.leftmainBg};
/* background: red; */
display:flex;       flex-direction:column;
justify-content:center;
height:100%;
 flex: 1;
 /* max-width: 20%; */
 min-width: 300px;
/*  flex-basis: 50%; */
 align-items: center;
  z-index: 1;
 h1 {
  color: #ffffff;
  text-align: right;
 }

 @media screen and (max-width:768px) {
  max-width: 100%;
 /*  flex-basis: 100%; */
  display: none;
  justify-content: center;

 }

`;

export const InfoColumnRight = styled.div`
color: ${dataObject.rightmainCol}; background: ${dataObject.rightmainBg};     
  display:flex;    flex-direction:column;
  height:100vh;
  flex: 4; 
  /* max-width: 80%; */
   z-index: 1;
 @media screen and (max-width:768px) {
  max-width: 100%;

 }
`;

export const RightColTopNavWrapper = styled.nav`
color: ${dataObject.rightTopNavCol};            color:black;
background: ${dataObject.rightTopNavBg}; 
/* background: red; */
display:flex; 
/* flex-direction: row; */
/* flex:1; */
  justify-content:space-between;
  align-items: center;
  height:102px;
  width: 100%;
  padding: 0px 20px 0px 20px;
  border-bottom: 2px solid #ddd;
 box-shadow: 00px 0px 20px 0px rgba(0, 102, 51, 0.2);
 /*  overflow-y: auto; */
 position:relative;
 z-index: 1;

 @media screen and (max-width:768px) {
 max-height:62px;


 }

`;

export const NavLeftWrapper = styled.div`
color: ${dataObject.rightTopNavCol};           
background: ${dataObject.rightTopNavBg};       
/* background: black;        */
display:flex;
flex:1;
flex-direction: row-reverse;
  justify-content:space-between;
  align-items: center;
  padding: 0px 0px 0px 0px;
 position:relative;
 z-index: 4;
 height: 100px;
 @media screen and (max-width:768px) {
    height: 60px;

 }

`;

//Top Nav right side 
export const NavRightWrapper = styled.div`
color: ${dataObject.rightTopNavCol};           
background: ${dataObject.rightTopNavBg};
/* background: green;
border: 2px solid red;        */
display:flex;
/* flex:1; */
flex-direction: row-reverse;
  justify-content:center;
  align-items: center;
  padding: 0px 0px 0px 0px;

 /* position:relative; */
 z-index: 4;
  height: 100%;
 @media screen and (max-width:768px) {
 }

`;



export const RightColBottomWrapper = styled.div`
color: ${dataObject.rightmainCol}; background: ${dataObject.rightmainBg};     
  display:flex;    
  flex-direction:column;
  justify-content: flex-start;
  align-items: center;
  height:100vh;
  width: 100%;
  /* max-width: 80%; */
   z-index: 5;
 @media screen and (max-width:768px) {
  max-width: 100%;

 }
`;


export const StyledRightContainer = styled.div`
 color: ${dataObject.rightCol};                      
  background: ${dataObject.rightBg};
  /* background:cyan; */
  /* border: 5px dotted red; */
  height:100vh;
  width:80%;
  display: flex;
   flex-direction:column;
   justify-content: flex-start;  
  /* flex-wrap:wrap; */
  align-items: center;
  /* align-self:center;   */
/*  justify-content: flex-start; */
  padding: 5px 0px 60px 0px;
  gap:10px 40px;
  overflow-y: scroll;
  @media screen and (max-width:768px) {
width:95%;
gap:10px 40px;

 }

`;



export const Button = styled.button`

 border-radius:10px;
 border-bottom: 4px;
 background: ${ (primary) => (primary ? '#03fcc2' : '#0467FB')};
 white-space: nowrap;
 padding: ${ ({ big }) => (big ? '12px 64px' : '10px 20px') };
 color :#000;
 font-size: ${ ({ fontBig }) => (fontBig ? '20px' : '16px')} ;
 outline: none;
 border: none;
 cursor: pointer;


 transition: all 0.5s;
 
 &:hover {
  color : #006633;
  transition: all 0.3s ease-out;
  background: #fff;
  background: ${ (primary) => (primary ? '#00FF80' : '#4B59F7')};

 }

@media screen and (max-width: 991) {
 width: 100%;
 
 
}
  
`

export const ButtonLegend = styled.button`

 border-radius:36px;
 border-bottom: 4px;
 background: ${(primary) => (primary ? '#FE0B0B' : '#FE0B0B')};
 white-space: nowrap;
 padding: ${({ big }) => (big ? '8px 20px' : '8px 20px')};
 color :#fff;
 font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')} ;
 outline: none;
 border: none;
 cursor: pointer;
 margin-bottom:24px;



 display:inline-flex;
 justify-content: center;
 align-items: center;




 transition: all 0.5s;
 
 &:hover {
  color : #006633;
  transition: all 0.3s ease-out;
  background: #fff;
  background: ${(primary) => (primary ? '#00FF80' : '#4B59F7')};

 }

@media screen and (max-width: 991) {
 width: 100%;
 
 
}
  
`



export default GlobalStyle;