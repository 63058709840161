import React from 'react';
import { tradeObjOne, tradeObjTwo, tradeObjThree} from '../Data';
import { InfoSection } from '../../Components';
import { Navbar, Footer } from '../../Components';

const Trade = () => {
 return (
  <>
   <Navbar />
   <InfoSection {...tradeObjOne} />
   <InfoSection {...tradeObjTwo} />
   <InfoSection {...tradeObjThree} />   
   <Footer />
  </>
 )
}

export default Trade;