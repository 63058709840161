
import React, { useState, useRef, useEffect, useCallback } from 'react';
// import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
// import { MdClose } from 'react-icons/md';
import { validate } from 'react-email-validator';
import { IconContext } from 'react-icons/lib';
//import { icon_plus, logoSVG_White, logoSVG_green, logoSVG_red, logoSVG_yellow, cancelSvg, checkSvg } from "../../Assets/Index";

//import { userTest, userDataTest, ConstValues } from '../../Pages/Protec/Data';
// import ModalSendEmail from '../../Components/ModalSendEmail/ModalSendEmail';
// import ModalSelectContact from '../ModalSelectContact/ModalSelectContact';

import {
      Img 
} from '../../Pages/Protec/Protec.style';


import {
  Background, ModalWrapper, ModalContent, CloseModalButton,
  LabelWrapper, EmailWrapper, CloseButtonWrapper, PWrapper,
  StyledInput, StyledError,
  // ChargesWrapper, FeeWraper, RefWraper, EmailRef, BalanceRef, TimeRef,
  // NavLogoWrapper, NaveSettingLogo, AmountInput,
  AddUserButtonWrapper, StyledAddUserButton,
  // ReceiptItemWrapper
} from './ModSend.style';


import { useUserAuth } from "../../context/UserAuthContex";
// import { FaSlideshare } from 'react-icons/fa';

const ModSendInvite = ({ showModalAdd, setShowModalAdd }) => {

  const { signIn } = useUserAuth();
  // const { userData } = useUserAuth();
  const { userContacts } = useUserAuth();
  const { setUserContacts } = useUserAuth();

  // const [showModalSendEmail, setShowModalAddSendEmail] = useState(false);
  // const [showModSendToContact, setShowModSendToContact] = useState(false);

  const [showAskForEmail, setShowAskForEmail] = useState(true);
  const [showAskForEmailButton, setShowAskForEmailButton] = useState(false);
  const [showNotFoundResult, setShowNotFoundResult] = useState(false);  
  const [showContinueToDash, setShowContinueToDash] = useState(false);
  const [showInvitationSent, setShowInvitationSent] = useState(false);

  const [showFoundResult, setShowFoundResult] = useState(false);

  // const [isMember, setIsMember] = useState(false);

// const [showContinue, setShowContinue] = useState(false);
// const [showEmailInput, setShowEmailInput] = useState(true);
  
  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState("");
  

  const [Error, setError] = useState("");
  const [isError, setIsError] = useState(false);  
  // const [isUserError, setIsUserError] = useState(false);
  // const [userError, setUserError] = useState('');




  // const [isLoading, setIsLoading] = useState(false);

  
  

  // const navigate = useNavigate();


  const modalRef = useRef();

  // const closeModalClick = e => {
  //   if (modalRef.current === e.target) {
  //     // setShowModalAdd(false);
  //     setShowModalAdd(!showModalAdd);
  //     setWillSendInvitation(false);
  //     setIsError(false);
  //     setShowEmailInput(true);
  //     setIsMembershipChecked(false);
  //     setNewEmail('');
  //     setShowFindEmail(false);
  //     setIsMember(false);
  //     // setUserError(false);
  //   }
  // };

  const closeModal = e => {
    if (modalRef.current === e.target) {
      
     
      goBackToParent();
      setIsError(false);
      setShowModalAdd(false);      
    }
  };
  // { showAskForEmail }
  // { showAskForEmailButton }
  // { showNotFoundResult && }
  // { showInvitationSent }
  // { showFoundResult &&  }

  const goBackToParent = e => {
    console.log(' * goBackToParent clicked *');
    setShowAskForEmail(true);
    setShowAskForEmailButton(false);
    setShowNotFoundResult(false);   
    setShowFoundResult(false);   
    setShowContinueToDash(false);
    setShowInvitationSent(false); 
    setNewEmail('');
    // setShowModalAdd(prev => !prev)
  };//==========  goBackToParent   =============


  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalAdd) {
        setShowModalAdd(false);
        console.log('I pressedffffff');
      }
    },
    [setShowModalAdd, showModalAdd]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  const handleInput = e => {
    const value = e.currentTarget.value;    
      if (validate(value)) {
        setIsError(false); setError('');     // the email is valid
        setShowAskForEmailButton(true);
      } else {
        setIsError(true); setError('Please Enter a valid Email');     // the email is invalid
      }
      setNewEmail(value);     
  };//========  handleInput  =========

  useEffect(() => {
    //  setUserContacts(contactsArrayClean(userContactsuserData.transactions, userData.email));
    
  }, []);// =======  useEffect  ============
  
  // function contactsArrayClean(transactions, ownEmail) {
  //   //  =======
  //   let userList = [];
  //   for (var i = 0; i < transactions.length; i++) {
  //     userList[i] = transactions[i].ref;
  //   }
  //   //console.log('userList hhhhhh', userList);
  //   userList.pop();
  //   //console.log('userList hhhhhh popit out', userList);
  //   let uniqueList = [...new Set(userList)];
  //   //console.log('uniqueList this time', uniqueList);
  //   //  =======    
  //   for (var j = 0; j < uniqueList.length; j++) {
  //     if (uniqueList[j] === ownEmail) {
  //       uniqueList.splice(j, 1);
  //     }
  //   }
  //   //console.log('uniqueList without own email &&&&&&7', uniqueList);
  //   return uniqueList;
  // }//============ contactsArrayClean


  function findEmail() {
    console.log(' *£££££ checkEmail £££££*');
    // let answer = '';
    //  Password input 12345678
    signIn(newEmail, '1234aTKzT5678')
      .then((userCredential) => { })
      .catch((error) => {
        console.log('Jam checkEmail : we have an ERROR at signing in ');
        console.log('error code ',error.code);

        setError(error.code);setIsError(true);
        if (error.code === "auth/wrong-password") {
          console.log(' exist =======');
          // setIsMember(true);
          setError(''); setIsError(true);
          setShowAskForEmail(false);
          setShowNotFoundResult(false);
          setShowFoundResult(true);
        } else if (error.code === "auth/user-not-found") {
          console.log(' DOES not exist =======');
          // setIsMember(false);
          setError(error.code); setIsError(true);
          setShowAskForEmail(false);
          setShowNotFoundResult(true);
          setShowFoundResult(false);
        } else {
          setError("Sorry can't find the Email, ask them yourself to join");
          setIsError(true);
        }
      });    
//=====simulation
    // setIsMember(true);
    setError(''); setIsError(true);
    setShowAskForEmail(false);
    setShowNotFoundResult(false); 
    setShowFoundResult(false);//change these after you allow email    
//=====simulation
  }//=========    findEmail ================


 
  
  const sendInvitation = e => {
    setShowNotFoundResult(false);
    setError(''); setIsError(true);
    setShowInvitationSent(true);
    setShowContinueToDash(true);
  //attention *********************************************************
    //sendEmailInviteViaEmailJs(toEmail,from_emaile)
    //sendEmailInviteViaEmailJs(newEmail,userData.email)
  //attention *********************************************************
  console.log("sendInvitation : Sending an invitation to :", newEmail);    
  };//====== sendInvitation


  const continueToDash = e => {
    setNewEmail('');
    setShowAskForEmailButton(false);
    setShowNotFoundResult(false);
    setShowContinueToDash(false);
    setShowInvitationSent(false);    
    setShowAskForEmail(true);
    
    navigate("/Protec/Dashboard"); 
  };//==========  continueToDash   =============

  const addContactToList = () => {
    console.log("adding contact to lst of Contacts ");
    userContacts.unshift(newEmail);
    setUserContacts(userContacts);
    goBackToParent();
    console.log("userContacts : ",userContacts);
    console.log("go to dashboard here line 221 ");
   navigate("/Protec/SendD");
  };//======= addContactToList

  // function sendEmailInviteViaEmailJs(toEmail,from_emaile) {
  //   let templateParams = {
  //     to_email: toEmail,     
  //     from_email: from_emaile,
  //   };
  //   console.log("templateParams : ", templateParams);
  //   console.log("Sending Email  to ", toEmail);
  //   console.log("Sending Email  from  ", from_emaile);
  //   emailjs
  //     .send(
  //       "service_195ei3n", //YOUR_SERVICE_ID
  //       "template_w3oz6v9", //YOUR_TEMPLATE_ID
  //       templateParams, //own parameters
  //       "31JSp7p9rt0D9nmq9" //YOUR_PUBLIC_KEY
  //     )
  //     .then(
  //       function (response) {
  //         console.log("SUCCESS!", response.status, response.text);
  //       },
  //       function (error) {
  //         console.log("FAILED...", error);
  //       }
  //     );
  // } //===========  sendEmailOtpViaEmailJs ============


  return (
    <>
      <IconContext.Provider value={{ color: '#000' }}>
      {showModalAdd ? (
        <Background onClick={closeModal} ref={modalRef}>
         
            <ModalWrapper showModalAdd={showModalAdd}>
              <ModalContent>
                <CloseButtonWrapper>
                  <CloseModalButton
                    aria-label='Close modal'
                    // onClick={closeModalClick}
                    // onClick={() => setShowModalAdd(prev => !prev)}
                    onClick={goBackToParent}
                  />
                </CloseButtonWrapper>
                


                {showAskForEmail &&
                  <>
                  <EmailWrapper> Finding New Contact</EmailWrapper>
                  <PWrapper>Lets find the benificary, Please enter the Email of the Recipient</PWrapper> 
                  <LabelWrapper></LabelWrapper>
                  <label htmlFor="newEmail"> </label>
                  <EmailWrapper>
                    <StyledInput
                      type="email"
                      name="newEmail"
                      value={newEmail}
                      onChange={handleInput} />
                    {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                      (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
                    }
                  </EmailWrapper>
                  
                  {showAskForEmailButton &&  
                  <AddUserButtonWrapper>
                    <StyledAddUserButton bgCol={'#006633'} onClick={findEmail}>let us Find Email</StyledAddUserButton>
                  </AddUserButtonWrapper>
                  } 
                </>                
                }
                

                {/* <EmailWrapper>shit </EmailWrapper> */}

                {showNotFoundResult && <>                             
                  <PWrapper><strong>{newEmail} </strong> Is not a Paydoroz Member <br/>They need to be member. We can ask them to join by sending them an invitation </PWrapper>
                  <AddUserButtonWrapper>
                    <StyledAddUserButton bgCol={'red'} onClick={sendInvitation}>Send invitation</StyledAddUserButton>
                  </AddUserButtonWrapper> 
                </>     
                }
                
                {showInvitationSent && <>                 
                  <PWrapper>Sorry <strong>{newEmail} </strong> is not registered with Paydoroz, We have Sent them an Email asking them to Join. You could also ring them and invite them.</PWrapper>
                 </>
                }

                {showFoundResult && <>                 
                  <PWrapper>Yes! <strong>{newEmail} </strong>Is a Paydoroz Member, You can Add them to your contact list Now</PWrapper>
                  <AddUserButtonWrapper>
                    <StyledAddUserButton bgCol={'#006633'} onClick={addContactToList}>Add Contact</StyledAddUserButton>
                  </AddUserButtonWrapper>
                </>
                }

                 {/*isLoading  */}
                {false ?
                  (<AddUserButtonWrapper>
                    <StyledAddUserButton onClick={()=>null}>Loading ...</StyledAddUserButton>
                  </AddUserButtonWrapper>
                  ) :
                  (null
                  )
                }


                {/* <LabelWrapper>We are checking the Email</LabelWrapper>
                */}
                {isError && (<StyledError>{Error}</StyledError>)} 
                {showContinueToDash &&                  
                  <AddUserButtonWrapper>
                    <StyledAddUserButton onClick={continueToDash}>Continue</StyledAddUserButton>
                    {/* <StyledAddUserButton onClick={() => { navigate("/Protec/Dashboard"); }}>Continue</StyledAddUserButton> */}
                  </AddUserButtonWrapper>
                }
              
              </ModalContent>           

              {/* <CloseModalButton
                  aria-label='Close modal'
                  onClick={() => setShowModalAdd(prev => !prev)}/>
              */}
            </ModalWrapper>

             
        </Background>
        ) : null}
        
        {/* {showModalSendEmail  &&       
            <ModalSendEmail showModalAdd={showModalAdd} setShowModalAdd={setShowModalAddSendEmail} selectedEmail={newEmail}/> 
        }   */}
       {/*         
         {showModalSelectContact &&  
        <ModSendToContact showModalAdd={showModSendToContact} setShowModalAdd={setShowModalAddSelectContact} selectedEmail={newEmail} />
         }    */}

      </IconContext.Provider>
    </>
  );
};
export default ModSendInvite;

