import React from 'react';
// import Navbar from '../../Components/Navbar/Navbar'; 
import NavbarD from '../../Components/NavbarD/NavbarD'; 
// import Sidebar from '../../Components/Sidebar/Sidebar'; 
// import SidebarD from '../../Components/SidebarD/SidebarD'; 
//import { tradeObjOne, tradeObjTwo, tradeObjThree, tradeObjFour, tradeObjFive, tradeObjSix, tradeObjSeven } from '../Data';

const Jamal = () => {
 return (
  <>
   <div>
   {/* <Navbar/> */}
   <NavbarD/>
   {/* <Sidebar/> */}
    {/* <SidebarD /> */}
   </div>
   <div>
   <br />
   <h1>***** Jamal  Page ****</h1><br/>
   <h3>** I forgot my password</h3><br />


   </div>
  </>
 )
}

export default Jamal;