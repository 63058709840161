import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, ButtonLegend } from '../../globalStyles';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  InfoSec, InfoSecContainer, InfoRow, InfoColumnCalculator,
  InfoColumn, TextWrapper,
  TopLine, Heading, Subtitle,
  ImgWrapper, Img,
  // Img2,
  IconWrapper2,
  // NavLogo2
} from './InfoSectionHome.style';

import Calculator from '../Calculator/Calculator.js';


const InfoSectionHome = (
  { primary,  imgStart,
    lightTopLine, lightTextDesc, 
    description, headline, lightText,
    topLine,     
    buttonlabel2,isbutton2,buttonlabel,
    // buttonlink,
    buttonlink2   
  
  }) => {
  const [t, i18n] = useTranslation('');
  return (
   <>
      <InfoSec  >

    <Container>  

        <InfoRow imgStart={imgStart}>
         
          <InfoColumn>
           <TextWrapper>
                
              <ButtonLegend big fontBig primary={primary}>
                                  
                  <IconWrapper2 >
                    <svg width="24" height="24" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM32 59C46.9117 59 59 46.9117 59 32C59 17.0883 46.9117 5 32 5C17.0883 5 5 17.0883 5 32C5 46.9117 17.0883 59 32 59Z" fill="#fff" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M15.5754 40.0964C17.5918 32.9393 21.6247 29.1502 21.6247 29.1502L25.3214 25.3611C25.3214 25.3611 23.473 24.0981 23.137 22.2737C22.8009 20.4493 23.137 19.1863 23.137 19.1863C23.137 19.1863 23.809 15.3972 25.4894 13.5728C27.1697 11.7484 30.8664 11.0468 30.8664 11.0468C30.8664 11.0468 35.0673 10.4854 36.9156 10.7661C38.764 11.0468 40.1924 11.4678 40.6124 12.1695C41.0323 12.8711 41.1164 18.7653 34.7312 23.5367C28.3459 28.3082 21.6246 33.5006 19.6082 36.4477C17.5918 39.3947 18.0959 41.6401 19.6082 43.1838C21.1205 44.7275 28.0099 46.4116 32.0426 46.9729C36.0754 47.5343 50.5263 44.8679 50.5263 44.8679C50.5263 44.8679 46.9976 50.9023 32.8828 53.0074C18.768 55.1124 13.559 47.2536 15.5754 40.0964ZM33.0441 35.9846C33.3254 35.7084 30.5355 34.4653 30.5355 34.4653L31.0747 33.4196L31.9656 32.0187C31.9656 32.0187 34.4976 33.0249 34.9196 34.1299C35.3416 35.2348 33.6536 37.8717 32.3876 38.5497C31.3034 39.1304 28.5168 39.4545 27.6064 39.5604L27.6062 39.5604C27.4537 39.5782 27.3539 39.5898 27.3236 39.5955C27.1126 39.6349 27.4643 38.8062 27.4643 38.8062L28.0973 36.9515L29.6446 36.7936L31.2623 36.5371C31.2623 36.5371 32.7627 36.2609 33.0441 35.9846ZM29.8563 22.3077C29.8563 22.3077 26.4956 20.0623 26.9997 17.9573C27.5038 15.8522 38.2579 16.6943 36.0734 18.5186C33.889 20.343 29.8563 22.3077 29.8563 22.3077Z" fill="#fff" />
                    </svg>
                  </IconWrapper2>

                  {buttonlabel}

                
              </ButtonLegend>  
                
                <TopLine lightTopLine={lightTopLine}> {t(topLine)} </TopLine>
                      
                <Heading lightText={lightText}>{t(headline)}  </Heading>
              
                <Subtitle lightTextDesc={lightTextDesc}>{t(description)}</Subtitle> 
               
                {
                 
                  isbutton2 && <>
                  <Link to={`${buttonlink2}`}>
                  <Button big fontBig primary={primary}>{t(buttonlabel2)} </Button>
                    </Link>
                  </>
                }  
           
                
              </TextWrapper> 
              
              
           
            </InfoColumn>
            
            <InfoColumn>
           {/*    <InfoColumnCalculator> */}
                   <Calculator />
     {/*          </InfoColumnCalculator> */}
           </InfoColumn>

        
        </InfoRow> 
       

       </Container>  

   </InfoSec>
   
   
   </>
  )
}

export default InfoSectionHome