
import React, {
  useState, useRef,
  // useEffect, useCallback
} from 'react';
// import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
// import { MdClose } from 'react-icons/md';
// import { validate } from 'react-email-validator';
import { IconContext } from 'react-icons/lib';
import {
  // icon_plus,
  icon_minus, icon_equal, 
// logoSVG_White, logoSVG_green, logoSVG_red,
//   logoSVG_yellow, cancelSvg, checkSvg
} from "../../Assets/Index";
import {
  // auth 
  dbb
} from "../../Firebase";
//import { signInWithEmailAndPassword } from "firebase/auth";
//import { usersCollectionRef } from '../../Firebase';
import {
  collection, getDocs, addDoc, updateDoc, doc,
  // deleteDoc,
  query, where
} from 'firebase/firestore'


import {
  // userTest, userDataTest,
  ConstValues,
  // userTranRow
} from '../../Pages/Protec/Data';
// import ModalSendEmail from '../../Components/ModalSendEmail/ModalSendEmail';
// import ModalSelectContact from '../ModalSelectContact/ModalSelectContact';


import {
  Img, 
  Background, ModalWrapper, ModalContent, CloseModalButton,
  LabelWrapper,
  // EmailWrapper,
  CloseButtonWrapper,
  // PWrapper,
  PreviewTextWrapper,
  // StyledInput,
  StyledError,
  ModalDialogueWrapper,
  AmountWrapper, AmountInput,
  NavLogoWrapper2, FeeWraper2,
  EmailRef2,
  ChargesWrapper, FeeWraper,
  // RefWraper, EmailRef, BalanceRef, TimeRef,
  // NavLogoWrapper, NaveSettingLogo, 
  AddUserButtonWrapper, StyledAddUserButton,
  // ReceiptItemWrapper
} from './ModSend.style';


import { useUserAuth } from "../../context/UserAuthContex";
// import { StyledAddUserButtonOffer } from '../../Pages/Protec/Protec.style';
// import { FaSlideshare } from 'react-icons/fa';

const ModSellToContact = ({ showModal, setShowModal, selectedEmail }) => {


  // const { signIn } = useUserAuth();
  const { userData } = useUserAuth();
  // const { userContacts } = useUserAuth();
  // const { setUserContacts } = useUserAuth();

  
  // const [showModSendPreview, setShowModSendPreview] = useState(false);
  // const [showModSendToContact, setShowModSendToContact] = useState(false);

  const [showAskForAmount, setShowAskForAmount] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [showAskForPreview, setShowAskForPreview] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showSorry, setShowSorry] = useState(false);
  // const [showAskForEmail, setShowAskForEmail] = useState(true);
  // const [showAskForEmailButton, setShowAskForEmailButton] = useState(false);
  // const [showNotFoundResult, setShowNotFoundResult] = useState(false);  
  // const [showContinueToDash, setShowContinueToDash] = useState(false);
  // const [showInvitationSent, setShowInvitationSent] = useState(false);

  // const [showFoundResult, setShowFoundResult] = useState(false);

  // const [isMember, setIsMember] = useState(false);

// const [showContinue, setShowContinue] = useState(false);
// const [showEmailInput, setShowEmailInput] = useState(true);
  
  const navigate = useNavigate();
  // const [newEmail, setNewEmail] = useState("");
  
  const [nf, setNf] = useState(0);
 const [sf, setSf] = useState(0);
 const [chf, setChf] = useState(0);
 const [trTime, setTrTime] = useState('');

//  const [feez2, setFeez2] = useState(0); 

  const [Error, setError] = useState("");
  const [isError, setIsError] = useState(false);  
  // const [isUserError, setIsUserError] = useState(false);
  // const [userError, setUserError] = useState('');

  const [amountToSendPd, setAmountToSendPd] = useState(0);
  const [amountNet2, setAmountNet2] = useState();
  // const [amount2, setAmount2] = useState();
  const [showCalculator2, setShowCalculator2] = useState(false);
  const [personalTouch, setPersonalTouch] = useState("");


  // const [isLoading, setIsLoading] = useState(false);

  
  

  // const navigate = useNavigate();


  const modalRef = useRef();



  function netFees() {
    return Number(ConstValues.nf)
  };//=========    sendAmountToContact ================

  function serviceFees(amountToSendPd) {
    // let tsfee = Math.round(amountToSendPd * ConstValues.sf);
    if (amountToSendPd <= 1000) {
      return Number(ConstValues.sffix);
    } else {
      return Number(Math.round(amountToSendPd * ConstValues.sf) + Number(ConstValues.sffix));
    }
  };//=========    serviceFees ================

  // function cashHandlingFees(amountToSendPd) {
  //   if (amountToSendPd <= 1000) {
  //     return Number(ConstValues.sffix);
  //   } else {
  //     return Number(Math.round(amountToSendPd * ConstValues.chf) + Number(ConstValues.chffix));
  //   }
  // };//=========    serviceFees ================

  // function createTransaction(fromF, toF, typeF, idF, amountF, refF) {
  //   let time = new Date().toString().slice(0, 25);    
  //   let tran = JSON.parse(JSON.stringify(userTranRow));
  //   tran.createdAt = time;
  //   tran.from = fromF;
  //   tran.to = toF;
  //   tran.type = typeF;
  //   tran.id = idF;
  //   tran.amount = Number(amountF);
  //   tran.nf = netFees(amountF);
  //   tran.sf = serviceFees(amountF);
  //   tran.chf = cashHandlingFees(amountF)
  //   tran.ref = refF;

  //   return tran
  // };//==========   createTransaction


  const closeModal = e => {
    if (modalRef.current === e.target) {


      goBackToParent();
      setIsError(false);
      setShowModal(false);
    }
  };

  const goBackToParent = e => {
    console.log(' * goBackToParent clicked *');
    // setShowAskForEmail(true);
    // setShowAskForEmailButton(false);
    // setShowNotFoundResult(false);
    // setShowFoundResult(false);
    // setShowContinueToDash(false);
    // setShowInvitationSent(false);
    // setNewEmail('');
    setShowModal(prev => !prev)
  };//==========  goBackToParent   =============

  const handleInput2 = e => {
    const value = Number(e.currentTarget.value);
    
    let nfV = netFees();
    let sfV = serviceFees(value);    
    // let chfV = cashHandlingFees(0);

    let feezV = Number(nfV + sfV );
    setAmountNet2(Number(value) - feezV);    
    setShowCalculator2(false);      
    setShowAskForPreview(false)
    setNf(nfV);
    setSf(sfV);
    setChf(0);
    // setFeez2(feezV);  
    setTrTime(Date().toString().slice(0, 25));    
   
    //=============== 
    
    if ((value + feezV) > userData.balance) {
      setAmountToSendPd(value);
      setIsError(true);
      setError('Not Enough Funds');
      setShowCalculator2(false);
      setShowAskForPreview(false)
    } else if (value < ConstValues.minAmount) {
      setAmountToSendPd(value);
      setIsError(true);
      setError('Min Amount to Send ' +  ConstValues.minAmount );
      setShowCalculator2(false);
      setShowAskForPreview(false)
    }
    else if (value > 9999) {
      setAmountToSendPd(value);
      setIsError(true); setError('Max 9999');
      setShowCalculator2(false);
      setShowAskForPreview(false)
    }  
    else {
      setAmountToSendPd(value);
      setIsError(false);
      setError('');
      setShowCalculator2(true);   
      setShowAskForPreview(true);
    }//================ 
    
  };//=======  handleInput2

  const showPreviewClick = e => {
    console.log(" preview started ");

    setShowCalculator2(false);
    setShowAskForAmount(false);
    setShowAskForPreview(false);
    setShowPreview(true);


  };
  const showContinue = e => {
    console.log(" Thank You ");

    setShowCalculator2(false);
    setShowAskForAmount(false);
    setShowAskForPreview(false);
    setShowThankYou(true);
    navigate("/Protec/Dashboard");


  };

  const handlePersonalTouch = e => {
    const valueTouch = e.currentTarget.value;    
    console.log(" preview started ");    
    setPersonalTouch(valueTouch);
  };//****************** handlePersonalTouch

  const isRecipienLive = (emailX) => {
    if (true) {
      return true
    }    
    else {
      return false 
    }
  };//****************** handlePersonalTouch

  async function sendPaydorozToUser() {

    let tempId = '';

    // setShowAskForAmountButton(false);
    // setShowAskForAmount(false);
    // setShowPreview(false);
    // createTransaction(time, from, to, type, id, amount,ref)
    // let time = new Date().toString().slice(0, 25);
    // let nf = netFees(); console.log('nf :', nf);
    // let sf = serviceFees(costOFGoods); console.log('sf :', sf);
    // let chfC = cashHandlingFees(costOFGoods); console.log('chfC :', chfC);

    let mainTransR = {

      "createdAt": trTime,
      "from": userData.email,
      "to": selectedEmail,
      "type": "Transfer:Send",
      "id": "",
      "amount": amountToSendPd,
      "nf": nf,
      "sf": sf,
      "chf": 0,//chf
      "ref": personalTouch
    };
    // createTransaction(time, userData.email, shopEmail, "Partner Pay", '', costOFGoods, "Partner Pay");
    let mainTrans = JSON.parse(JSON.stringify(mainTransR));

    //  setTimeOfTransaction(mainTrans.createdAt);
    // let sendTransR = createTransaction(time, userData.email, shopEmail, "Pay:Send", '', costOFGoods, "Paid For Goods");

    let sendTransR = {
      "createdAt": trTime,
      "from": userData.email,
      "to": selectedEmail,
      "type": "Transfer:Send",
      "id": "",
      "amount": amountToSendPd,
      "nf": nf,
      "sf": sf,
      "chf": 0,// chf
      "ref": personalTouch
    };
    let sendTrans = JSON.parse(JSON.stringify(sendTransR));
    //createTransaction(timeF,  fromF,  toF,  typeF, idF, amountF,refF)
    let recTrans = {
      "createdAt": trTime,
      "from": userData.email,
      "to": selectedEmail,
      "type": "Transfer:Receive",
      "id": "",
      "amount": amountToSendPd,
      "nf": nf,
      "sf": sf,
      "chf": 0,
      "ref": personalTouch
    };
      // createTransaction(time, userData.email, shopEmail, "Pay:Receive", '', costOFGoods, "Thanks your");
    
      //save tranTransfert to  transCollection
    //get doc id from transCollection    
    //====== start part 1   
    console.log('*********** Start tranCollection ************');
    try {
      const docRef = addDoc(collection(dbb, "tranCollection"), mainTrans);
      tempId = (await docRef).id;
      sendTrans.id = tempId;
      recTrans.id = tempId;
    } catch (e) {
      setIsError(true); setError(e);
    }
    console.log(" sendTrans = ", sendTrans);
    console.log(" recTrans = ", recTrans);


    console.log('*********** end tranCollection ************');
    // ==============   
    let tSenderData = {};
    // let tTransactions = [];
    let tSendToken = ';'
    let tBalance = 0;
    //update sender balance and record 
    console.log(" tBalance :",tBalance);
    const qSender = query(collection(dbb, "users"), where("email", "==", userData.email));
    const querySnapshot = await getDocs(qSender);
    querySnapshot.docs.forEach((doc) => {
      tSendToken = doc.id;
      tSenderData = JSON.parse(JSON.stringify(doc.data()));
    });

    tBalance = tSenderData.balance;
    console.log(" tSenderData = ", tSenderData);
    console.log(" tSenderData.balance = ", tSenderData.balance);
    console.log(" tBalance = TBALANCE :", tBalance);
    

    // tTransactions = JSON.parse(JSON.stringify(tSenderData.transactions));      
    //***********************************************
    // check balance positive before continue
    //***********************************************

    // tTransactions.unshift(sendTrans);
    tSenderData.transactions.unshift(sendTrans);
    
    tSenderData.balance = Number(tBalance) - Number(amountToSendPd);

    
    console.log(" nf :", Number(nf));
    console.log(" sf :", Number(sf));
    console.log(" chf :", Number(chf));
    console.log(" Number(nf + sf + chf)) :", Number(nf + sf + chf));

    console.log(" Number(tBalance) - Number(nf + sf + chf) :", Number(tBalance) - Number(nf + sf + chf));
    console.log(" Number(tBalance) :", Number(tBalance));
    console.log(" Number(nf + sf + chf) :", Number(nf + sf + chf));
    console.log(" NEW tBalance = TBALANCE :", tSenderData.balance);
    console.log(" tSenderData = ", tSenderData);

    const sendRef = doc(dbb, 'users', tSendToken);
    // updateDoc(sendRef, { balance: Number(tBalance) - Number(costOFGoods), transactions: tTransactions }).then(() => { });
    updateDoc(sendRef, tSenderData).then(() => { console.log('shit') });
    //catch any errors     
    //====== end  part 1

    //====== start  part 2


    // ==============   
    let tRecData = {};
    // let tRecTransactions = [];
    let tRecToken = ';';
    let tRecBalance = 0;
    //update sender balance and record 
    const qRec = query(collection(dbb, "users"), where("email", "==", selectedEmail));
    const queryRecSnapshot = await getDocs(qRec);
    queryRecSnapshot.docs.forEach((doc) => {
      tRecToken = doc.id;
      tRecData = JSON.parse(JSON.stringify(doc.data()));     
    });
    tRecBalance = tRecData.balance;
    console.log(" tRecBalance = ", tRecBalance);
    console.log(" tRecData.balance = ", tRecData.balance);
    console.log(" tBalance = TBALANCE :", tRecBalance);


    // tRecTransactions = JSON.parse(JSON.stringify(tRecData.transactions));
    //***********************************************
    // check balance positive before continue
    //***********************************************
    console.log(" recTrans :", recTrans);
    tRecData.transactions.unshift(recTrans);
    tRecData.balance = Number(tRecBalance) + Number(amountToSendPd) - Number(nf) - Number(sf);

    console.log(" nf :", Number(nf));
    console.log(" sf :", Number(sf));
    console.log(" chf :", Number(chf));
    console.log(" Number(nf + sf + chf)) :", Number(nf + sf + chf));
    
    console.log(" Number(nf + sf + chf)) :", Number(nf + sf + chf) );
    console.log("amountToSendPd  :", Number(amountToSendPd));
    console.log(" Number(nf + sf + chf) :", Number(nf + sf + chf));
    console.log(" x+y-feez) :", Number(tRecBalance) + Number(amountToSendPd) - Number(nf) - Number(sf));
    

    console.log(" tRecData :", tRecData);
    console.log(" tRecData.balance :", tRecData.balance);
    // console.log(" tRecTransactions :", tRecTransactions);

    




    const RecRef = doc(dbb, 'users', tRecToken);
    updateDoc(RecRef, tRecData).then(() => { console.log('finished sending ') });
    //catch any errors
    //====== end  part 2
    // localStorage.setItem("userDatasend", JSON.stringify((tSenderData)));
    // localStorage.setItem("userDatarec", JSON.stringify((recTrans)));
    // //===============    
    //sendEmailPayReceiptViaEmailJs(toEmail,atTime,fromEmail,netPaydoroz,forTotalCash)
    // sendEmailPayReceiptViaEmailJs(shopEmail, time, userData.email, nf + sf + chf, Number(costOFGoods) - Number(nf) - Number(sf));
    // update both sender and receiver as transaction or batch
    // ************************    setShowCompleted(true);  *****************************
    //return to
    //  navigate('/Protec/Dashboard');
    // setShowContinue3(true);
    // setShowContinue2(false);
    setShowCalculator2(false);
    // setShowFoundResult(false);
    // setShowTradeSellSelected(false);

    console.log(" after db call = ");
    console.log(" sendTrans = ", sendTrans);
    console.log(" recTrans = ", recTrans);

  };//========  sendPaydorozToUser





  const sendPaydoroz = () => {
    console.log(" Start The Sending Process ");
    if (isRecipienLive(selectedEmail)) {
      sendPaydorozToUser();

      setShowThankYou(true);
    } else {
      setIsError(true);
      setError('Recipient Account is not Live');
      
      setShowSorry(true);
    }



    console.log(" finished The Sending Process ");
  };//****************** sendPaydoroz

  const SendToContact = e => {
    console.log(" Send To Contact ");
    setShowCalculator2(false);
    setShowAskForAmount(false);
    setShowPreview(false);
    sendPaydoroz();
   
    
  };//****************** SendToContact

  
 
  return (
    <>
      <IconContext.Provider value={{ color: '#000' }}>       
        {showModal ? (
          <Background onClick={closeModal} ref={modalRef}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <CloseButtonWrapper>
                  <CloseModalButton
                    aria-label='Close modal'
                    // onClick={closeModalClick}
                    // onClick={() => setShowModalAdd(prev => !prev)}
                    onClick={goBackToParent}
                  />
                </CloseButtonWrapper>

                {showAskForAmount &&
                  <>
                    <ModalDialogueWrapper color={`#006633`} colortwo={`#FE0B0B`}>
                      <br />
                      You are sending to  <br />
                      <strong >{selectedEmail} </strong>
                      Please Enter Amount, max 9999, remember your balance is
                    <strong >{Intl.NumberFormat().format(userData.balance)}</strong>
                    </ModalDialogueWrapper>

                    <LabelWrapper>You Send </LabelWrapper>
                    <AmountWrapper>
                      <AmountInput autoFocus
                        type="number"
                        name="amountToSendPd"
                        value={amountToSendPd}
                        onChange={handleInput2} />

                      {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                        (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
                      }
                    </AmountWrapper>
                  </>
                }

                {showCalculator2 &&
                  
                  <>
                  <ChargesWrapper>
                    {/* <FeeWraper>
                      <NavLogoWrapper2>
                        <img src={icon_minus} alt="minus" />
                      </NavLogoWrapper2>
                      <FeeWraper2>
                        <EmailRef2> {chf} </EmailRef2>
                        <EmailRef2> Cash Handling Fee </EmailRef2>
                      </FeeWraper2>
                    </FeeWraper> */}                    

                    <FeeWraper>    <NavLogoWrapper2> <img src={icon_minus} alt="minus" /> </NavLogoWrapper2>
                      <FeeWraper2> <EmailRef2> {nf} </EmailRef2> <EmailRef2> Network Fee </EmailRef2> </FeeWraper2> </FeeWraper>
                    <FeeWraper>    <NavLogoWrapper2> <img src={icon_minus} alt="minus" /> </NavLogoWrapper2>
                      <FeeWraper2> <EmailRef2> {sf} </EmailRef2> <EmailRef2> Service Fee </EmailRef2> </FeeWraper2> </FeeWraper>

                    <FeeWraper>  <NavLogoWrapper2> <img src={icon_equal} alt="equal" /> </NavLogoWrapper2>
                      <FeeWraper2> <EmailRef2>{nf+sf} </EmailRef2><EmailRef2> Total Fees </EmailRef2> </FeeWraper2>  </FeeWraper>
                  </ChargesWrapper>

                  <LabelWrapper>Customer Gets Paydoroz</LabelWrapper>
                  <AmountWrapper> <AmountInput readOnly type="number" name="amountNet2" value={amountNet2} />
                    {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                      (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)}
                  </AmountWrapper>  
                  {<br />}
                  </>
                }
                {showAskForPreview &&
                  <>
                  <AddUserButtonWrapper>
                    <StyledAddUserButton bgCol={'#006633'} onClick={showPreviewClick}>Preview</StyledAddUserButton>
                  </AddUserButtonWrapper>
                  </>
                }


                {showPreview &&
                  <>
                  <br/>
                  <LabelWrapper>Please add a personal reference</LabelWrapper>
                  <AmountWrapper>
                      <AmountInput autoFocus
                        type="text"
                      name="personalTouch"
                      value={personalTouch}
                      onChange={handlePersonalTouch} />

                      {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                        (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
                      }
                  </AmountWrapper>
                  <LabelWrapper>Time Stamp</LabelWrapper>
                  <PreviewTextWrapper>{trTime}</PreviewTextWrapper>
                  <LabelWrapper>From</LabelWrapper>
                  <PreviewTextWrapper>{userData.email}</PreviewTextWrapper>
                  <LabelWrapper>To</LabelWrapper>
                  <PreviewTextWrapper>{selectedEmail}</PreviewTextWrapper>
                  <LabelWrapper>You Paid</LabelWrapper>
                  <PreviewTextWrapper>{amountToSendPd}</PreviewTextWrapper>
                  <LabelWrapper>Less Total Fees</LabelWrapper>
                  <PreviewTextWrapper>{Number(Number(nf) +Number(sf) +Number(chf))}</PreviewTextWrapper>
                  <LabelWrapper>Beneficiary gets</LabelWrapper>
                  <PreviewTextWrapper>{Number(Number(amountToSendPd) - Number(Number(nf) + Number(sf) + Number(chf)))}</PreviewTextWrapper>
                  <LabelWrapper>Type Of Transaction</LabelWrapper>
                  <PreviewTextWrapper>Transfer:Send</PreviewTextWrapper>
                  <AddUserButtonWrapper>
                    <StyledAddUserButton bgCol={'#006633'} onClick={SendToContact}>Send</StyledAddUserButton>
                  </AddUserButtonWrapper>

                  </>
                }
                {showThankYou &&
                  <>
                    <ModalDialogueWrapper color={`#006633`} colortwo={`#FE0B0B`}>
                      <br />                   
                      <strong >Thank You </strong>
                    Please Continue to Dashboard
                    <br /> 
                    </ModalDialogueWrapper>

                    <AddUserButtonWrapper>
                      <StyledAddUserButton bgCol={'#006633'} onClick={showContinue}>Continue</StyledAddUserButton>
                    </AddUserButtonWrapper>
                  </>
                }
                <br /> 
                {showSorry &&
                  <>
                    <ModalDialogueWrapper color={`#006633`} colortwo={`#FE0B0B`}>
                      <br />                   
                    <strong >Sorry </strong>
                    The recipient Account is not Live , Sorry we can't Send them Paydoroz Now
                    Please Continue to Dashboard
                    <br /> 
                    </ModalDialogueWrapper>

                    <AddUserButtonWrapper>
                      <StyledAddUserButton bgCol={'#006633'} onClick={showContinue}>Continue</StyledAddUserButton>
                    </AddUserButtonWrapper>
                  </>
                }
                <br /> 
                {isError && <StyledError>{Error}</StyledError>} 

              </ModalContent>
            </ModalWrapper> 
          </Background>
        ) : null}
      </IconContext.Provider>
    </>
  );
};
export default ModSellToContact;

