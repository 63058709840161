
import styled from "styled-components";
import { FaMagento } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Container } from '../../globalStyles';


export const Nav = styled.nav`
background:#006633;
height: 80px;
display: flex;
justify-content:center;
align-items: center;
font-size: 1.2rem;
position: sticky;
z-index: 999;
//border: 3px solid white;
`

export const NavbarContainer = styled(Container)`
display: flex;
justify-content: space-between;
height: 80px;
${Container}
z-index: 100;
//border: 5px solid red;
`

export const NavLogo = styled(Link)`
color:#fff; 
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
display: flex;
font-size: 2rem;
align-items: center;
`

export const NavIcon = styled(FaMagento)`
 margin-right: 0.5rem;
` 
export const IconWrapper = styled.div`
max-width: 32px;
display: flex;
margin-right: 8px;
/* justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')}; */
`
export const GlobeWrapper = styled.div`
max-width: 64px;
display: flex;
flex-direction: column;
justify-content: flex-start;
margin-right: 14px;
margin-left: 8px;
/* justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')}; */
`

export const MobileIcon = styled.div`
 display: none;
 background:#006633;
@media screen and (max-width: 960px) {
  display: block;
  background:#006633;
  position: absolute;
  top:0;
  right:0;
  transform: translate(-100%, 60%);
  font-size:1.8rem;
  cursor:pointer;
  
 }

`

export const NavMenu = styled.ul`
 display:flex;
 align-items: center;
 //justify-content:center;
 list-style: none;
 text-align: center;
 //border: 3px solid yellow;

 @media screen and (max-width: 960px) {
  display: flex;
  background:#006633;
  flex-direction: column;
  //justify-content:center;
  align-items: center;
  width: 100%;
  height: 90vh;
  position: absolute;
  top:80px;
  left: ${ ({ click }) => (click ? 0 : '-100%') };
  opacity: 1;
  transition: all 0.5s ease;

 }
  
`

export const NavItem = styled.li`
display: flex;
align-items: center;
height: 80px;
border-bottom: 2px solid transparent;
  &:hover  {
   border-bottom: 3px solid #03fcc2;  
  }
  @media screen and (max-width: 960px) {
   width: 100%;
   //border-bottom: 2px solid red;   
   &:hover  {
    border:none;  
    }
  }

`
export const NavLinks = styled(Link)`

 color : #fff;
 display: flex;
 align-items: center;
 text-decoration: none;
 padding: 0.5rem 1rem;
 height: 100%;

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover  {
    color: #4b59f7;
    transition: all 0.3s ease;
   
    }
 
 }
`

export const DropWrapper = styled.div`

 color : #fff;
 display: flex;
 justify-content:center;
 align-items: center;
 text-decoration: none;
 padding: 0.5rem 1rem;
 height: 100%;
//border: 2px solid blue;  

  @media screen and (max-width: 960px) {
    //text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover  {
    color: #4b59f7;
    transition: all 0.3s ease;
   
    }
 
 }
`

export const NavItemBtn = styled.li`
@media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
 
 }
`

export const NavBtnLink = styled(Link)`
@media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
 
 }
`
