import { Link } from "react-router-dom";
import styled from "styled-components";

import { btnReset, v } from "../../Variables";

export const SSidebar = styled.div`

display: flex;
flex-direction: column;
justify-content: flex-start;
align-items:center;

    width: 300px;
    background: #006633;
    height: 100%;
    padding: 20px;
    div{
        flex-direction:column ;
        display :flex;
        justify-content: left;
        align-items:left;
    }
   
`;

export const SLogo = styled.div`
    justify-content: center;

    width: 52px;
     align-items: center;
   /*   justify-content: center; */

    img {
        max-width: 100%;
        height: auto;
    }
    cursor: pointer;

    margin-bottom:20px;
`;

export const SSidebarButton = styled.button`
    ${btnReset};
    color: black;
    font-weight: medium;    
    font-size: 1rem !important;
    width: 200px;
    height:36px;
    border-radius: 5px    ;
    padding: 5px;
    margin-bottom: 20px;
    background: #00FF80;
   /*  box-shadow: 0 0 4px ${({ theme }) => theme.bg3}, 0 0 7px ${({ theme }) => theme.bg}; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;



export const SDivider = styled.div`
    height: 1px;
    width: 100%;
    background: grey;
    margin: 20px 20px;
`;

export const SLinkContainer = styled.div`
    background: ${({ theme, isActive }) => (!isActive ? `transparent` : theme.bg3)};
    border-radius: ${v.borderRadius};
    margin: 8px 0;
    display: flex;
    width:100%;
    flex-direction: row;
    justify-content: flex-start;
     align-items: left;
     text-align: left;    
   /*   border: 2px solid red; */

    :hover {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.bg3};
    }
`;

export const SLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    padding: calc(${v.smSpacing} - 2px) 0;
`;

export const SLinkIcon = styled.div`
    padding: ${v.smSpacing} ${v.mdSpacing};
    display: flex;

    svg {
        font-size: 20px;
    }
`;

export const SLinkLabel = styled.span`
    display: block;
    flex: 1;
    margin-left: ${v.smSpacing};
`;



export const STheme = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
`;
export const SThemeLabel = styled.span`
    display: block;
    flex: 1;
`;
export const SThemeToggler = styled.button`
    ${btnReset};
    margin: 0 auto;
    cursor: pointer;
    width: 36px;
    height: 20px;
    border-radius: 10px;
    background: ${({ theme, isActive }) => (!isActive ? theme.bg3 : theme.primary)};

    position: relative;
`;

export const SToggleThumb = styled.div`
    height: 18px;
    width: 18px;
    position: absolute;
    top: 1px;
    bottom: 1px;
    transition: 0.2s ease right;
    right: calc(100% - 18px - 1px);
    border-radius: 50%;
    background: ${({ theme }) => theme.bg};
`;