import React from 'react';
import { transferObjOne, transferObjTwo, transferObjThree, transferObjFour, transferObjFive, transferObjSix, transferObjSeven } from '../Data';

import  InfoSection from '../../Components/InfoSection/InfoSection';
import { Navbar, Footer } from '../../Components';
const Transfer = () => {
 return (
  <>
    <Navbar />
   <InfoSection {...transferObjOne} />
   <InfoSection {...transferObjTwo} />
   <InfoSection {...transferObjThree} />
  {/*  <InfoSection {...transferObjFour} />
   <InfoSection {...transferObjFive} />
   <InfoSection {...transferObjSix} />
   <InfoSection {...transferObjSeven} /> */}
    <Footer />
  </>
 )
}

export default Transfer;