
import React, {
  // useState,
  useRef, useEffect, useCallback
} from 'react';
// import emailjs from "@emailjs/browser";
// import { useNavigate } from "react-router-dom";
// import { MdClose } from 'react-icons/md';
// import { validate } from 'react-email-validator';
import { IconContext } from 'react-icons/lib';
//import { icon_plus, logoSVG_White, logoSVG_green, logoSVG_red, logoSVG_yellow, cancelSvg, checkSvg } from "../../Assets/Index";

//import { userTest, userDataTest, ConstValues } from '../../Pages/Protec/Data';
// import ModalSendEmail from '../../Components/ModalSendEmail/ModalSendEmail';
// import ModalSelectContact from '../ModalSelectContact/ModalSelectContact';

// import { Img } from '../../Pages/Protec/Protec.style';


import {
  Background, ModalWrapper, ModalContent, CloseModalButton,
  LabelWrapper, EmailWrapper,
  // EmailWrapper,
  CloseButtonWrapper, PWrapper,
  // StyledInput, StyledError,
  // ChargesWrapper, FeeWraper, RefWraper, EmailRef, BalanceRef, TimeRef,
  // NavLogoWrapper, NaveSettingLogo, AmountInput,
  // StyledAddUserButton,
  ReceiptItemWrapper
} from './Transaction.style';


// import { useUserAuth } from "../../context/UserAuthContex";
// import { FaSlideshare } from 'react-icons/fa';

const ModAlertTransaction = ({ showModal, setShowModal, selectedTransacP }) => {

  // const { signIn } = useUserAuth();
  // const { userData } = useUserAuth();
  // const { userContacts } = useUserAuth();
  // const { setUserContacts } = useUserAuth();

  // const [showModalSendEmail, setShowModalSendEmail] = useState(false);
  
  
  // const navigate = useNavigate();
  // const [newEmail, setNewEmail] = useState("");

  // const navigate = useNavigate();


  const modalRefTransaction = useRef();

  // const closeModalClick = e => {
  //   if (modalRefTransaction.current === e.target) {
  //     // setShowModal(false);
  //     setShowModal(!showModal);
  //     setWillSendInvitation(false);
  //     setIsError(false);
  //     setShowEmailInput(true);
  //     setIsMembershipChecked(false);
  //     setNewEmail('');
  //     setShowFindEmail(false);
  //     setIsMember(false);
  //     // setUserError(false);
  //   }
  // };

  const closeModal = e => {
    if (modalRefTransaction.current === e.target) {
      setShowModal(false);      

    }
  };

  // const goBackToParent = e => {

  //   console.log(' * goBackToParent clicked *');
   
  // };//==========  goBackToParent   =============


  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
        console.log('I pressedffffff');
      }
    },
    [setShowModal, showModal]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  // const handleInput = e => {
   
  // };//========  handleInput  =========

  useEffect(() => {
    //  setUserContacts(contactsArrayClean(userContactsuserData.transactions, userData.email));
    
  }, []);// =======  useEffect  ============
  





  return (
    <>
      <IconContext.Provider value={{ color: '#000' }}>
      {showModal ? (
        <Background onClick={closeModal} ref={modalRefTransaction}>
         
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <CloseButtonWrapper>
                  <CloseModalButton
                    aria-label='Close modal'
                    // onClick={closeModalClick}
                    onClick={() => setShowModal(prev => !prev)}
                    // onClick={closeModal}
                  />
                </CloseButtonWrapper>
                <br />

                {(selectedTransacP.type === "Transfer:Send") &&
                  <><PWrapper>Transfer:Send</PWrapper>
                  <LabelWrapper>Time Stamp</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.createdAt}</ReceiptItemWrapper>
                  <LabelWrapper>From </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.from}</ReceiptItemWrapper>
                  <LabelWrapper>To</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.to} </ReceiptItemWrapper>
                  <LabelWrapper>Type</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.type}</ReceiptItemWrapper>
                  <LabelWrapper>You Paid</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                  <LabelWrapper>Network Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.nf}</ReceiptItemWrapper>
                  <LabelWrapper>Service Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.sf}</ReceiptItemWrapper>
                  {/* <LabelWrapper>Cash Handling Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.fees}</ReceiptItemWrapper>
                   */}
                  <LabelWrapper>Beneficiary gets</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount - selectedTransacP.nf - selectedTransacP.sf}</ReceiptItemWrapper>
                  <LabelWrapper>Reference</LabelWrapper>
                  <PWrapper>{selectedTransacP.ref}</PWrapper>
                  </>
                }
                {(selectedTransacP.type === "Transfer:Receive") &&
                  <><PWrapper>Transfer:Receive</PWrapper>
                  <LabelWrapper>Time Stamp</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.createdAt}</ReceiptItemWrapper>
                  <LabelWrapper>From </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.from}</ReceiptItemWrapper>
                  <LabelWrapper>To</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.to} </ReceiptItemWrapper>
                  <LabelWrapper>Type</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.type}</ReceiptItemWrapper>
                  <LabelWrapper>Sender Sent</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount + selectedTransacP.sf}</ReceiptItemWrapper>
                  <LabelWrapper>Network Fees (Paid)</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.nf}</ReceiptItemWrapper>
                  <LabelWrapper>Service Fees (Paid)</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.sf}</ReceiptItemWrapper>
                  {/* <LabelWrapper>Cash Handling Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.fees}</ReceiptItemWrapper>
                   */}
                  <LabelWrapper>You Got</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                  <LabelWrapper>Reference</LabelWrapper>
                  <PWrapper>{selectedTransacP.ref}</PWrapper>
                  </>
                }

                {(selectedTransacP.type === "Trade:Buy") &&
                  <><PWrapper>Trade:Buy</PWrapper>
                  <LabelWrapper>Time Stamp</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.createdAt}</ReceiptItemWrapper>
                  <LabelWrapper>From </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.from}</ReceiptItemWrapper>
                  <LabelWrapper>To</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.to} </ReceiptItemWrapper>
                  <LabelWrapper>Type</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.type}</ReceiptItemWrapper>
                  <LabelWrapper>You Paid Cash</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                  <LabelWrapper>Network Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.nf}</ReceiptItemWrapper>
                  <LabelWrapper>Service Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.sf}</ReceiptItemWrapper>
                  <LabelWrapper>Cash Handling Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.chf}</ReceiptItemWrapper>
                 
                  <LabelWrapper>You Got</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount - selectedTransacP.nf - selectedTransacP.sf}</ReceiptItemWrapper>
                  <LabelWrapper>Reference</LabelWrapper>
                  <PWrapper>{selectedTransacP.ref}</PWrapper>
                  </>


                }
                {(selectedTransacP.type === "Trade:Sell") &&
                  <><PWrapper>Trade:Sell</PWrapper>
                  <LabelWrapper>Time Stamp</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.createdAt}</ReceiptItemWrapper>
                  <LabelWrapper>From </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.from}</ReceiptItemWrapper>
                  <LabelWrapper>To</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.to} </ReceiptItemWrapper>
                  <LabelWrapper>Type</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.type}</ReceiptItemWrapper>
                  <LabelWrapper>You Sold Paydoroz</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                  <LabelWrapper>Network Fees </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.nf}</ReceiptItemWrapper>
                  <LabelWrapper>Service Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.sf}</ReceiptItemWrapper>
                  <LabelWrapper>Cash Handling Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.chf}</ReceiptItemWrapper>

                  <LabelWrapper>You Received Cash from Partner</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount - selectedTransacP.chf - selectedTransacP.nf - selectedTransacP.sf}</ReceiptItemWrapper>
                  <LabelWrapper>Reference</LabelWrapper>
                  <PWrapper>{selectedTransacP.ref}</PWrapper>
                  </>


                }
                {(selectedTransacP.type === "Pay:Send") &&
                  <><PWrapper>Pay:Send</PWrapper>
                  <LabelWrapper>Time Stamp</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.createdAt}</ReceiptItemWrapper>
                  <LabelWrapper>From </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.from}</ReceiptItemWrapper>
                  <LabelWrapper>To</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.to} </ReceiptItemWrapper>
                  <LabelWrapper>Type</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.type}</ReceiptItemWrapper>
                  <LabelWrapper>You Paid </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                  <LabelWrapper>Network Fees (paid)  </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.nf}</ReceiptItemWrapper>
                  <LabelWrapper>Service Fees (paid)</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.sf}</ReceiptItemWrapper>
                  {/* <LabelWrapper>Your Cash Handling Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.chf}</ReceiptItemWrapper> */}

                  <LabelWrapper>You Sent to buyer</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                  <LabelWrapper>Reference</LabelWrapper>
                  <PWrapper>{selectedTransacP.ref}</PWrapper>
                  </>


                }
                {(selectedTransacP.type === "Pay:Receive") &&
                  <><PWrapper>Pay:Receive</PWrapper>
                  <LabelWrapper>Time Stamp</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.createdAt}</ReceiptItemWrapper>
                  <LabelWrapper>From </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.from}</ReceiptItemWrapper>
                  <LabelWrapper>To</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.to} </ReceiptItemWrapper>
                  <LabelWrapper>Type</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.type}</ReceiptItemWrapper>
                  <LabelWrapper>Customer Paid </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                  <LabelWrapper>Network Fees </LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.nf}</ReceiptItemWrapper>
                  <LabelWrapper>Service Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.sf}</ReceiptItemWrapper>
                  {/* <LabelWrapper>Your Cash Handling Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.chf}</ReceiptItemWrapper> */}

                  <LabelWrapper>You Got from Customer -Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount - selectedTransacP.nf - selectedTransacP.sf}</ReceiptItemWrapper>
                  <LabelWrapper>Reference</LabelWrapper>
                  <PWrapper>{selectedTransacP.ref}</PWrapper>
                  </>


                }
                {(selectedTransacP.type === "Flexy:Pay") &&
                  <><PWrapper>Flexy:Pay</PWrapper>
                    <LabelWrapper>Time Stamp</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.createdAt}</ReceiptItemWrapper>
                    <LabelWrapper>From </LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.from}</ReceiptItemWrapper>
                    <LabelWrapper>To</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.to} </ReceiptItemWrapper>
                    <LabelWrapper>Type</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.type}</ReceiptItemWrapper>
                    <LabelWrapper>You Paid</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount + selectedTransacP.sf}</ReceiptItemWrapper>
                    {/* <LabelWrapper>Network Fees</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.nf}</ReceiptItemWrapper>
                   */}
                    <LabelWrapper>Service Fees</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.sf}</ReceiptItemWrapper>
                    {/* <LabelWrapper>Cash Handling Fees</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.fees}</ReceiptItemWrapper>
                   */}
                    <LabelWrapper>Your Top up is</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                  <LabelWrapper>Reference</LabelWrapper>
                  <PWrapper>{selectedTransacP.ref}</PWrapper>
                  </>
                }


                {(selectedTransacP.type === "Flexy:Receive") &&
                  <><PWrapper>Flexy:Receive</PWrapper>
                    <LabelWrapper>Time Stamp</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.createdAt}</ReceiptItemWrapper>
                    <LabelWrapper>From </LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.from}</ReceiptItemWrapper>
                    <LabelWrapper>To</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.to} </ReceiptItemWrapper>
                    <LabelWrapper>Type</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.type}</ReceiptItemWrapper>
                    <LabelWrapper>You Paid</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                    {/* <LabelWrapper>Network Fees</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.nf}</ReceiptItemWrapper> */}
                    <LabelWrapper>Service Fees</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.sf}</ReceiptItemWrapper>
                    {/* <LabelWrapper>Cash Handling Fees</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.chf}</ReceiptItemWrapper> */}
                    <LabelWrapper>Beneficiary gets</LabelWrapper>
                  <ReceiptItemWrapper>{selectedTransacP.amount - selectedTransacP.sf}</ReceiptItemWrapper>
                  <LabelWrapper>Reference</LabelWrapper>
                  <PWrapper>{selectedTransacP.ref}</PWrapper>
                  </>
                }                
                {(selectedTransacP.type === "Balance Brought Forward") &&
                  <><PWrapper>Balance Brought Forward</PWrapper>
                    <EmailWrapper>Transaction Details</EmailWrapper>                   
                    <LabelWrapper>Time Stamp</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.createdAt}</ReceiptItemWrapper>
                    <LabelWrapper>From Us </LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.from}</ReceiptItemWrapper>
                    <LabelWrapper>We Updated your Account</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.to} </ReceiptItemWrapper>
                    <LabelWrapper>This is Your</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.type}</ReceiptItemWrapper>
                    <LabelWrapper>Your Amount</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.amount}</ReceiptItemWrapper>
                    <LabelWrapper>Fees</LabelWrapper>
                    <ReceiptItemWrapper>No Fees for this Transaction{selectedTransacP.nf}</ReceiptItemWrapper>
                    {/* <LabelWrapper>Service Fees</LabelWrapper>
                    <ReceiptItemWrapper>{selectedTransacP.sf}</ReceiptItemWrapper>
                    <LabelWrapper>Cash Handling Fees</LabelWrapper> */}
                    <ReceiptItemWrapper>{selectedTransacP.chf}</ReceiptItemWrapper> 
                    {/*                   
                    <LabelWrapper>Beneficiary gets</LabelWrapper>
                    <ReceiptItemWrapper>{-1.0 * (selectedTransacP.amount) - selectedTransacP.fees}</ReceiptItemWrapper> 
                    */}

                  <LabelWrapper>Reference</LabelWrapper>
                  <PWrapper>{selectedTransacP.ref}</PWrapper>
                  </>
                }                
                 
 

              
              
            </ModalContent>           

         
            </ModalWrapper>

             
        </Background>
        ) : null}
        
        {/* {showModalSendEmail  &&       
            <ModalSendEmail showModal={showModal} setShowModal={setShowModalSendEmail} selectedEmail={newEmail}/> 
        }   */}
       {/*         
         {showModalSelectContact &&  
        <ModSendToContact showModal={showModSendToContact} setShowModal={setShowModalSelectContact} selectedEmail={newEmail} />
         }    */}

      </IconContext.Provider>
    </>
  );
};
export default ModAlertTransaction;

