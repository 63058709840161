import React, {
 useState
 // , useRef
} from 'react';
import { useNavigate } from "react-router-dom";
// import { validate } from 'react-email-validator';
import {
  dbb,
  // auth
} from "../../Firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { usersCollectionRef } from '../../Firebase';
import {
  collection, getDocs, addDoc, updateDoc, doc,
  // deleteDoc,
  query, where
} from 'firebase/firestore'
import { useUserAuth } from "../../context/UserAuthContex"
/* import styled from "styled-components"; */
import { ConstValues, userTranRow } from '../../Pages/Protec/Data';
import {
 jezzyMenu,
 mobilisMenu,
 ooredooMenu,
 jezzyEmail,
 mobilisEmail,
 ooredooEmail
} from './Data';

import {
 // Transactions,
 OfferWraper,
 // RefWraper, EmailRefFrom, BalanceRef,
 // TransWraper,
 OffersWraper,
  // ChargesWrapper, FeeWraper, FeeWraper2,
  // BalanceRef,
 LabelWrapper,
 ButtonModal,  StyledError,
 EmailWrapper,
 // StyledInput,
 StyledInput2, Img,Img2,
 // AmountWrapper, AmountInput,
 // ModalContent,
 // ,  
 // StyledAddUserButton,
 // ContactWraper, ContactWraperInner,
 NavLogoWrapper,
 NavLogoWrapper2,
 NavBalanceWrapper,
 TitleWrapper,
 // StyledRightWrapper,
 // NaveSettingLogo,
 // EmailRef,
 // EmailRef2,
 NewContactWrapper,AddUserButtonWrapper,StyledTradeBuyButton,
 // IconAddUser, TitleAddUser,
 PWrapper, RDialogueWrapper,
 // StyledAddUserButton,
  StyledAddUserButtonOffer,
 // AddUserButtonWrapperOffer,
 // StyledTradeBuyButtonRed,
 // SendStyledRightContainer,
 // ListContactWrapper, TitleSelectUser
} from '../Protec/Protec.style';

import {
 Container2,InfoRow,InfoColumnLeft, InfoColumnRight, 
 RightColTopNavWrapper, NavLeftWrapper,
 NavRightWrapper,
 RightColBottomWrapper, StyledRightContainer
} from '../../globalStyles';
/* import InfoSectionSignin from '../../Components/InfoSectionSignIn/InfoSectionSignIn';
 */

import SidebarD from '../../Components/SidebarD/SidebarD';
import NavbarD from '../../Components/NavbarD/NavbarD';
// // 
import { logoSVG_red } from "../../Assets/Index";

//https:/ / github.com / gkhan205 / react - reu...



const FlexyD = () => {
 // const { signIn } = useUserAuth();
 // const modalRefX = useRef();
 const navigate = useNavigate();

 const { userData } = useUserAuth();
 // const { userContacts } = useUserAuth();

 // const [showAskForTrade, setShowAskForTrade] = useState(true);
 // const [showTreadeBuySelected, setShowTreadeBuySelected] = useState(false);
 const [showTreadeSellSelected, setShowTreadeSellSelected] = useState(true);
 // const [showCalculator, setShowCalculator] = useState(false);
 // const [showCalculator2, setShowCalculator2] = useState(false);
 // const [showContinue, setShowContinue] = useState(false);
 // const [showContinue2, setShowContinue2] = useState(false);
 const [showContinue3, setShowContinue3] = useState(false);
 const [showAskConfirmPhoneNumber, setShowAskConfirmPhoneNumber] = useState(false);
 const [showConfirmPhoneNumber, setShowConfirmPhoneNumber] = useState(false);
 //  const [showNotFoundResult, setShowNotFoundResult] = useState(false);
 // const [showFoundResult, setShowFoundResult] = useState(false);

 const [showJezzy, setShowJezzy] = useState(false);//false
 const [showJezzyOffers, setShowJezzyOffers] = useState(false);//false

 const [showMobilis, setShowMobilis] = useState(false);
 const [showMobilisOffers, setShowMobilisOffers] = useState(false);//false

 const [showOoredoo, setShowOoredoo] = useState(false);
 const [showOoredooOffers, setShowOoredooOffers] = useState(false);//false


 const [showOfferSelected, setShowOfferSelected] = useState(false);
 const [selectedOfferName, setSelectedOfferName] = useState('');
 const [selectedOfferValue, setSelectedOfferValue] = useState('');



 const [newPhone, setNewPhone] = useState('');
 // const [newEmail, setNewEmail] = useState('');
 const [emailOfSupplier, setEmailOfSupplier] = useState('');
 const [isError, setIsError] = useState(true);
 const [Error, setError] = useState("");
  // const [amount, setAmount] = useState();
  // const [amount2, setAmount2] = useState();
  // const [amountNet, setAmountNet] = useState();
  // const [amountNet2, setAmountNet2] = useState();
  // const [nf, setNf] = useState(0);
  // const [sf, setSf] = useState(0);
  // const [chf, setChf] = useState(0);
  // const [feez, setFeez] = useState(0);
  // const [feez2, setFeez2] = useState(0);
  // const [pTouch, setPTouch] = useState('');
  // const { setUserContacts } = useUserAuth();

  // const [email, setEmail] = useState("jamel@tesco.net");
  // const [password, setPassword] = useState("");

  // const [error, setError] = useState("");
  // const [isError, setIsError] = useState(true);
  // const [isPsError, setIsPsError] = useState(true);
  // const navigate = useNavigate();

  // const { signIn } = useUserAuth();

  // const [contactsArray, setContactsArray] = useState([]);
  // const [emailTo, setEmailTo] = useState('');


  // const [showModAddContact, setShowModAddContact] = useState(false);
  // const [showModSendToContact, setShowModSendToContact] = useState(false);

 function netFees() {
  return Number(ConstValues.nf)
 };//=========    sendAmountToContact ================

 function serviceFees(amountToSend) {
  // let tsfee = Math.round(amountToSend * ConstValues.sf);
  if (amountToSend <= 1000) {
   return Number(ConstValues.sffix);
  } else {
   return Number(Math.round(amountToSend * ConstValues.sf) + Number(ConstValues.sffix));
  }
 };//=========    serviceFees ================

  // function cashHandlingFees(amountToSend) {
  //   if (amountToSend <= 1000) {
  //     return Number(ConstValues.sffix);
  //   } else {
  //     return Number(Math.round(amountToSend * ConstValues.chf) + Number(ConstValues.chffix));
  //   }
  // };//=========    serviceFees ================
 
 function createTransaction(timeF, fromF, toF, typeF, idF, amountF, refF) {
  //  let time = new Date().toString().slice(0, 25);    
  let tran = JSON.parse(JSON.stringify(userTranRow));
  tran.createdAt = timeF;
  tran.from = fromF;
  tran.to = toF;
  tran.type = typeF;
  tran.id = idF;
  tran.amount = Number(amountF);
  // tran.nf = netFees();
  tran.nf = 0;
  tran.sf = serviceFees(amountF);
  // tran.chf = cashHandlingFees(amountF)
  tran.chf = 0;
  tran.ref = refF;

  return tran
 };//==========   createTransaction

 const continueToDash = () => {
  navigate('/Protec/Dashboard');
 };//========  openPreview
 
 const handleNewPhoneInput = e => {
  // const inputName = e.currentTarget.name;
  const value = e.currentTarget.value;
  //===============
  let regex = /^[0]\d{9}$/;
  if (regex.test(value.trim()) === false) {
   setIsError(true);
   // setError('Please Enter a valid Phone');
  }
  else {
   setIsError(false);
   // setError('');
   setShowAskConfirmPhoneNumber(true);
  }
  setNewPhone(value);
 };//================  handleNewPhoneInput


 const confirmPhoneNumberBeforeFlexy = e => {

  setShowTreadeSellSelected(false);
  setShowConfirmPhoneNumber(true);


  let code = newPhone.slice(0, 2);
  console.log(" code", code)

  switch (code) {
   case '07':
    console.log('Djezzy selected');
    setShowJezzy(true);
    setShowJezzyOffers(true);
    break;
   case '06':
    console.log('Mobilis selected');
    setShowMobilis(true);
    setShowMobilisOffers(true);
    break;
   case '05':
    console.log('Djezzy selected');
    setShowOoredoo(true);
    setShowOoredooOffers(true);
    break;
   default:
    console.log(`Sorry, can 't flexy to this number.`);
    isError(true);
    setError('Sorry cannot flexy selected number');
  }
 };//=======  confirmPhoneNumberBeforeFlexy

 const ShowJezzyTranDetails = e => {

  console.log('e  :', e);
  console.log('e.target  :', e.target);
  console.log('e.target.value  :', e.target.value);
  console.log('e.target.innerHTML  :', e.target.innerHTML);
  console.log('e.target.name  :', e.target.name);
  console.log('e.target.id  :', e.target.id);
  setSelectedOfferName(e.target.name);
  setSelectedOfferValue(Number(e.target.value));
  setShowTreadeSellSelected(false);
  setShowConfirmPhoneNumber(false);
  setShowConfirmPhoneNumber(false);
  setShowJezzyOffers(false);
  setShowOfferSelected(true);
  setEmailOfSupplier(jezzyEmail);
  };//============ ShowJezzyTranDetails
  
  const ShowMobilisTranDetails = e => {

    console.log('e  :', e);
    console.log('e.target  :', e.target);
    console.log('e.target.value  :', e.target.value);
    console.log('e.target.innerHTML  :', e.target.innerHTML);
    console.log('e.target.name  :', e.target.name);
    console.log('e.target.id  :', e.target.id);
    setSelectedOfferName(e.target.name);
    setSelectedOfferValue(Number(e.target.value));
    setShowTreadeSellSelected(false);
    setShowConfirmPhoneNumber(false);
    setShowConfirmPhoneNumber(false);
    setShowMobilisOffers(false);
    setShowOfferSelected(true);
    setEmailOfSupplier(mobilisEmail);
  };//============ ShowMobilisTranDetails


  const ShowOoredooTranDetails = e => {
    console.log('e  :', e);
    console.log('e.target  :', e.target);
    console.log('e.target.value  :', e.target.value);
    console.log('e.target.innerHTML  :', e.target.innerHTML);
    console.log('e.target.name  :', e.target.name);
    console.log('e.target.id  :', e.target.id);
    setSelectedOfferName(e.target.name);
    setSelectedOfferValue(Number(e.target.value));
    setShowTreadeSellSelected(false);
    setShowConfirmPhoneNumber(false);
    setShowConfirmPhoneNumber(false);
    setShowOoredooOffers(false);
    setShowOfferSelected(true);
    setEmailOfSupplier(ooredooEmail);
  };//============ ShowOoredooTranDetails

 async function sendPaydorozToFlexy() {

  let tempId = '';
  let amounty = Number(selectedOfferValue);

  // setShowAskForAmountButton(false);
  // setShowAskForAmount(false);
  // setShowPreview(false);
  // createTransaction(time, from, to, type, id, amount,ref)
  let time = new Date().toString().slice(0, 25);
  let nfC = netFees(); console.log('nf :', nfC);
  let sfC = serviceFees(amounty); console.log('sfC :', sfC);
  // let chfC = cashHandlingFees(amounty); console.log('chfC :', chfC);

  let mainTransR = createTransaction(time, userData.email, emailOfSupplier, "Flexy", '', amounty, "User Flexy");
  let mainTrans = JSON.parse(JSON.stringify(mainTransR));
  //  setTimeOfTransaction(mainTrans.createdAt);
  let sendTransR = createTransaction(time, userData.email, emailOfSupplier, "Flexy:Pay", '', amounty, "Thanks For Trading with me");
  let sendTrans = JSON.parse(JSON.stringify(sendTransR));
  //createTransaction(timeF,  fromF,  toF,  typeF, idF, amountF,refF)

  let recTrans = createTransaction(time, userData.email, emailOfSupplier, "Flexy:Receive", '', amounty, "Thanks for The Service");

  //save tranTransfert to  transCollection 
  //get doc id from transCollection    
  //====== start part 1   
  console.log('*********** Start tranCollection ************');
  try {
   const docRef = addDoc(collection(dbb, "tranCollection"), mainTrans);
   tempId = (await docRef).id;
   sendTrans.id = tempId;
   recTrans.id = tempId;
  } catch (e) {
   setIsError(true); setError(e);
  }

  console.log('*********** end tranCollection ************');
  // ==============   
  let tSenderData = {};
  // let tTransactions = [];
  let tSendToken = '';
  let tBalance = 0;
  //update sender balance and record 

  const qSender = query(collection(dbb, "users"), where("email", "==", userData.email));
  const querySnapshot = await getDocs(qSender);
  querySnapshot.docs.forEach((doc) => {
   tSendToken = doc.id;
   tSenderData = JSON.parse(JSON.stringify(doc.data()));
  });

  tBalance = tSenderData.balance;


  // tTransactions = JSON.parse(JSON.stringify(tSenderData.transactions));      
  //***********************************************
  // check balance positive before continue
  //***********************************************

  // tTransactions.unshift(sendTrans);
  tSenderData.transactions.unshift(sendTrans);

  tSenderData.balance = Number(tBalance) - Number(amounty) - Number(sfC);
  const sendRef = doc(dbb, 'users', tSendToken);
  // updateDoc(sendRef, { balance: Number(tBalance) - Number(amounty), transactions: tTransactions }).then(() => { });
  updateDoc(sendRef, tSenderData).then(() => { console.log('shit') });
  //catch any errors     
  //====== end  part 1

  //====== start  part 2


  // ==============   
  let tRecData = {};
  let tRecTransactions = [];
  let tRecToken = '';
  let tRecBalance = 0;
  //update sender balance and record 
  const qRec = query(collection(dbb, "users"), where("email", "==", emailOfSupplier));
  const queryRecSnapshot = await getDocs(qRec);
  queryRecSnapshot.docs.forEach((doc) => {
   tRecToken = doc.id;
   tRecData = JSON.parse(JSON.stringify(doc.data()));
   tRecToken = doc.id;
  });
  tRecBalance = tRecData.balance;

  tRecTransactions = JSON.parse(JSON.stringify(tRecData.transactions));
  //***********************************************
  // check balance positive before continue
  //***********************************************

  tRecTransactions.unshift(recTrans);
  tRecData.balance = Number(tRecBalance) + Number(amounty);
  const RecRef = doc(dbb, 'users', tRecToken);
  updateDoc(RecRef, { balance: Number(tRecBalance) + Number(amounty), transactions: tRecTransactions }).then(() => { });
  //catch any errors
  //====== end  part 2
  localStorage.setItem("userData", JSON.stringify((tSenderData)));
  //===============    
  //sendEmailSellReceiptViaEmailJs(toEmail,atTime,fromEmail,netPaydoroz,forTotalCash)
  //sendEmailSellReceiptViaEmailJs( userData.email,time,newEmail, amounty, amounty);
  // update both sender and receiver as transaction or batch
  // ************************    setShowCompleted(true);  *****************************
  //return to
  //  navigate('/Protec/Dashboard');
  setShowContinue3(true);
  // setShowContinue2(false);
  // setShowCalculator2(false);
  // setShowFoundResult(false);
  setShowTreadeSellSelected(false);
  setShowOfferSelected(false);

 };//========  sendPaydorozToFlexy

 return (
  <Container2>
   <InfoRow>
    {/*   =================left Column=============== */}
    <InfoColumnLeft>
     <SidebarD /> 
    </InfoColumnLeft>

    {/*   =================Right column=============== */}    
    <InfoColumnRight>
     <RightColTopNavWrapper>

      <NavLeftWrapper>
       {/* <NavLogoWrapper>
                 <img src={logoSVG_red} alt="logo" />
                </NavLogoWrapper>  */}
       <NavBalanceWrapper>
        {userData.fname}
       </NavBalanceWrapper>
      </NavLeftWrapper>

      <NavRightWrapper>
       <NavLogoWrapper>
        <img src={logoSVG_red} alt="logo" />
       </NavLogoWrapper>
       <NavBalanceWrapper>
        {Intl.NumberFormat().format(userData.balance)}
       </NavBalanceWrapper>
      </NavRightWrapper>

     </RightColTopNavWrapper>

     {/*   =================Right Bottom column=============== */}
     <RightColBottomWrapper>
      <StyledRightContainer>       
       {/* <h1 style={{ color: "red" }}>FlexyD</h1> */}

       {showTreadeSellSelected &&
        <>
         <TitleWrapper>Flexy</TitleWrapper>
          <LabelWrapper>Please Enter Phone Number</LabelWrapper>
          <EmailWrapper>
           <StyledInput2 autoFocus
             type="phone"
             name="newPhone"
             value={newPhone}
             onChange={handleNewPhoneInput} />
             {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
              (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
             }
         </EmailWrapper>
         
         {showAskConfirmPhoneNumber &&
           <NewContactWrapper>
              <AddUserButtonWrapper>
               <StyledTradeBuyButton bgCol={'#006633'} onClick={confirmPhoneNumberBeforeFlexy}>Continue</StyledTradeBuyButton>            
              </AddUserButtonWrapper> 
             </NewContactWrapper>
         } 
        </>
       }

       {showConfirmPhoneNumber &&
        <>
        <RDialogueWrapper color={`#006633`} colortwo={`#FE0B0B`}>
         <br /> <br />
         you have selected to Flexy to <br /> <br />
         <strong >{newPhone} </strong><br />
         Please select the offer from the list bellow</RDialogueWrapper>					
       </>
       }



       {showJezzy &&
        <>
         {showJezzyOffers && <>
          <PWrapper color={`#000000`}> <br /><br /> <strong> Djezzy Offers</strong> <br />
          </PWrapper>

          <OffersWraper>
           {/* <div className='jamDiv'> */}
           {jezzyMenu.map((tr, index) =>
           (<div key={index}>
            <OfferWraper >
             <NavLogoWrapper2>
              <Img2 src={tr.imgName} alt="logo" />
              <StyledAddUserButtonOffer bgcol={'#FE0B0B'} onClick={ShowJezzyTranDetails} value={tr.value} name={tr.name}>Select</StyledAddUserButtonOffer>
             </NavLogoWrapper2>
            </OfferWraper>
           </div>))
           }
           {/* </div>                  */}
          </OffersWraper>
         </>
         }

         {showOfferSelected &&
          <>
           {((userData.balance - selectedOfferValue - serviceFees(selectedOfferValue)) < 0) ?
            <>
             <StyledError>Sorry not Enough funds To Flexy</StyledError>
             <ButtonModal onClick={continueToDash}>Continue To Dash</ButtonModal>
            </>
            :
            <>
              <RDialogueWrapper color={`#006633`} colortwo={`#FE0B0B`}><br /><br /> <strong>{newPhone}</strong> <br />
              Offer Name:{selectedOfferName} <br />
              Costs :{selectedOfferValue}<br /><br />
              we charge a small fee :{serviceFees(selectedOfferValue)} DA<br />
              By clicking your Paydoroz will be debited with <br /><br />{selectedOfferValue + serviceFees(selectedOfferValue)} DA
              </RDialogueWrapper>
             <br /><br />
             <StyledAddUserButtonOffer bgcol={'#FE0B0B'} onClick={sendPaydorozToFlexy}>Flexy</StyledAddUserButtonOffer>
            </>
           }
          </>
         }



        </>
             } {/* =================  showJezzy ===============*/}
             

             {/* =================  showMobilis ===============*/}
             {showMobilis &&
               <>
                 {showMobilisOffers && <>
                   <PWrapper color={`#000000`}> <br /><br /> <strong> Mobilis Offers</strong> <br />
                   </PWrapper>

                   <OffersWraper>
                     {/* <div className='jamDiv'> */}
                     {mobilisMenu.map((tr, index) =>
                     (<div key={index}>
                       <OfferWraper >
                         <NavLogoWrapper2>
                           <Img2 src={tr.imgName} alt="logo" />
                           <StyledAddUserButtonOffer bgcol={'#218706'} onClick={ShowMobilisTranDetails} value={tr.value} name={tr.name}>Select</StyledAddUserButtonOffer>
                         </NavLogoWrapper2>
                       </OfferWraper>
                     </div>))
                     }
                     {/* </div>                  */}
                   </OffersWraper>
                 </>
                 }

                 {showOfferSelected &&
                   <>
                     {((userData.balance - selectedOfferValue - serviceFees(selectedOfferValue)) < 0) ?
                       <>
                         <StyledError>Sorry not Enough funds To Flexy</StyledError>
                         <ButtonModal onClick={continueToDash}>Continue To Dash</ButtonModal>
                       </>
                       :
                       <>
                         <PWrapper color={`#000000`}><br /><br /> <strong>{newPhone}</strong> <br />
                           Offer Name:{selectedOfferName} <br />
                           Costs :{selectedOfferValue}<br /><br />
                           we charge a small fee :{serviceFees(selectedOfferValue)} DA<br />
                           By clicking your Paydoroz will be debited with <br /><br />{selectedOfferValue + serviceFees(selectedOfferValue)} DA
                         </PWrapper>
                         <br /><br />
                         <StyledAddUserButtonOffer bgcol={'#FE0B0B'} onClick={sendPaydorozToFlexy}>Flexy</StyledAddUserButtonOffer>
                       </>
                     }
                   </>
                 }



               </>

             }{/* =================  showMobilis ===============*/}   
             {/* =================  showOoredoo ===============*/}
             {showOoredoo &&
               <>
                 {showOoredooOffers && <>
                   <PWrapper color={`#000000`}> <br /><br /> <strong> Ooredoo Offers</strong> <br />
                   </PWrapper>

                   <OffersWraper>
                     {/* <div className='jamDiv'> */}
                     {ooredooMenu.map((tr, index) =>
                     (<div key={index}>
                       <OfferWraper >
                         <NavLogoWrapper2>
                           <Img2 src={tr.imgName} alt="logo" />
                           <StyledAddUserButtonOffer bgcol={'#FE0B0B'} onClick={ShowOoredooTranDetails} value={tr.value} name={tr.name}>Select</StyledAddUserButtonOffer>
                         </NavLogoWrapper2>
                       </OfferWraper>
                     </div>))
                     }
                     {/* </div>                  */}
                   </OffersWraper>
                 </>
                 }

                 {showOfferSelected &&
                   <>
                     {((userData.balance - selectedOfferValue - serviceFees(selectedOfferValue)) < 0) ?
                       <>
                         <StyledError>Sorry not Enough funds To Flexy</StyledError>
                         <ButtonModal onClick={continueToDash}>Continue To Dash</ButtonModal>
                       </>
                       :
                       <>
                         <PWrapper color={`#000000`}><br /><br /> <strong>{newPhone}</strong> <br />
                           Offer Name:{selectedOfferName} <br />
                           Costs :{selectedOfferValue}<br /><br />
                           we charge a small fee :{serviceFees(selectedOfferValue)} DA<br />
                           By clicking your Paydoroz will be debited with <br /><br />{selectedOfferValue + serviceFees(selectedOfferValue)} DA
                         </PWrapper>
                         <br /><br />
                         <StyledAddUserButtonOffer bgcol={'#FE0B0B'} onClick={sendPaydorozToFlexy}>Flexy</StyledAddUserButtonOffer>
                       </>
                     }
                   </>
                 }



               </>


             }{/* =================  showOoredoo ===============*/}


        {/* =====Continue To Dashboard============= */}
        {showContinue3 &&
          <>
               <RDialogueWrapper color={`#006633`} colortwo={`#FE0B0B`}>
              <br /><br /> <strong> Receipt</strong> <br />            
                  A receipt has been sent to your Email. Keep it safe.
              <br /><br /> <strong> {userData.email}</strong> <br />
               </RDialogueWrapper>
            <ButtonModal onClick={continueToDash}>Continue To Dash</ButtonModal>  </>
        }  <br /> <br />
        {/* {(!showModAddContact && !showModSendToContact) && <NavbarD />} */}
        {isError && <StyledError>{Error}</StyledError>}      



      

      </StyledRightContainer>
      <NavbarD />
     </RightColBottomWrapper>
    </InfoColumnRight>
   </InfoRow>   
   
  </Container2>
 )
};

export default FlexyD;