import React, { useEffect } from 'react';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour, homeObjFive, homeObjSix, homeObjSeven } from '../Data';
import InfoSectionHome from '../../Components/InfoSectionHome/InfoSectionHome';
import { Navbar, Footer } from '../../Components';


const Home = () => {

 
  
  return (
    <>
      <Navbar />
      <InfoSectionHome {...homeObjOne} />
     {/*  <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjThree} /> 
      <InfoSection {...homeObjFour} />
      <InfoSection {...homeObjFive} />
      <InfoSection {...homeObjSix} />
      <InfoSection {...homeObjSeven} /> */}       
      <Footer />
   </>
  )
}

export default Home;