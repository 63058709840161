
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { validate } from 'react-email-validator';
// import styled from 'styled-components';
import emailjs from "@emailjs/browser";
import ListOfCities from '../SignUp/listOfCities.json';
import { userDataRow } from "../../Pages/Protec/Data";
import { dbb } from "../../Firebase";
import { doc, setDoc } from "firebase/firestore";

import { useUserAuth } from "../../context/UserAuthContex"

//import { auth } from "../../Firebase";
//import { signInWithEmailAndPassword } from "firebase/auth";
//import { usersCollectionRef } from '../../Firebase';
//import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore'
// import { useUserAuth } from "../../context/UserAuthContex"
/* import styled from "styled-components"; */
import {

	InfoRow, InfoColumnLeft, InfoColumnRight,
	// InputWrapperInvisible,
	RightColTopNavWrapper, NavLeftWrapper, NavLogoWrapper, NavBalanceWrapper,

	StyledRightWrapper, StyledRightContainer,
	RDialogueWrapper, RDialogueWrapperPreview,
	Img, IconWrapper2, Welcome,
	InfoLeftWrapper,
	// StyledForm,
	InputWrapper, StyledInput, StyledButton,
	StyledError, ButtonWrapper,
	LabelWrapper,
	// EmailWrapper,
	SelectWrapper, SelectMr
} from '../SignUp/SignUp.style';

import { Container2 } from '../../globalStyles';
/* import InfoSectionSignin from '../../Components/InfoSectionSignIn/InfoSectionSignIn';
	*/

// import SidebarD from '../../Components/SidebarD/SidebarD';
// import NavbarD from '../../Components/NavbarD/NavbarD';

import { logoSVG_red } from "../../Assets/Index";
// import ModSetAddress from '../../Components/ModSet/ModSetAddress';
// import ModSetSecurity from '../../Components/ModSet/ModSetSecurity';
// import ModSetPhone from '../../Components/ModSet/ModSetPhone';
// import ModSetEmail from '../../Components/ModSet/ModSetEmail';
// import ModSetPassword from '../../Components/ModSet/ModSetPassword';
// import ModSetProfile from '../../Components/ModSet/ModSetProfile';

// import { GiSleepingBag } from 'react-icons/gi';

// import ListOfCities from '../../Components/ModSet/listOfCities.json';

//https://github.com/gkhan205/react-reu...

let e_otp_key = '';
let f_otp_key = '';

const SignUp = () => {
	//        d.chekroud@gmail.com
	//        0796642676

	const { signUp } = useUserAuth();
	const navigate = useNavigate();

	

	const [showWelcomeLogo, setShowWelcomeLogo] = useState(true);
	const [showInputEmails, setShowInputEmails] = useState(true);// true
	const [email, setEmail] = useState("");
	const [isEmailError, setIsEmailError] = useState(true);
	const [emailConfirm, setEmailConfirm] = useState("");
	const [isEmailConfirmError, setIsEmailConfirmError] = useState(true);
	const [Error, setError] = useState("");
	// const [isError, setIsError] = useState(false);
	const [showAskForOtp, setShowAskForOtp] = useState(false);// false
	const [isOtpError, setIsOtpError] = useState(true);
	const [isOtpVerified, setIsOtpVerified] = useState(false);
	const [otpField, setOtpField] = useState('');
	const [showAskForProfile, setShowAskForProfile] = useState(false);// false

	const [profile, setProfile] = useState({
		mrorms: '',
		fName: '',
		lName: ''
	});
	const [showfName, setShowfName] = useState(false);
	const [showlName, setShowlName] = useState(false);
	const [isfNameError, setIsfNameError] = useState(true);
	const [islNameError, setIslNameError] = useState(true);

	const [showAskForAddress, setShowAskForAddress] = useState(false);//false
	const [address, setAddress] = useState(
		{
			addressLine: '',
			willaya: '',
			daira: '',
			baladiya: '',
			postCode: ''
		});
	const [showDaira, setShowDaira] = useState(false);
	const [showBaladia, setShowBaladia] = useState(false);
	const [showContinue, setShowContinue] = useState(false);

	const [security, setSecurity] = useState(
		{
			pinNumber: '',
			question: '',
			answer: ''
		});

	const [showAskForSecurity, setShowAskForSecurity] = useState(false);//false
	const [isPinError, setIsPinError] = useState(true);
	const [isQuestionError, setIsQuestionError] = useState(true);
	const [isAnswerError, setIsAnswerError] = useState(true);

	const [showAskForPhone, setShowAskForPhone] = useState(false);//false
	const [phone, setPhone] = useState("");
	const [isPhoneError, setIsPhoneError] = useState(true);
	const [otpFieldFone, setOtpFieldFone] = useState('');
	const [showOtpPhone, setShowOtpPhone] = useState(false);

	const [showInputPhone, setShowInputPhone] = useState(true);

	const [showAskForPassword, setShowAskForPassword] = useState(false);//false
	const [showInputPasswords, setShowInputPasswords] = useState(true);
	const [password, setPassword] = useState("");
	const [isPasswordError, setIsPasswordError] = useState(true);
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [isPasswordConfirmError, setIsPasswordConfirmError] = useState(true);

	// const [arePasswordTheSame, setArePasswordTheSame] = useState(false);
	const [readyToSignUp, setReadyToSignUp] = useState(false);

	const [userDataTemp, setUserDataTemp] = useState(userDataRow);

	const [showAskForThanks, setShowAskForThanks] = useState(false);

	// const [showModSetProfile, setShowModSetProfile] = useState(false);

	// const formOtpEmail = useRef();
	// const [showModSetAddress, setShowModSetAddress] = useState(false);
	// const [areEmailTheSame, setAreEmailTheSame] = useState(false);

	// const [otp, setOtp] = useState('');
	// const [otpPhone, setOtpPhone] = useState('');

	//  const { signIn } = useUserAuth();

	// const openModSetProfile = () => {
	//   setShowModSetProfile((prev) => !prev);
	//   // localStorage.setItem("profile", JSON.stringify(profile));

	//   // logData(profile);
	// }; //=====   openModSetProfile  ==================

	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	// setError('');
	// 	//console.log('submited to firebase  == EMAIL : ', email, '   Password : ', password);

	// 	/* navigate("/Dashboard");  */
	// };

	const handleInputEmail = (e) => {
		const value = e.currentTarget.value;
		//===============
		if (isEmailOk(value) === "True") {
									// email is ok
									// No error on email input
									setIsEmailError(false); //no input email error
									setEmailConfirm("");
									if (emailConfirm === value) {
										setError(""); // Error string nothing             
										EmailsOKContinue();
										setShowAskForOtp(true);

									} else {
										setError("Emails not the same ");
										// setIsEmailConfirmError(true);
										// setIsError(true);
									}
		} else {
								// email not ok
								// update Error and Error message
								setError(isEmailOk(value)); // Error string
								// setIsError(true); //  error
								// No error on email input
								setIsEmailError(true); //yes input email error
		}
		setEmail(value);
		// reset emails not the same
		// setAreEmailTheSame(false);

		// setEmail(prev => ({ ...email, emailConfirm: '' }));
	}; //======   handleInputPhone   ==========

	const handleInputEmailConfirm = (e) => {
		const value = e.currentTarget.value;
		//===============
		if (isEmailOk(value) === "True") {
			// email is ok
			if (email === value) {
				setError("");
				EmailsOKContinue();
				setShowAskForOtp(true);

			} else {
				setError("Emails not the same ");
				setIsEmailConfirmError(true);
				// setIsError(true);
				// setAreEmailTheSame(false);


				// setIsOtpError(true);
				// setIsOtpVerifiedError(false);
			}
		} else {
			setError(isEmailOk(value));
			setIsEmailConfirmError(true);

			setIsEmailConfirmError(true);
		}
		setEmailConfirm(value);
		// console.log(' trying shit :', email.emailConfirm)
	}; //======   handleInputEmailConfirm   ==========

	const EmailsOKContinue = () => {

		console.log("Email saved : ", email);
		const randomOtp = Math.floor(Math.random() * 89999 + 10000);
		e_otp_key = randomOtp.toString();
		//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
		// set back to work 
		sendEmailOtpViaEmailJs(email, e_otp_key);
		//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
		setShowInputEmails(false);
		// setAreEmailTheSame(false); get rid of this ....security risk
		console.log('E_OTP :', e_otp_key);
	}; //======   EmailsOKContinue   ==========


	function isEmailOk(email) {
		let answer = "";
		if (validate(email)) {
			// setIsError(false);
			// setError('');
			answer = "True";
			return answer; // the email is valid
		} else {
			// setIsError(true);
			// setError('Please Enter a valid Email');
			answer = "Please Enter a valid Email"; // the email is invalid
		}
	} //=========    isEmailOk ================

	function sendEmailOtpViaEmailJs(toEmail, otpSent) {
		let templateParams = {
			to_email: toEmail,
			otp_key: otpSent,
		};
		console.log("templateParams : ", templateParams);
		console.log("from whats sent to email otp_key :", otpSent);
		emailjs
			.send(
				"service_195ei3n", //YOUR_SERVICE_ID
				"template_rc8qibt", //YOUR_TEMPLATE_ID
				templateParams, //own parameters
				"31JSp7p9rt0D9nmq9" //YOUR_PUBLIC_KEY
			)
			.then(
				function (response) {
					console.log("SUCCESS!", response.status, response.text);
				},
				function (error) {
					console.log("FAILED...", error);
				}
			);
	} //===========  sendEmailOtpViaEmailJs ============

	function sendEmailWelcomeViaEmailJs(toEmail) {
		let templateParams = {
			to_email: toEmail,
		};
		emailjs
			.send(
				"service_195ei3n", //YOUR_SERVICE_ID
				"template_rc8qibt", //YOUR_TEMPLATE_ID
				templateParams, //own parameters
				"31JSp7p9rt0D9nmq9" //YOUR_PUBLIC_KEY
			)
			.then(
				function (response) {
					console.log("SUCCESS!", response.status, response.text);
				},
				function (error) {
					console.log("FAILED...", error);
				}
			);
	} //===========  sendEmailWelcomeViaEmailJs ============


	// function sendOtpToEmail(toEmail) {
	// 	console.log("@sendOtpToEmail  doing nothing");
	// 	//	setOtp(randomOtp); already set in handleSentOtpButton
	// } //=========    sendOtpToEmail  ================

	const handleOtp = (e) => {
		const value = e.currentTarget.value;
		//===============
		if (isOtpOk(value) === "True") {
			// OTP is ok locally
			// const otpLocal = localStorage.getItem("OTP_Key");
			console.log("otpField :", value, '    e_otp_key  ', e_otp_key);

			if (value === e_otp_key) {
				setShowAskForOtp(false);
				setShowAskForProfile(true);
				setShowWelcomeLogo(false);
				console.log('OTP verified Continue to Profile')

				setIsOtpError(false);
				setError("");
				console.log(" at last i am here *************");
			} else {

				setShowAskForProfile(false);
				setShowWelcomeLogo(true);
				setError("Wrong OTP your account may be frozen");
				// setIsError(true);
				setIsOtpError(false);
			}
			//===================
			// setError(''); setIsOtpError(false)
		} else {
			setError(isOtpOk(value));
			setShowAskForProfile(false);
			setShowWelcomeLogo(true);
			setIsOtpError(true);
		}
		// email;
		setOtpField(value);
	}; //======   handleOtp   ==========

	function isOtpOk(enteredOtpNumber) {
		let answer = "";
		let regPin = /^[1-9]\d{4}$/;

		if (enteredOtpNumber === "") {
			//=======Empty name
			answer = "Field should not be Empty";
			return answer;
		} else {
			if (!regPin.test(enteredOtpNumber)) {
				//====invalid characters
				answer = "5 Digits only";
				return answer;
			} else {
				//====Valid nanme
				setError("");
				answer = "True";
				return answer;
			}
		}
	} //=========    isOtpOk ================
	
	const handleInputMrOrMS = e => {
		const value = e.currentTarget.value;
		if (value === "") {
			setError("Please Select Gender");
			// setIsError(true);
			setShowfName(false);
			setShowlName(false);
		} else {
			setError("");
			// setIsError(false);
			setShowfName(true);
			setShowlName(false);
		}
		setProfile(prev => ({ ...profile, mrorms: value, fName: "", lName: "" }));
	};//======   handleInputMrOrMS   =============

	const handleInputName = e => {
		const inputName = e.currentTarget.name;
		const value = e.currentTarget.value;
		if (inputName === 'fName') {
			//  Name is char only
			if (isNameOk(value) === 'True') {
				// setFname(value);
				setError('');
				setIsfNameError(false);
				setShowlName(true);
			} else {
				setError(isNameOk(value));
				setIsfNameError(true)
				// setShowfName(false);
				setShowlName(false);
				setProfile({ ...profile, mrOrMs: "" });
			}
			setProfile({ ...profile, fName: value });
			// localStorage.setItem("profile", JSON.stringify(profile));
		}
		else {
			//  Name is char only
			if (isNameOk(value) === 'True') {
				// setFname(value);
				setError('');
				setIslNameError(false);
			} else {
				setError(isNameOk(value));
				setIslNameError(true)
			}
			setProfile({ ...profile, lName: value });
		}
		// localStorage.setItem("profile", JSON.stringify(profile));
	};// handleInputName


	const handleInputLName = e => {
		const inputName = e.currentTarget.name;
		const value = e.currentTarget.value;
		if (inputName === 'fName') {
			//  Name is char only
			if (isNameOk(value) === 'True') {
				// setFname(value);
				setError('');
				setIsfNameError(false);
				setShowlName(true);
			} else {
				setError(isNameOk(value));
				setIsfNameError(true)
				// setShowfName(false);
				setShowlName(false);
				setProfile({ ...profile, mrOrMs: "" });
			}
			setProfile({ ...profile, fName: value });
			// localStorage.setItem("profile", JSON.stringify(profile));
		}
		else {
			//  Name is char only
			if (isNameOk(value) === 'True') {
				// setFname(value);
				setError('');
				setIslNameError(false);
			} else {
				setError(isNameOk(value));
				setIslNameError(true)
			}
			setProfile({ ...profile, lName: value });
		}
		// localStorage.setItem("profile", JSON.stringify(profile));
	};// handleInputLName

	function isNameOk(name) {
		let answer = '';
		let regName = /^[a-zA-Z\-\s]+$/;
		if (name === '') {
			//=======Empty name 
			answer = 'Field should not be Empty';
			return answer;
		} else {
			//====NOt empty check for valid characters
			if (!regName.test(name)) {
				//====invalid characters 
				answer = 'Only letters and - ';
				return answer;
			} else {
				//====Valid nanme
				answer = 'True';
				return answer;
			}
		}
	}//=========    isNameOK ================

	const showAskForAddressContinue = () => {
		// setShowModSetAddress(prev => !prev);
		// localStorage.setItem("profile", JSON.stringify(profile));
		setShowAskForProfile(false);
		setShowAskForAddress(true);
		// logData(profile);

	};//=====   showAskForAddressContinue  ==================

	function getListOfWillayas() {

		const willayas = ListOfCities.map((item) => {
			return item.wilaya_name_ascii
		})
		const willayasClean = [...new Set(willayas)];
		const listOfWillayas = [...willayasClean];

		return listOfWillayas

	};//=========    getListOfWillayas    ================

	function getListOfDairas(willaya) {

		let dairas = [];
		for (let i = 0; i < ListOfCities.length; i++) {
			if (ListOfCities[i].wilaya_name_ascii === willaya) {
				dairas.push(ListOfCities[i].daira_name_ascii)
			}
		}
		const listOfDairas = [...new Set(dairas)];
		return listOfDairas

	};//=========    getListOfDairas    ================


	function getListOfBaladias(willaya, daira) {

		// console.log('getListOfDairas from getListOfBaladias function :', getListOfDairas(willaya));

		let listOfBaladias = [];
		for (let i = 0; i < ListOfCities.length; i++) {
			if ((ListOfCities[i].wilaya_name_ascii === willaya) && (ListOfCities[i].daira_name_ascii === daira)) {
				listOfBaladias.push(ListOfCities[i].commune_name_ascii)
			}
		}
		// console.log('dairas from getListOfDairas function :', dairas);
		// const listOfDairas = [...new Set(dairas)];
		// console.log('listOfDairas from getListOfDairas function :', listOfDairas);
		//==========
		// console.log('getList Of baladias  from getListOfBaladias function :', listOfBaladias);
		return listOfBaladias

	}//=========    getListOfBaladias    ================

	const handleInputWillaya = e => {
		const value = e.currentTarget.value;
		setAddress(prev => ({ ...address, willaya: value, daira: '', baladiya: '' }));
		setShowDaira(true);
		getListOfDairas(value);
	};//======   handleInputWillaya   =============

	const handleInputDaira = ev => {
		const value = ev.currentTarget.value;
		// setAddress({ daira: value });
		setAddress(prev => ({ ...address, daira: value, baladiya: '' }));
		setShowBaladia(true);
		getListOfBaladias(address.willaya, value);
	};//======   handleInputDaira   =============

	const handleInputBaladia = ev => {
		const value = ev.currentTarget.value;
		// setAddress({ baladiya: value });
		setAddress(prev => ({ ...address, baladiya: value }));
		setShowContinue(true);
	};//======   handleInputBaladia   =============


	const showAskForSecurityContinue = () => {
		// setShowModSetAddress(prev => !prev);
		// localStorage.setItem("address", JSON.stringify(address));
		setShowAskForAddress(false);
		setShowAskForSecurity(true);
		// logData(profile);

	};//=====   showAskForSecurityContinue  ==================

	function isQuestionOk(question) {
		let answer = '';
		// let regName = /^[a-zA-Z\-\s]+$/;
		if (question === '') {
			//=======Empty name 
			answer = 'Field should not be Empty';
			return answer;
		} else {
			//====NOt empty check for valid characters
			// if (!regName.test(question)) {
			//====invalid characters 
			// answer = 'Only letters and - ';
			// return answer;
			// } else {
			//====Valid nanme
			answer = 'True';
			return answer;
			// }
		}
	}//=========    isQuestionOk ================

	function isAnswerOk(question) {
		let answer = '';
		if (question === '') {
			//=======Empty name 
			answer = 'Field should not be Empty';
			return answer;
		} else {
			answer = 'True';
			return answer;
			// }
		}
	}//=========    isNameOK ================


	function isPinOk(pin) {
		let answer = '';
		let regPin = /^[0-9]+$/;

		if (pin === '') {                //=======Empty name 
			answer = 'Field should not be Empty';
			return answer;
		} else {
			if (!regPin.test(pin)) {            //====invalid characters 
				answer = 'Digits Only';
				return answer;
			} else {
				if (pin.length < 4) {       //====Valid nanme
					answer = '5 digits only';
					return answer;
				} else {
					if (pin.length === 5) {       //====Valid nanme
						answer = 'True';
						return answer;
					} else {
						//====Valid nanme
						answer = '5 digits only';
						return answer;
					}
				}
			}
		}
	}//=========    isPinOk ================

	const handleInputPinNumber = e => {
		const value = e.currentTarget.value;
		//  pin validation 
		if (isPinOk(value) === 'True') {
			// pin is ok 
			setError(''); setIsPinError(false)
		} else {
			setError(isPinOk(value)); setIsPinError(true)
		}
		// setPin;
		setSecurity(prev => ({ ...security, pinNumber: value }));
	};//======   handleInputPinNumber   =============


	const handleInputQuestion = ev => {
		const value = ev.currentTarget.value;
		//  pin validation 
		if (isQuestionOk(value) === 'True') {
			// pin is ok 
			setError(''); setIsQuestionError(false)
		} else {
			setError(isQuestionOk(value)); setIsQuestionError(true)
		}
		// setPin;
		setSecurity(prev => ({ ...security, question: value }));


	};//======   handleInputQuestion   =============
	const handleInputAnswer = ev => {
		const value = ev.currentTarget.value;
		//  pin validation 
		if (isAnswerOk(value) === 'True') {
			// pin is ok 
			setError(''); setIsAnswerError(false)
		} else {
			setError(isAnswerOk(value)); setIsAnswerError(true)
		}
		// setPin;
		setSecurity(prev => ({ ...security, answer: value }));
	};//======   handleInputAnswer   =============


	const showAskForPhoneContinue = () => {
		// setShowModSetAddress(prev => !prev);
		// localStorage.setItem("address", JSON.stringify(address));
		setShowAskForSecurity(false);
		setShowAskForPhone(true);
		// logData(profile);

	};//=====   showAskForPhoneContinue  ==================

	function isPhoneOk(enteredPhoneNumber) {
		let answer = '';
		let regPin = /^[0]\d{9}$/;

		if (enteredPhoneNumber === '') {                //=======Empty name 
			answer = 'Field should not be Empty';
			return answer;
		} else {

			if (!regPin.test(enteredPhoneNumber)) {            //====invalid characters 
				answer = 'Phone Numbers Only';
				return answer;
			} else {
				//====Valid nanme
				answer = 'True';
				return answer;
			}
		}
	}//=========    isPhoneOk ================

	const handleInputPhone = e => {
		const value = e.currentTarget.value;
		//===============
		if (isPhoneOk(value) === 'True') {
			// Phone is ok 
			setError('');
			//setIsError(false); // NO error
			setIsPhoneError(false);

		} else {
			setError(isPhoneOk(value)); setIsPhoneError(true);
			// setIsError(true); //  error 
		}
		setPhone(value);
		setIsOtpError(true);
		setShowOtpPhone(false);
		// setIsOtpVerifiedError(false); 
	};//======   handleInputPhone   ==========

	const PhoneOKContinue = () => {
		
		console.log("Phone saved : ", phone);
		// localStorage.setItem("phone", phone);

		const randomOtp = Math.floor(Math.random() * 89999 + 10000);
		f_otp_key = randomOtp.toString();
		console.log('P_OTP :', f_otp_key);
		// setOtpPhone(otp_key);
		setShowInputPhone(false);
		setShowOtpPhone(true);
		// handleSendOtpButton();
		sendEmailOtpViaEmailJs(email, f_otp_key);
	}; //======   PhoneOKContinue   ==========

		const handleOtpFone = e => {
		const value = e.currentTarget.value;
		//===============
		if (isOtpOk(value) === 'True') {
			// Phone is ok locally 
			//=========check answer back
			console.log("Entered OTP_field", value);
			console.log("********* I am here            OTP_OK ********* ");
			if (f_otp_key === value) {
				console.log("Both OTP  are the same ", f_otp_key,'  ',value);

				setIsOtpVerified(true);
				setError('');
				//setIsError(false);
				setIsOtpError(false);
				console.log("********* I am here             === ********* ");
				setShowOtpPhone(false);
			} else {
				// setIsOtpVerifiedError(false);
				setError('Wrong OTP your account may be frozen');
				// setIsError(true);
				setIsOtpError(true);
				console.log("********* I am here           !  === ********* ");
			}      //===================
			// setError(''); setIsOtpError(false)
		} else {
			setError(isOtpOk(value)); setIsOtpError(true)
			// setIsOtpVerifiedError(false);
			console.log("********* I am here         OTP_not OK  ********* ");
		}
		// Phone;
		setOtpFieldFone(value);

	};//======   handleOtpPhone   ==========

	// const handleSendOtpButton = e => {
	//   console.log("********* Starting the handleSendOtpButton  Send OTP ********* ");

	//   // setShowOtp(false);
	//   console.log("reading email from storage");
	//   const savedEmail = localStorage.getItem("email");
	//   console.log("Email read ", savedEmail);
	//   console.log("Phone : Sending OTP to Email : ", savedEmail);

	//   //===========  sendEmail ============
	//   //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
	//   sendEmailOtpViaEmailJs(savedEmail, otpPhone);
	//   //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
	//   //===========  sendEmail ============ 

	// };//======   handleSendOtpButton   ==========


	function isPasswordOk(psw) {
		let answer = '';
		//  Password input change  8 char long
		if ((psw.trim() === '') || (psw.trim().length < 8)) {
			answer = 'Password at least 8 char';
		} else {
			answer = 'True';
		}
		return answer;
	}//=========    isPasswordOk ================


	const showAskForPasswordContinue = () => {
		// setShowModSetPassword((prev) => !prev);
		// localStorage.setItem("profile", JSON.stringify(profile));
		// logData(profile);
		setShowAskForPhone(false);
		setIsOtpVerified(false);
		setShowAskForPassword(true);
	}; //=====   showAskForPasswordContinue  ==================

	const handleInputPassword = e => {
		const value = e.currentTarget.value;
		//===============
		if (isPasswordOk(value) === 'True') {
			// psd  is ok 
			setError(''); setIsPasswordError(false);
			// setIsError(false);
		} else {
			setError(isPasswordOk(value)); setIsPasswordError(true);
			// setIsError(true);
		}
		setPassword(value);
		setIsPasswordConfirmError(true);
	};//======   handleInputPhone   ==========



	const handleInputPasswordConfirm = e => {
		const value = e.currentTarget.value;
		//===============
		if (isPasswordOk(value) === 'True') {
			// pswd is ok do they match 
			if (password === value) {
				setError('');
				setIsPasswordConfirmError(false);
				// setArePasswordTheSame(true);
				// setIsError(false);
				PasswordsOKContinue();

			} else {
				// setIsError(true);
				setError("Password not the same");
				setIsPasswordConfirmError(true);
				// setArePasswordTheSame(false);

			}

		} else {
			// setIsError(true);
			setError(isPasswordOk(value));
			setIsPasswordConfirmError(true);
			// setArePasswordTheSame(false);
		}
		setPasswordConfirm(value);
	};//======   handleInputPasswordConfirm   ==========


	const PasswordsOKContinue = () => {
		setShowInputPasswords(false);
		// setArePasswordTheSame(false);
		setReadyToSignUp(true);
		// const profile = JSON.parse(localStorage.getItem('profile'));
		setUserDataTemp(prev => ({ ...prev, mrorms: profile.mrorms }));
		setUserDataTemp(prev => ({ ...prev, fname: profile.fName }));
		setUserDataTemp(prev => ({ ...prev, lname: profile.lName }));

		// const address = JSON.parse(localStorage.getItem('address'));
		setUserDataTemp(prev => ({ ...prev, addressLine: address.addressLine }));
		setUserDataTemp(prev => ({ ...prev, baladiya: address.baladiya }));
		setUserDataTemp(prev => ({ ...prev, daira: address.daira }));
		setUserDataTemp(prev => ({ ...prev, willaya: address.willaya }));

		// const email = localStorage.getItem('email');
		setUserDataTemp(prev => ({ ...prev, email: email }));

		// const security = JSON.parse(localStorage.getItem('security'));
		setUserDataTemp(prev => ({ ...prev, answer: security.answer }));
		setUserDataTemp(prev => ({ ...prev, pinnumber: security.pinNumber }));
		setUserDataTemp(prev => ({ ...prev, question: security.question }));


		// const phone = localStorage.getItem('phone');
		setUserDataTemp(prev => ({ ...prev, phone: phone }));


	}; //======   PasswordsOKContinue   ==========


	function createAccountWithData(withEmail, withPassword) {

		let time = new Date().toString().slice(0, 25);
		// setUserDataTemp(prev => ({ ...prev, transactions[0].createdAt: time }));
		console.log("email needed jamal", withEmail, "    time :", time);
		let withData = JSON.parse(JSON.stringify(userDataTemp));
		withData.transactions[0].createdAt = time;
		withData.transactions[0].from = 'paydoroz@gmail.com';
		withData.transactions[0].to = withEmail;
		signUp(withEmail, withPassword)
			.then(
				async (userCredential) => {
					await setDoc(doc(dbb, "users", userCredential.user.uid), withData);
					localStorage.setItem("user", JSON.stringify(userCredential.user));

				})
			.catch((error) => {
				setError(error.message);
				console.log(error.message);
			});

		return Error

	};// ********  createAccountAndSAve  ********


	const createAccount = e => {

		setError(createAccountWithData(userDataTemp.email, password));
		// setIsError(true);
		setReadyToSignUp(false);
		setShowAskForPassword(false);
		sendEmailWelcomeViaEmailJs(userDataTemp.email);
		setShowAskForThanks(true);



	};//======   createAccount   ==========

	// const showAskForThanksContinue = () => {
	//   // setShowModSetAddress(prev => !prev);
	//   localStorage.setItem("address", JSON.stringify(address));
	//   setShowAskForPassword(false);
	//   setShowAskForThanks(true);
	//   // logData(profile);

	// };//=====   showAskForThanksContinue  ==================


	return (
		<Container2>
			<InfoRow> {/*   =================left Column=============== */}
				<InfoColumnLeft>

					{/*   =============================================Start =============== */}
					{/*   =============================================Start =============== */}



					{true &&
						<>
							<IconWrapper2>
								<svg
									width="64"
									height="64"
									viewBox="0 0 64 64"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM32 59C46.9117 59 59 46.9117 59 32C59 17.0883 46.9117 5 32 5C17.0883 5 5 17.0883 5 32C5 46.9117 17.0883 59 32 59Z"
										fill="#ffffff"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M15.5754 40.0964C17.5918 32.9393 21.6247 29.1502 21.6247 29.1502L25.3214 25.3611C25.3214 25.3611 23.473 24.0981 23.137 22.2737C22.8009 20.4493 23.137 19.1863 23.137 19.1863C23.137 19.1863 23.809 15.3972 25.4894 13.5728C27.1697 11.7484 30.8664 11.0468 30.8664 11.0468C30.8664 11.0468 35.0673 10.4854 36.9156 10.7661C38.764 11.0468 40.1924 11.4678 40.6124 12.1695C41.0323 12.8711 41.1164 18.7653 34.7312 23.5367C28.3459 28.3082 21.6246 33.5006 19.6082 36.4477C17.5918 39.3947 18.0959 41.6401 19.6082 43.1838C21.1205 44.7275 28.0099 46.4116 32.0426 46.9729C36.0754 47.5343 50.5263 44.8679 50.5263 44.8679C50.5263 44.8679 46.9976 50.9023 32.8828 53.0074C18.768 55.1124 13.559 47.2536 15.5754 40.0964ZM33.0441 35.9846C33.3254 35.7084 30.5355 34.4653 30.5355 34.4653L31.0747 33.4196L31.9656 32.0187C31.9656 32.0187 34.4976 33.0249 34.9196 34.1299C35.3416 35.2348 33.6536 37.8717 32.3876 38.5497C31.3034 39.1304 28.5168 39.4545 27.6064 39.5604L27.6062 39.5604C27.4537 39.5782 27.3539 39.5898 27.3236 39.5955C27.1126 39.6349 27.4643 38.8062 27.4643 38.8062L28.0973 36.9515L29.6446 36.7936L31.2623 36.5371C31.2623 36.5371 32.7627 36.2609 33.0441 35.9846ZM29.8563 22.3077C29.8563 22.3077 26.4956 20.0623 26.9997 17.9573C27.5038 15.8522 38.2579 16.6943 36.0734 18.5186C33.889 20.343 29.8563 22.3077 29.8563 22.3077Z"
										fill="#ffffff"
									/>
								</svg>
							</IconWrapper2>
							{/*        <NavLinkPw to='/Signup'> New To Paydoroz ? Sign Up  </NavLinkPw>
								 */}
							{/* ********************************************************             */}
							{/* <EmailWrapper>Please enter your Email</EmailWrapper> */}
						</>
					}
					<InfoLeftWrapper color={`#FFFFFF`} colortwo={`#FFFF00`}>

						{showInputEmails &&
							
							<>
							<strong >Important </strong><br />
							Please Please make sure your email is valid and you do have access to it. without it
							<br /> <br />
							<strong >YOU WILL NOT </strong> be able toopen an account
							<br /> <br />
							Please make sure you Re-Enter the same Email address again
						
						</>
						}
						
					{showAskForOtp && (
						<>
								<strong >OTP Verifiaction</strong><br />
								In Order To verify that your email exist and that you have access to it, we have sent an
								OTP :<br /><strong >One Time Password</strong>  <br />to your Email. its a 5 Digits number. please Enter it in the coresponding field
				

						</>
						)}
						



{showAskForProfile && (
						<>
							{/* <PWrapper color={`#FE0B0B`}><strong>Ghathering profile data</strong><br /> */}

								<strong >Profile</strong><br />
								Thank you , your Email has been verified. We need to gather some personal information required in order to comply with local regulation
								<br />
								{/* <strong >One Time Password</strong>  <br /> */}
								Please select <br /><br />Title,<br /><br />Then enter your<br /><br /> First Name <br /><br />Last Name <br />
		

						</>
						)}
						
						{showAskForAddress &&<>
							<strong >Address</strong><br />
							Please Enter the first line of your address, then select the Willaya , Daira and Baladiya where you live
							<br />
							<br /><br /><br /><br /><br /><br /> <br /><br /> <br />
				
</>
						}  {/* showAskForAddress */}
						
	{showAskForSecurity &&
						<>					
								<strong >Security Settings</strong><br />
							
								<strong >Pin Number</strong>
								Please Enter 5 Digits Pin Number of your choice. <br /><br />
								<strong >Question</strong>
								Please Enter a Question of your choice<br /><br />
								<strong >Answer</strong>
								Please Enter Your own answer to the above question<br /><br />
								<br /><br />
								Please remember all the above <br />YOU WILL NEED THEM<br /> when contacting our call center and help IDENTIFY YOU
		
						</>
						}{/*showAskForSecurity*/}
						

					{showAskForPhone &&
						<>
							<strong >Important</strong><br /> 						
								Please Please make sure your have a mobile number, without it.<br /><br />
								<strong >YOU WILL NOT </strong> be able toopen an account
								<br /> <br />
								The number must be a 10 digit and begins with '0'<br /> <br />
								An OTP will be sent to your Email to validate your phone number.<br /> <br />
								Please Enter it in the coresponding field
				
						</>
						}{/*showAskForPhone*/}
						
							{showAskForPassword &&
						<>							
								<strong >Important </strong><br />
								Please chose a password thats strong enough and					
							<br />	<br />
							<strong>KEEP IT SAFE</strong><br />
								
								Passwords are 8 characters minimum, Re-Enter the Password to confirm it.
	
						</>
						}{/* showAskForPassword */}
						
						{showAskForThanks &&
						<>
							<strong >Thank You</strong><br />
								You are now a member of the the  <br />
		<strong >PAYDOROZ</strong><br /> 

						
								Family.
						

						</>}{/* showAskForThanks */}
    </InfoLeftWrapper>

					

				




					

					{/*   =============================================END =============== */}
					{/*   =============================================END =============== */}


					{/*  <SidebarD />  */}
				</InfoColumnLeft>

				<InfoColumnRight>{/*   =================Right column=============== */}
					<RightColTopNavWrapper>
						<NavLeftWrapper>
							<NavLogoWrapper>
								<img src={logoSVG_red} alt="logo" />
							</NavLogoWrapper>
							{email && <NavBalanceWrapper>{email}</NavBalanceWrapper>}
						</NavLeftWrapper>
					</RightColTopNavWrapper>

					<StyledRightWrapper>
						<StyledRightContainer>
							{showWelcomeLogo &&
								<>
									<IconWrapper2>
										<svg
											width="64"
											height="64"
											viewBox="0 0 64 64"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM32 59C46.9117 59 59 46.9117 59 32C59 17.0883 46.9117 5 32 5C17.0883 5 5 17.0883 5 32C5 46.9117 17.0883 59 32 59Z"
												fill="#063"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M15.5754 40.0964C17.5918 32.9393 21.6247 29.1502 21.6247 29.1502L25.3214 25.3611C25.3214 25.3611 23.473 24.0981 23.137 22.2737C22.8009 20.4493 23.137 19.1863 23.137 19.1863C23.137 19.1863 23.809 15.3972 25.4894 13.5728C27.1697 11.7484 30.8664 11.0468 30.8664 11.0468C30.8664 11.0468 35.0673 10.4854 36.9156 10.7661C38.764 11.0468 40.1924 11.4678 40.6124 12.1695C41.0323 12.8711 41.1164 18.7653 34.7312 23.5367C28.3459 28.3082 21.6246 33.5006 19.6082 36.4477C17.5918 39.3947 18.0959 41.6401 19.6082 43.1838C21.1205 44.7275 28.0099 46.4116 32.0426 46.9729C36.0754 47.5343 50.5263 44.8679 50.5263 44.8679C50.5263 44.8679 46.9976 50.9023 32.8828 53.0074C18.768 55.1124 13.559 47.2536 15.5754 40.0964ZM33.0441 35.9846C33.3254 35.7084 30.5355 34.4653 30.5355 34.4653L31.0747 33.4196L31.9656 32.0187C31.9656 32.0187 34.4976 33.0249 34.9196 34.1299C35.3416 35.2348 33.6536 37.8717 32.3876 38.5497C31.3034 39.1304 28.5168 39.4545 27.6064 39.5604L27.6062 39.5604C27.4537 39.5782 27.3539 39.5898 27.3236 39.5955C27.1126 39.6349 27.4643 38.8062 27.4643 38.8062L28.0973 36.9515L29.6446 36.7936L31.2623 36.5371C31.2623 36.5371 32.7627 36.2609 33.0441 35.9846ZM29.8563 22.3077C29.8563 22.3077 26.4956 20.0623 26.9997 17.9573C27.5038 15.8522 38.2579 16.6943 36.0734 18.5186C33.889 20.343 29.8563 22.3077 29.8563 22.3077Z"
												fill="#063"
											/>
										</svg>
								</IconWrapper2>
								
									{/*        <NavLinkPw to='/Signup'> New To Paydoroz ? Sign Up  </NavLinkPw>
								 */}
									{/* ********************************************************             */}
									{/* <EmailWrapper>Please enter your Email</EmailWrapper> */}
								</>
							}

							{/* {false && ( */}
							{showInputEmails && (
								<>
									<Welcome>Welcome </Welcome>
									<LabelWrapper>Please Enter Your Email Address</LabelWrapper>
									<InputWrapper>
										<StyledInput autoFocus type="email" name="email"
											value={email} onChange={handleInputEmail}
										/>
										{isEmailError ? (<Img src={require("../../Assets/cancel1.jpg")} alt={"cancel"} />
										) : (<Img src={require("../../Assets/check.png")} alt={"cancel"} />)}
									</InputWrapper>

									{!isEmailError && (<>
										<LabelWrapper>Please ReEnter Your Email Address</LabelWrapper>
										<InputWrapper>
											<StyledInput type="email" name="emailConfirm"
												value={emailConfirm} onChange={handleInputEmailConfirm} />
											{isEmailConfirmError ? (<Img src={require("../../Assets/cancel1.jpg")} alt={"cancel"} />
											) : (<Img src={require("../../Assets/check.png")} alt={"cancel"} />)}
										</InputWrapper>   </>)} </>
							)}


							{showAskForOtp && (
								<>
									<RDialogueWrapper color={`#000000`} colortwo={`#006633`}><strong >need to verify your Email </strong><br />
										Please check your Email Please Enter the 5 Digits OTP you received
										in the field bellow</RDialogueWrapper>
									<InputWrapper>
										<StyledInput
											// autoFocus
											type="text"
											name="otpfield"
											value={otpField}
											onChange={handleOtp}
										/>
										{isOtpError ? (
											<Img src={require("../../Assets/cancel1.jpg")} alt={"cancel"} />
										) : (
											<Img src={require("../../Assets/check.png")} alt={"cancel"} />
										)}
									</InputWrapper>

								</>
							)}

							{/* {false && ( */}
							{showAskForProfile && (
								<>
									{/* <PWrapper color={`#FE0B0B`}><strong>Ghathering profile data</strong><br /> */}

									<RDialogueWrapper color={`#000000`} colortwo={`#006633`}>Your Email <strong >{email}</strong>has been verified.

										<strong >Gathering profile data</strong><br />
									</RDialogueWrapper>
									<LabelWrapper>Please Select Gender</LabelWrapper>
									<SelectWrapper>
										<SelectMr
											id="mrOrMs"
											name="mrOrMs"
											value={profile.mrorms}
											onChange={handleInputMrOrMS}>
											<option value={profile.mrorms} hidden>{profile.mrorms}</option>
											<option value="Mr">Mr</option>
											<option value="Ms">Ms</option>
										</SelectMr>
									</SelectWrapper>
									{showfName &&
										<>

											<LabelWrapper>Please Enter your First Name</LabelWrapper>
											<InputWrapper>
												<StyledInput
													type="text"
													name="fName"
													value={profile.fName}
													onChange={handleInputName} />
												{/* {true ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                    (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
                  } */}
											</InputWrapper>
										</>
									}
									{showlName &&
										<>
											<LabelWrapper>Please Enter your Last Name</LabelWrapper>
											<InputWrapper>
												<StyledInput
													type="text"
													name="lName"
													value={profile.lName}
													onChange={handleInputLName} />
												{/* {true ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                    (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
                  } */}
											</InputWrapper>
										</>

									}
									{!(isfNameError || islNameError) ? (
										<ButtonWrapper>
											<StyledButton onClick={showAskForAddressContinue}>Continue</StyledButton>
										</ButtonWrapper>
									) : null
									}
								</>
							)}

							{/* {false && */}
							{showAskForAddress &&
								<>
								<RDialogueWrapper color={`#000000`} colortwo={`#FE0B0B`} >
										<strong >Ghathering Address</strong><br />
								</RDialogueWrapper>
									<LabelWrapper>Please Enter your address</LabelWrapper>
									<InputWrapper>
										<StyledInput
											type="text"
											name="address"
											value={address.addressLine}
											onChange={e => setAddress({ addressLine: e.target.value })} />
										{/* {true ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                      (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
                    } */}
									</InputWrapper>

									<LabelWrapper>Please Select Willaya</LabelWrapper>
									<SelectWrapper>
										<SelectMr
											id="willaya"
											name="willaya"
											value={address.willaya}
											onChange={handleInputWillaya}>
											<option value={address.willaya} hidden>{address.willaya}</option>
											{
												getListOfWillayas().map(item => {
													return (
														<option value={item} key={item}>{item}</option>
													)
												}
												)
											}

										</SelectMr>
									</SelectWrapper>


									{showDaira && <>
										<LabelWrapper>Please Select Daira</LabelWrapper>
										<SelectWrapper>
											<SelectMr
												id="daira"
												name="daira"
												value={address.daira}
												onChange={handleInputDaira}>
												<option value={address.daira} hidden>{address.daira}</option>
												{
													getListOfDairas(address.willaya).map(item => {
														return (
															<option value={item} key={item}>{item}</option>
														)
													}
													)
												}

											</SelectMr>
										</SelectWrapper>
									</>}

									{showBaladia && <>
										<LabelWrapper>Please Select Baladiya</LabelWrapper>
										<SelectWrapper>
											<SelectMr
												id="baladia"
												name="baladia"
												value={address.baladiya}
												onChange={handleInputBaladia}>
												<option value={address.baladiya} hidden>{address.baladiya}</option>
												{
													getListOfBaladias(address.willaya, address.daira).map(item => {
														return (
															<option value={item} key={item}>{item}</option>
														)
													}
													)
												}
											</SelectMr>
										</SelectWrapper>
									</>
									}

									{showContinue &&
										<ButtonWrapper>
											<StyledButton onClick={showAskForSecurityContinue}>Continue</StyledButton>
										</ButtonWrapper>
									}
								</>
							}  {/* showAskForAddress */}


							{/* {false &&  */}
							{showAskForSecurity &&
								<>
								<RDialogueWrapper color={`#000000`} colortwo={`#FE0B0B`}>
									<strong >Security Settings</strong><br />
								</RDialogueWrapper>
									<LabelWrapper>Please Enter 5 Digits Pin Number</LabelWrapper>
									<InputWrapper>
										<StyledInput
											type="text"
											name="pinNumber"
											value={security.pinNumber}
											onChange={handleInputPinNumber}
										/>
										{isPinError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
											(<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
										}
									</InputWrapper>

									<LabelWrapper>Please Enter Your question</LabelWrapper>
									<InputWrapper>
										<StyledInput
											type="text"
											name="pinNumber"
											value={security.question}
											onChange={handleInputQuestion}
										/>
										{isQuestionError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
											(<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
										}
									</InputWrapper>


									<LabelWrapper>Please Enter Your Answer</LabelWrapper>
									<InputWrapper>
										<StyledInput
											type="text"
											name="pinNumber"
											value={security.answer}
											onChange={handleInputAnswer}
										/>
										{isAnswerError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
											(<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
										}
									</InputWrapper>
									{!(isPinError || isQuestionError || isAnswerError) ? (
										<ButtonWrapper>
											<StyledButton onClick={showAskForPhoneContinue}>Continue</StyledButton>
											{/* <StyledButton type='submit' onClick={openModSetAddress} >Continue</StyledButton> */}
										</ButtonWrapper>
									) : null
									}
								</>
							}{/*showAskForSecurity*/}


							{/* {false &&  */}
							{showAskForPhone &&
								<>
									{showInputPhone && (
										<>
										<RDialogueWrapper color={`#000000`} colortwo={`#FE0B0B`}>
												<strong >Phone Settings</strong><br />
										</RDialogueWrapper>
											{/* <Welcome>Welcome </Welcome>     */}
											<LabelWrapper>Please Enter Your Phone Number</LabelWrapper>
											<InputWrapper>
												<StyledInput type="phone" name="phone" value={phone}
													onChange={handleInputPhone} />

												{isPhoneError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
													(<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
												}
											</InputWrapper>
											{!isPhoneError &&
												<>
													<ButtonWrapper>
														<StyledButton onClick={PhoneOKContinue} >Confirm n Continue</StyledButton>
													</ButtonWrapper>
												</>
											}
										</>
									)}
									{showOtpPhone &&
										<>
									<RDialogueWrapper color={`#000000`} colortwo={`#FE0B0B`}>
										<strong >We need to verify your Number </strong><br />
												Please check your Email Please Enter the 5 Digits OTP you received
										in the field bellow
									</RDialogueWrapper>
											<LabelWrapper>Please Enter 5 Digits Pin Number</LabelWrapper>
											<InputWrapper>
												<StyledInput type="phone" name="otpFieldFone" value={otpFieldFone}
													onChange={handleOtpFone} />
												{(isOtpError) ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
													(<Img src={require('../../Assets/check.png')} alt={'cancel'} />)}
											</InputWrapper>

										</>
									}
									{isOtpVerified && <>
									<RDialogueWrapper color={`#000000`} colortwo={`#FE0B0B`}>Your Phone <strong >{phone}</strong><br />
											Has been verified, please continue to set your password
									</RDialogueWrapper>

										<ButtonWrapper>
											<StyledButton onClick={showAskForPasswordContinue} >Continue to Password</StyledButton>
										</ButtonWrapper>
									</>
									}

								</>
							}{/*showAskForPhone*/}

							{showAskForPassword &&
								<>
									{showInputPasswords &&
										<>
									<RDialogueWrapper color={`#000000`} colortwo={`#FE0B0B`}>
												<strong >Password Settings</strong><br />
									</RDialogueWrapper>

											<LabelWrapper>Please Enter Your Password min=8 char</LabelWrapper>
											<InputWrapper>
												<StyledInput autoFocus
													type="password"
													name="password"
													value={password}
													onChange={handleInputPassword} />
												{isPasswordError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
													(<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
												}
											</InputWrapper>
											{!isPasswordError && (
												<>
													<LabelWrapper>Please ReEnter Your Password</LabelWrapper>
													<InputWrapper>
														<StyledInput
															type="password"
															name="passwordConfirm"
															value={passwordConfirm}
															onChange={handleInputPasswordConfirm} />
														{isPasswordConfirmError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
															(<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
														}
													</InputWrapper>
												</>
											)}
										</>
									}
									{/*                   
                  
                        {arePasswordTheSame && (
                          <>
                            <ButtonWrapper>
                              <StyledButton onClick={PasswordsOKContinue}>
                                Passwords OK Continue
                              </StyledButton>
                            </ButtonWrapper>
                          </>
                        )}
                       */}

									{readyToSignUp &&
										<>
									<RDialogueWrapperPreview color={`#000000`} colortwo={`#FE0B0B`}>
												<strong >Preview</strong><br />
												An account will be created for you with these details.<br /><br />
												{userDataTemp.mrorms} {userDataTemp.fname} {userDataTemp.lname} <br />
												{''}<br />
												Your Email &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;{userDataTemp.email}<br />
												Your Phone &nbsp; &nbsp; &nbsp;&nbsp;:&nbsp;{userDataTemp.phone}<br />
												Your Address &nbsp; &nbsp;:&nbsp;{userDataTemp.addressLine}<br />
												----------------- &nbsp; &nbsp;:&nbsp;{userDataTemp.baladiya}, {userDataTemp.daira}, {userDataTemp.willaya}<br />
												Your Secret Pin :&nbsp;{userDataTemp.pinnumber}<br />
												Q?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;{userDataTemp.question}<br />
												A to?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;{userDataTemp.answer}<br />
									</RDialogueWrapperPreview>
											{/* 
                      <LabelWrapper>{userDataTemp.mrorms} {userDataTemp.fname} {userDataTemp.lname} </LabelWrapper>
                      <LabelWrapper>Your Email : {userDataTemp.email}</LabelWrapper>
                      <LabelWrapper>Your Phone : {userDataTemp.phone}</LabelWrapper>
                      <LabelWrapper>Your Address : {userDataTemp.addressLine}</LabelWrapper>
                      <LabelWrapper>    {userDataTemp.baladiya}, {userDataTemp.daira}, {userDataTemp.willaya}</LabelWrapper>


                      <LabelWrapper>Your Secret Pin : {userDataTemp.pinnumber}</LabelWrapper>
                      <LabelWrapper>Q? {userDataTemp.question}</LabelWrapper>
                      <LabelWrapper>A to ? {userDataTemp.answer}</LabelWrapper> 
                      */}

											<ButtonWrapper>
												<StyledButton onClick={createAccount}>
													Creat Account
												</StyledButton>
											</ButtonWrapper>

										</>
									}


								</>
							}{/* showAskForPassword */}



							{/* logic probleme here  ,...							
							nNEED TOWAIT FOR CONFIRMATION FROM FIREBASE BEFORE THANKS
							showAskForPassword */}
							{showAskForThanks &&
								<>
								<RDialogueWrapper color={`#000000`} colortwo={`#FE0B0B`}>
										Thank you, you are now a member of the<br /> <br /> <strong >Paydoroz</strong><br />Family.
										you can enjoy all the benifit of Transfering Paydoroz quickly<br />
										Please continue to your Dashboard.
								</RDialogueWrapper>
									<ButtonWrapper>
										<StyledButton onClick={() => { navigate("/Protec/Dashboard") }}>
											Contnue to Dash
										</StyledButton>
									</ButtonWrapper>
								</>}{/* showAskForThanks */}

							{Error && <StyledError>{Error}</StyledError>}
						</StyledRightContainer>
						{/* <NavbarD /> */}
					</StyledRightWrapper>
				</InfoColumnRight>
			</InfoRow>
		</Container2>
	);
};

export default SignUp;