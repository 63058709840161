import React, { useState } from "react";
import {
  SDivider,
  SLink,
  SLinkContainer,
  SLinkIcon,
  SLinkLabel,
  SLogo,
  SSidebar,
  SSidebarButton,
} from "./SidebarD.style";

import { logoSVG_White } from "../../Assets/Index";

import {
  // AiOutlineApartment,
  AiOutlineHome,
  AiOutlineSetting,
} from "react-icons/ai";
import {
  RiSendPlaneFill, RiPaypalFill
} from "react-icons/ri";
import {
  GiSellCard
} from "react-icons/gi";

import { MdLogout, MdMobileFriendly } from "react-icons/md";
// import { BsPeople } from "react-icons/bs";


// import { useLocation } from "react-router-dom";

const Sidebar2 = () => {


  const [sidebarOpen, setSidebarOpen] = useState(true);
  //const { pathname } = useLocation();


  return (
    <SSidebar isOpen={sidebarOpen}>
      <>

      </>
      <SLogo>
        <img src={logoSVG_White} alt="logo" />
      </SLogo>


      <SSidebarButton onClick={() => setSidebarOpen((p) => !p)}>
        Send Money Now
      </SSidebarButton>

      <SDivider />


      {linksArray.map(({ id,icon, label, to }) => (
        


          <SLinkContainer key={id}>
            <SLink to={to} style={!sidebarOpen ? { width: `fit-content` } : {}}>
              <SLinkIcon>{icon}</SLinkIcon>
              {sidebarOpen && (
                <>
                  <SLinkLabel>{label}</SLinkLabel>
                </>
              )}
              {/*      */}
            </SLink>

          </SLinkContainer>

      

      ))}


      <SDivider />

      {secondaryLinksArray.map(({ id,icon, label, to }) => (
        <SLinkContainer key={id}>
          <SLink to={to}>
            <SLinkIcon>{icon}</SLinkIcon>
            <SLinkLabel>{label}</SLinkLabel>
          </SLink>

        </SLinkContainer>
      ))}


      <SDivider />

    </SSidebar>
  );
};

const linksArray = [
  { id:"1",
    label: "Dashboard",
    icon: <AiOutlineHome />,
    to: "/Protec/Dashboard",

  },
  {
    id: "2",
    label: "Trade",
    icon: <GiSellCard />,
    to: "/Protec/TradeD",

  },
  {id: "3",
    label: "Send",
    icon: <RiSendPlaneFill />,
    to: "/Protec/SendD",

  },
  {
     id: "4",
    label: "Pay",
    icon: <RiPaypalFill />,
    to: "/Protec/PayD",

  },

  {
    id: "5",
    label: "Flexy",
    icon: <MdMobileFriendly />,
    to: "/Protec/FlexyD",

  }
];

const secondaryLinksArray = [
  {
    id: "1",
    label: "Settings",
    icon: <AiOutlineSetting />,
    to: "/Protec/SettingsD",
  },
  {
    id: "2",
    label: "Logout",
    icon: <MdLogout />,
    to: "/Protec/LogoutD",
  },
];

export default Sidebar2;