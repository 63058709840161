import { css } from "styled-components";

export const v = {
 sidebarWidth: `300px`,
 smSpacing: `8px`,
 mdSpacing: `16px`,
 lgSpacing: `24px`,
 xlSpacing: `32px`,
 xxlSpacing: `48px`,
 borderRadius: `6px`,
};

export const btnReset = css`
    font-family: inherit;
    outline: none;
    border: none;
    background: none;
    letter-spacing: inherit;
    color: inherit;
    font-size: inherit;
    text-align: inherit;
    padding: 0;
`;