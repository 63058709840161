import React, { useState } from 'react';
import Select from 'react-select';
import {
  icon_minus, icon_equal
} from "../../Assets/Index";
import makeAnimated from 'react-select/animated';


import { Link } from 'react-router-dom';
import { Container, Button, ButtonLegend } from '../../globalStyles';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ConstValues } from '../../Pages/Protec/Data';
import {
  SelectWrapper, CalcWrapper,
  InfoColumn,
  CalcContainer,
  ChargesWrapper, FeeWraper, FeeWraper2,
  LabelWrapperLeft,
  AmountWrapper, AmountInput,
  Img, StyledError,
  NavLogoWrapper2,
  // EmailRef,
  EmailRef2,

  InfoSec, InfoRow,
   TextWrapper,
  TopLine, Heading, Subtitle,
  ImgWrapper,
  // Img2,
  IconWrapper2,
  // NavLogo2
} from './Calculator.style';





const Calculator = (
 { primary, lightBg, imgStart,
  lightTopLine, lightTextDesc,
  description, headline, lightText,
  topLine, start, img, alt,
  gradcolstar, gradcolmiddle, gradcolend,
  buttonlabel2, isbutton2, buttonlabel,
  // buttonlink,
  buttonlink2


  }) => {
  
  
  const [action, setAction] = useState("");
  const [showTradeBuySelected, setShowTradeBuySelected] = useState(true);
  const [isError, setIsError] = useState(true);
  const [Error, setError] = useState("");
  const [amount, setAmount] = useState();
  const [amount2, setAmount2] = useState();
  // const [amountNet, setAmountNet] = useState();
  const [amountNet2, setAmountNet2] = useState();
  const [nf, setNf] = useState(0);
  const [sf, setSf] = useState(0);
  const [chf, setChf] = useState(0);
  // const [feez, setFeez] = useState(0);
  const [feez2, setFeez2] = useState(0);
  
  const options = [
    { value: 'Trade:Buy', label: 'Trade:Buy', color:"#200f57"},
    { value: 'Trade:Sell', label: 'Trade:Sell', color: "#200f57" },
    { value: 'Transfer:Send', label: 'Transfer:Send', color: "#200f57" },
    { value: 'Transfer:Receive', label: 'Transfer:Receive', color: "#200f57" }
  ]
  const [t, i18n] = useTranslation('');

  const colorStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    container: provided => ({ ...provided, width: "100%" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return { ...styles, color: data.color };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
        color: "#fff",
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
      };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
          
        },
      };
    },
  };
 
  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#e6fff3',
        primary:'#bdfcde'
      }
    }

  }

  const handleChange= (selectedOption) => {
    console.log("handleChange ", selectedOption);

    setAction(selectedOption.value);
    setShowTradeBuySelected(true);

    console.log("action state ", action);

  }//********* selectAction ********/

  function netFees() {
    return Number(ConstValues.nf)
  };//=========    sendAmountToContact ================

  function serviceFees(amountToSend) {
    // let tsfee = Math.round(amountToSend * ConstValues.sf);
    if (amountToSend <= 1000) {
      return Number(ConstValues.sffix);
    } else {
      return Number(Math.round(amountToSend * ConstValues.sf) + Number(ConstValues.sffix));
    }
  };//=========    serviceFees ================

  function cashHandlingFees(amountToSend) {
    if (amountToSend <= 1000) {
      return Number(ConstValues.sffix);
    } else {
      return Number(Math.round(amountToSend * ConstValues.chf) + Number(ConstValues.chffix));
    }
  };//=========    serviceFees ================

  const handleInputBuy = e => {
    const value = e.currentTarget.value;

    let nfV = netFees();
    let sfV = serviceFees(value);
    let chfV = cashHandlingFees(value);
    let feezV = Number(nfV + sfV + chfV);
    setAmountNet2(Number(0));

    //=============== 
    if (value < ConstValues.minAmount) {
      setIsError(true); setError('Min Amount to Spend ' + ConstValues.minAmount);
      setAmount(value); setAmountNet2(0);
      setNf(nfV); setSf(sfV); setChf(chfV);
      // setFeez(feezV); 

    }
    else if (value > 9999) {
      setIsError(true); setError('Max 9999');
      setAmount(value);
      setNf(nfV); setSf(sfV); setChf(chfV);
      // setFeez(feezV);
    }
    else {
      setIsError(false); setError('');
      setAmount(value);
      setNf(nfV); setSf(sfV);
      setChf(chfV);
      // setFeez(feezV); 
      setAmountNet2(Number(value) - feezV);

    }

    // 


    //================ 
  };//=======  handleInputBuy



 return (
   <CalcWrapper>
   
     <SelectWrapper>
       <Select
         options={options}
         onChange={handleChange}
         styles={colorStyles} 
         theme={customTheme}
         autoFocus         
         defaultValue={options[0]}
        
       />
     </SelectWrapper>
   
     <>
       <CalcContainer>
         <LabelWrapperLeft>You Are Paying </LabelWrapperLeft>
         <AmountWrapper>
           <AmountInput autoFocus
             type="number"
             name="amount"
             value={amount}
             onChange={handleInputBuy} />
           {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
             (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
           }
         </AmountWrapper>
         <ChargesWrapper>
           <FeeWraper>
             <NavLogoWrapper2> <img src={icon_minus} alt="minus" />
             </NavLogoWrapper2>
             <FeeWraper2>
               <EmailRef2> {nf} </EmailRef2>
               <EmailRef2> Network Fee </EmailRef2>
             </FeeWraper2>
           </FeeWraper>
           <FeeWraper>    <NavLogoWrapper2> <img src={icon_minus} alt="minus" /> </NavLogoWrapper2>
             <FeeWraper2> <EmailRef2> {sf} </EmailRef2> <EmailRef2> Service Fee </EmailRef2> </FeeWraper2> </FeeWraper>
           <FeeWraper>
             <NavLogoWrapper2>
               <img src={icon_minus} alt="minus" />
             </NavLogoWrapper2>
             <FeeWraper2>
               <EmailRef2> {chf} </EmailRef2>
               <EmailRef2> Cash Handling Fee </EmailRef2>
             </FeeWraper2>
           </FeeWraper>
           <FeeWraper>  <NavLogoWrapper2> <img src={icon_equal} alt="equal" /> </NavLogoWrapper2>
             <FeeWraper2> <EmailRef2>{nf + sf + chf} </EmailRef2><EmailRef2> Total Fees </EmailRef2> </FeeWraper2>  </FeeWraper>
         </ChargesWrapper>
         <LabelWrapperLeft>You Get Paydoroz </LabelWrapperLeft>
         <AmountWrapper> <AmountInput readOnly type="number" name="amountNet2" value={amountNet2} />
           {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
             (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)}
         </AmountWrapper>
         {isError && <StyledError>{Error}</StyledError>}
       </CalcContainer>
     </>
 
    {/*  </InfoRow> */}
   </CalcWrapper>
 )
}

export default Calculator