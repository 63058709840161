import React from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserAuthContextProvider } from './context/UserAuthContex';
// import Jijel from './Pages/Jijel';
import Home from './Pages/HomePage/Home';
// import SignUp from './Pages/SignUp/SignUp';
import SignIn from './Pages/SignIn/SignIn';
import SignUp from './Pages/SignUp/SignUp';

import Jamal from './Pages/Jamal/Jamal';
import Help from './Pages/Help/Help';
import Language from './Pages/Language/Language';
import Transfer from './Pages/Transfer/Transfer';
import Trade from './Pages/Trade/Trade';
import Partners from './Pages/Partners/Partners';

import Dashboard from './Pages/Protec/Dashboard';
import TradeD from './Pages/Protec/TradeD';
import SendD from './Pages/Protec/SendD';
import PayD from './Pages/Protec/PayD';
import FlexyD from './Pages/Protec/FlexyD';

// import { Navbar, Footer } from './Components';
import ScrollToTop from './Components/ScrollToTop';
import ProtectedRoute from './ProtectedRoutes/ProtectedRoutes';
function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop /> 
      <UserAuthContextProvider>

      
      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* <Route exact path="/SignUp" element={<SignUp />} /> */}
          <Route exact path="/SignIn" element={<SignIn />} />
          <Route exact path="/SignUp" element={<SignUp />} />
          
          <Route exact path="/Trade" element={<Trade />} />
          <Route exact path="/Partners" element={<Partners/>} />
        <Route exact path="/Transfer" element={<Transfer />} /> 
        <Route path="/Help" element={<Help />} /> 
        <Route path="/Language" element={<Language />} /> 
        <Route path="/Jamal" element={<Jamal/>} /> 
         


          <Route path="/Protec/Dashboard" element={<ProtectedRoute />} >
            <Route path="/Protec/Dashboard" element={<Dashboard/>} /> 
          </Route>

          <Route path="/Protec/TradeD" element={<ProtectedRoute />} >
            <Route path="/Protec/TradeD" element={<TradeD/>} />
          </Route>

          <Route path="/Protec/SendD" element={<ProtectedRoute />} >
            <Route path="/Protec/SendD" element={<SendD />} />
          </Route>

          <Route path="/Protec/PayD" element={<ProtectedRoute />} >
            <Route path="/Protec/PayD" element={<PayD />} />
          </Route>
           

          <Route path="/Protec/FlexyD" element={<ProtectedRoute />} >
            <Route path="/Protec/FlexyD" element={<FlexyD />} />
          </Route>

        </Routes>
      
      </UserAuthContextProvider>  
    </Router>

  );
}

export default App;
