import styled, { css } from "styled-components";
import { Link } from 'react-router-dom'
import { dataObject } from './../Data';

const sharedStyles = css`
  background-color: #E0FFF0;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

//  const InfoSec2 = styled.div`
// width: 100%;
//     height: 100vh;
//     margin-right: 0px;
//     margin-left: 0px;
//     padding-right: 0px;
//     padding-left: 0px;
//     background: gold;
 
// @media screen and (max-width:768px) {
/*   display: block; */
  // flex-direction: column;
//  }
// `;


export const RightColBottomWrapper = styled.div`
color: ${dataObject.rightmainCol}; background: ${dataObject.rightmainBg};     
  display:flex;    
  flex-direction:column;
  justify-content: flex-start;
  align-items: center;
  height:100vh;
  width: 100%;
  /* max-width: 80%; */
   z-index: 5;
 @media screen and (max-width:768px) {
  max-width: 100%;

 }
`;




export const NavLogoWrapper = styled.div`
color: ${dataObject.rightTopNavCol};            color:black;
background: ${dataObject.rightTopNavBg};        
  justify-content:center;
  align-items: center;
  /*  min-height:100px;*/
 /*  border-radius: 50%; */
 height: 60px;
 position:relative;
 z-index: 6;


  img {
        max-height: 100%;
        height: auto;
        padding: 10px 0px 10px 0px;
    }
 @media screen and (max-width:768px) {
 }

`;


export const NavLogoWrapper2 = styled.div`
color: black;
    background: rgb(224, 255, 255);
    display: flex;
    flex-direction: column;   
    justify-content: center;    
    align-items: center;
    position: relative;  
    margin-right: 5px;
    /* min-width: 150px; */
    z-index: 6;


  img {
        max-height: 100%;
        height: auto;
        padding: 5px 0px 5px 0px;
    }
 @media screen and (max-width:768px) {
 }

`;


export const OfferWraper = styled.div`
color: black;
    background: rgb(224, 255, 255);
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    min-width: 150px;
    z-index: 6;


  img {
        max-height: 100%;
        height: auto;
        padding: 10px 0px 10px 0px;
    }
 @media screen and (max-width:768px) {
 }

`;

export const OffersWraper = styled.div`
display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0px;
    margin: 0px;
    border-radius: 5px;
    width: 100%;
    flex-direction: row !important;


  img {
        max-height: 100%;
        height: auto;
        padding: 10px 0px 10px 0px;
    }
 @media screen and (max-width:768px) {
 }

`;

export const NaveSettingLogo = styled(Link)`
box-sizing: border-box;
    margin: 0px;
    padding: 0px;    
    letter-spacing: 0.6px;

`

export const NavBalanceWrapper = styled.div`
color: ${dataObject.rightTopNavCol};            
color: purple;            
background: ${dataObject.rightTopNavBg}; 
display:flex;
  align-items: center; 
  height:100px;
  width: 100%;
  padding: 0px 20px 0px 10px;
 /*  overflow-y: auto; */
  font-weight: bold;
    font-size: 1.25rem !important;

   /*  text-align: center; */

 position:relative;
 z-index: 6;
 @media screen and (max-width:768px) {
   height:60px;
 }

`;

export const TitleWrapper = styled.h1`
/* color: ${dataObject.rightTopNavCol};             */
color: purple;            
/* background: ${dataObject.rightTopNavBg};  */
display:flex;
flex-direction: row;
justify-content: center;
  align-items: center;
  text-align : center;
  align-self: center;
  /* height:100px;
  width: 100%; */
  padding: 0px 20px 0px 10px;
 /*  overflow-y: auto; */
  font-weight: bold;
    font-size: 1.25rem !important;
   /*  text-align: center; */
 position:relative;
 z-index: 6;
 @media screen and (max-width:768px) {
   padding: 5px 10px 2px;
    min-width: 80%;    
 }
`;


export const LabelWrapper = styled.h1`
color: ${dataObject.rightTopNavCol};            
color: purple;            
background: ${dataObject.rightTopNavBg}; 
display: flex;
    flex-direction: row;   
    justify-content: center;
    align-items: center;
    text-align : center;
    align-self: center;
    padding: 0px 10px 5px;
    min-width: 350px;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: rgb(0, 102, 51) !important;
 @media screen and (max-width:768px) {
   height:60px;
 }

`;
export const LabelWrapperLeft = styled.h1`
color: ${dataObject.rightTopNavCol};            
color: purple;            
background: ${dataObject.rightTopNavBg}; 
display: flex;
    flex-direction: row;   
    justify-content: left;
    align-items: center;
    text-align : center;
    align-self: center;
    padding: 0px 10px 5px;
    min-width: 350px;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: rgb(0, 102, 51) !important;
 @media screen and (max-width:768px) {
   /* height:60px; */
   min-width: 80%;
   padding: 0px 5px 5px 5px;
   font-size:0.9rem !important;
 }

`;

export const EmailWrapper = styled.div`
color: ${dataObject.rightTopNavCol};            
color: purple;            
background: ${dataObject.rightTopNavBg}; 
display: flex;
    flex-direction: row;   
    justify-content: center;
    align-self: center;
    min-width: 350px;
    font-size: 1.5rem !important;
 @media screen and (max-width:768px) {
  min-width: 80%;
   height:60px;
 }

`;
export const StyledInput = styled.input`
  display: block;
  width: 100%;
 /*  ${sharedStyles} */
background-color: Azure;
   height: 40px;
 border-radius: 5px;
  border: 1px solid #ddd;
 margin: 0px 0 20px 0;
   padding: 10px; 
  box-sizing: border-box;

`;


export const StyledInput2 = styled.input`
  display: block;
  width: 100%; 
background-color: Azure;
   height: 40px;
 border-radius: 5px;
  border: 1px solid #ddd;
 margin: 0px 0 20px 0;
   padding: 10px; 
  box-sizing: border-box;
  font-size: 1.2rem !important;
  font-weight: 500 !important;

`;

export const AmountInput = styled.input`
  display: block;
  width: 100%; 
background-color: Azure;
   height: 40px;
 border-radius: 5px;
  border: 1px solid #ddd;
 margin: 0px 0 20px 0;
   padding: 10px; 
  box-sizing: border-box;
  font-size: 1.2rem !important;
  font-weight: 500 !important;

`;


//NavLogoRightWrapper   NavProfileWrapper
export const NavLogoRightWrapper = styled.div`
color: ${dataObject.rightTopNavCol};            color:black;
background: ${dataObject.rightTopNavBg};        color:red;
display:flex;
  justify-content:space-between;
  align-items: center;
  /*  min-height:100px;*/
 /*  border-radius: 50%; */
 height: 76px;
 position:relative;
 z-index: 4;


  img {
        max-width: 100%;
        height: auto;
        align-self: center;
    }
 @media screen and (max-width:768px) {
 }

`;

export const TransContainer = styled.div`
 color: ${dataObject.rightCol};                      
  background: ${dataObject.rightBg};
  /* background:cyan; */
  /* border: 5px dotted red; */
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-self: center;
  padding: 0px 0px 0px 0px;
  margin: 0px;
  border-radius: 5px;  
  width:100%;    
  
  @media screen and (max-width:768px) {
  width:95%;
  gap:10px 40px;

 }

`;

export const NavProfileWrapper = styled.div`
color: ${dataObject.rightTopNavCol};            
background: ${dataObject.rightTopNavBg};        
display:flex;
  align-items: center;
  min-height:100px;
  width: 100%;
  padding: 0px 0px 0px 10px;
 /*  overflow-y: auto; */
  font-weight: medium;    
    font-size: 1.2rem !important;
   
   /*  text-align: center; */
 
 position:relative;
 z-index: 5;
 @media screen and (max-width:768px) {
 }

`;

export const StyledRightWrapper = styled.div`
  color: ${dataObject.rightCol};                      
  background: ${dataObject.rightBg};            
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:100%;
  padding: 0 0px;
   z-index: 5;
 /*   border: 3px solid red; */
  @media screen and (max-width:768px) {
 
 }

`;

export const IconWrapper2 = styled.div`
display: flex;
justify-content: center;
align-self: center;
margin: 0px 0px 20px 0px;
 z-index: 5;
@media screen and (max-width:768px) {
  margin: 0px 0px 20px 0px;
  
 }

`

export const IconAddUser = styled.div`
display: flex;
    flex: 1 1 0%;
    height: 80px;
    align-items: center;
    place-content: center;
    z-index: 5;
  


`

export const ListContactWrapper = styled.div`
color: rgb(255, 255, 255);
    background: rgb(224, 255, 255);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0px 0px;
  


`
export const StyledAddUserButton = styled.button`
display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 102, 51);
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    border: 0px;
    border-radius: 5px;
    height: 50%;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    box-sizing: border-box;
  


`

export const TitleAddUser = styled.div`
background: rgb(224, 255, 255);
    border-bottom: 1px dotted grey;
    flex: 10 1 0%;
    height: 80px;
    width: 300px;
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    color: rgb(0, 102, 51);
    font-weight: 400;
    text-align: left;
    font-size: 1.2rem !important;
  


`
export const TitleSelectUser = styled.div`
background: rgb(224, 255, 255);
    height: 51px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    color: rgb(0, 102, 51);
    font-weight: 400;
    padding: 0px 0px 10px 10px;
    text-align: left;
    font-size: 1.2rem !important;
  


`
export const EmailRef = styled.h1`
font-weight: 400;
    text-align: left;
    width: 75%;
    align-items: center;
    font-size: 1rem !important;
    color: black !important;
`
export const EmailRef2 = styled.h1`
  font-weight: 400;
    padding: 0px 5px;
    text-align: left;
    align-items: center;
    font-size: 1rem !important;
    color: black !important;
`;

export const TopLine = styled.div`
 color: ${dataObject.leftmainCol};
 font-size: 18px;
 line-height: 16px;
 letter-spacing: 1.4px;
 margin-bottom: 16px;
 
`
export const Heading = styled.div`
 color: ${dataObject.leftmainCol};
 margin-bottom: 24px;
 font-size: 48px;
 line-height: 1.1;
 letter-spacing: 1.4px;
 text-align: center;
 padding: 10px 10px 10px 10px;
`
export const Subtitle = styled.p`
color: ${dataObject.leftmainCol};
 max-width: 440px;
 margin-bottom: 35px;
 font-size: 18px;
 line-height: 24px;
 text-align: center;
  padding: 10px 10px 10px 10px;

 
`
export const Welcome = styled.h1`
  color: ${dataObject.leftmainBg};
    font-weight: bold;    
    font-size: 1.5rem !important;
    padding: 0px 0px 40px 0px ;
    text-align: center;
@media screen and (max-width:768px) {
    font-size: 1rem !important;
    padding: 0px 0px 20px 0px ;
  
 }
`

export const WelcomeName = styled.h1`
    font-weight: bold;    
    font-size: 2rem !important;
    color: ${dataObject.leftmainBg};
    padding: 0px 0px 40px 0px ;
    text-align: center;
@media screen and (max-width:768px) {
    font-size: 1.5rem !important;
    padding: 0px 0px 20px 0px ;  
 }
`

export const Transactions = styled.h1`
    font-weight: bold;
    padding: 0px 0px 10px 20px ; 
    text-align: left !important; 
    align-self  :left ;
    font-size: 1.5rem !important;
    color: ${dataObject.leftmainBg};
    
    
@media screen and (max-width:768px) {
    font-size: 1.5rem !important;
    padding: 0px 0px 20px 0px ;  
 }
`

export const NewToPd = styled.h1`      
    font-size: 0.8rem !important;
    color :Blue !important;
    padding: 0px 0px 20px 0px ;
    text-align: center;
    text-decoration: none !important;
@media screen and (max-width:768px) {
    font-size: 0.6rem !important;
 }
`

export const TransWraper = styled.h1`      
    font-size: 0.8rem !important;
    color :Blue !important;
    padding: 0px 0px 20px 0px ;
    text-align: center;
    text-decoration: none !important;
@media screen and (max-width:768px) {
    font-size: 0.6rem !important;
 }
`
export const NavLinkPw = styled(Link)`
 color : #0000FF !important;
 display: flex;
 flex-direction: column; 
 align-items: center !important;
 text-align: center !important;
 text-decoration: none;
 padding: 0px 0px 30px 0px ;
 width: 100%;
 font-size: 0.9rem !important;
/*  height: 100%;  */
  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 0px 0px 20px 0px ;
    width: 100%;
    display: table;

    &:hover  {
    cursor: pointer !important;
    color: red !important;
    transition: all 0.3s ease;
   
    }
 
 }
`



export const StyledForm = styled.form`
  color: ${dataObject.rightCol};
  background: ${dataObject.rightBg};
  /* display: none; */
  flex-direction: column;
  /* justify-content: space-between !important; */
  height: 100%;
  width: 100%;
  max-width: 700px;
  padding: 40px 20px;
  border-radius: 10px;
  /* box-sizing: border-box; */
  box-shadow: 00px 0px 20px 0px rgba(0, 102, 51, 0.2);
  overflow-y: auto;

  label{
    color:black
  }
@media screen and (max-width:768px) {
  /* max-width: 100%; */
 /*  flex-basis: 100%; */
  /* display: flex;  */
/*   display: flex;
  flex-direction: column;
  justify-content: space-between !important; */
  height: 100%;
  border-radius: 0px;
  padding: 20px 20px;
 }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: green ;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 0px;
  box-sizing: border-box;
 

  /* height: 100vh;
  width:80%;
  padding: 0 0px;
  background:Coral; */
 /*  @media screen and (max-width:768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  justify-content: space-between;
    background:DarkOrange;
  width:100%;

 } */
`;

export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: Red ;
  height: 40px;
  min-width: 350px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 0px ;
  padding: 0px;
  box-sizing: border-box; 
   @media screen and (max-width:768px) {
  min-width: 80%;

 } 
`;


export const ChargesWrapper = styled.div`
  display: flex;
    flex-direction: column;  
    justify-content: flex-start;
    align-items: center;
    min-width: 350px;
    margin: 0px;
`;


export const FeeWraper = styled.div`
  display: flex;
    flex-direction: row;    
    align-items: center;  
    justify-content: flex-start;
    align-self: center;
    padding: 2px 10px 2px 10px;
    min-width: 350px;
    margin: 0px;
    font-size: 1rem !important;
@media screen and (max-width:768px) {
    font-size: 0.7rem !important;
   padding: 2px 5px;
  min-width: 80%;
}

`;
export const FeeWraper2 = styled.div`
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-self: center;
    padding: 5px 10px;
    width: 100%;
    margin: 0px;
    font-size: 1rem !important;
`;


export const ImgWrapper = styled.div`
width: 40px;
height: 40px;
display: flex;
justify-content: flex-end;
align-items: center;
text-align:center;
/* margin-left: -40px; */
position :relative;
/* left: -40px; */
z-index: 99;


background-color: none;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 0px 0 0px 0;
  padding: 0px;
  box-sizing: border-box;

`

export const Img = styled.img`
padding: 8px;
border: 0;
width: 40px;
height: 40px;
vertical-align: middle;
justify-content:center;
display: inline-block;
margin-left: -40px; 
`

export const ButtonWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
align-items: center;
/* max-width:100px; */
margin: 0px 0px 50px 0px;
width: 100% !important;

`

export const StyledButton = styled.button`
  display: block;
  background-color: #006633;
  color: #fff;
  font-size: 0.9rem;
  border: 0;
  border-radius: 10px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
  width: 50% !important;
 
`;

export const StyledError = styled.div`
  color: red;
  font-weight: 800;
  margin: 0 0 40px 0;
  align-items: center;
  @media screen and (max-width:768px) {
    margin: 0 0 20px 0;
 }

`;



export const Img2 = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
justify-content:center;
display: inline-block;

`

export const NewContactWrapper = styled.div`
color: rgb(255, 255, 255);
    background: rgb(224, 255, 255);
    border-bottom: 1px dotted grey;
    height: 80px;
    width: 100%;
    display: flex; 
    align-items: center;  
    justify-content: space-between;
    padding: 3px 0px;

`


export const ContactWraper = styled.div`
display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    align-self: center;
    z-index: 40;
    padding: 0px;
    margin: 0px;
    border-radius: 5px;
    width: 100%;

`
export const ContactWraperInner = styled.div`
display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    background: rgb(211, 249, 208);
    padding: 5px 10px;
    width: 100%;
    margin: 0px 0px 10px;
    border-radius: 5px;
    border: 1px solid rgb(0, 102, 51);

`


export const AddUserButtonWrapper = styled.div`
background: rgb(224, 255, 255);
    color: rgb(0, 102, 51);
    flex: 2 1 0%;
    height: 80px;
    width: 300px;
    display: flex;
    flex-wrap: wrap;  
    align-items: center;
   
    justify-content: center;
`

export const StyledTradeBuyButton = styled.button`
  display: flex;
    flex-shrink: 0;    
    align-items: center;    
    justify-content: center;
    background-color: ${props => props.bgCol || "#006633"} !important;; 
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    border: 0px;
    border-radius: 5px;
    height: 50%;
    width: 50%;
    padding: 10px;
    cursor: pointer;
    box-sizing: border-box;
 
`;


export const PWrapper = styled.div`
display: flex;
    flex-direction: column; 
    justify-content: center;
    align-self: center;
    text-align: center;
    color: rgb(0, 0, 0);
    width: 350px;
    font-size: 0.9rem !important;
`

export const RDialogueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;
  width:350px;
  color: ${props => props.color || "#063"} !important;  
  font-size: 0.9rem !important;
   strong {
      font-size: 1.5rem !important;
    font-weight: 500 !important;
    color: ${props => props.colortwo || "#000"} !important;
    }  
`;

export const ModalDialogueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;
  width:350px;
  color: ${props => props.color || "#063"} !important;  
  font-size: 0.9rem !important;
   strong {
      font-size: 1.5rem !important;
    font-weight: 500 !important;
    color: ${props => props.colortwo || "#000"} !important;
    }  
    @media screen and (max-width:768px) {
    font-size: 0.9rem !important;
    padding-right: 5px ; 
    min-width: 80%;
 }

`;

export const ButtonModal = styled.button`
  border-radius: 30px;
    white-space: nowrap;
    padding: 8px 20px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 24px;
    margin-bottom: 5px;
    display: inline-flex;   
    justify-content: center;    
    align-items: center;
    align-self: center;
    transition: all 0.5s ease 0s;
    background: rgb(0, 102, 51) !important;
 
`;

export const StyledAddUserButtonOffer = styled.button`
  display: flex;
    flex-shrink: 0;
    
    align-items: center;
    align-self: center;
    justify-content: center;
    background-color: black;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    border: 0px;
    border-radius: 5px;
    width: 80px;
    padding: 2px;
    cursor: pointer;
    box-sizing: border-box;
 
`;
