import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';
//import { tradeObjOne, tradeObjTwo, tradeObjThree, tradeObjFour, tradeObjFive, tradeObjSix, tradeObjSeven } from '../Data';

import { useTranslation} from 'react-i18next';

const Language = () => {

 const [t, i18n] = useTranslation(''); 

 return (
  <>
   <Navbar />
   <br />

   
   <h1>***** Language Page/ component ****</h1><br />
   <br /><br />
   
{/*    <h1> {t("header.message")} </h1> 
   <h1> {t("welcome_to_react")} </h1>  */}
   <br /><br />
   <h3>** Needs attention </h3><br />
   <h3>** Arabic shit</h3><br />
  
  </>
 )
}

export default Language;