import styled from "styled-components";
/* import { FaMagento } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Container, Button } from '../../globalStyles'; */




export const InfoSec = styled.div`
display:flex;
flex:1;
height:100%;
justify-content:center;
color: #fff;
padding: 100px 0;
background: ${props => (props.gradcolstar || '#063')} ;
background: #063; /* fallback for old browsers */
background: -webkit-linear-gradient(to right, ${props => (props.gradcolstar || '#ee0979')} , ${props => (props.gradcolend || '#063')});  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient( to right, ${props => (props.gradcolstar || '#063')} ,${props => (props.gradcolmiddle || '#063')},${props => (props.gradcolend || '#063')} ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
//  border: 5px dotted black;                                       
 // height:100%;                                     


@media screen and (max-width:768px) {
  padding: 80px 0;

 }

`;

export const InfoRow = styled.div`
 display: flex;
 flex-wrap: wrap;
 margin: 0 -15px -15px -15px;
 //justify-content:center;
 align-items: center;
 flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
//width:80%;
//  border: 5px dotted white;  

`;

export const InfoColumn = styled.div`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
//display:flex;
flex:1;
max-width: 50%;
flex-basis: 50%;

//flex-direction:column;
//justify-content:center;
//align-items: center;
 //margin-bottom: 15px; 
  
// height:100%;

// border: 5px dotted yellow;  

 @media screen and (max-width:768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;

 }

`;

export const TextWrapper = styled.div`

 max-width: 540px;
 padding-top: 0;
 padding-bottom: 60px;

 @media screen and (max-width:768) {
  padding-bottom: 65px;

 }
`
export const TopLine = styled.div`

 color: ${({ lightTopLine }) => (lightTopLine ? '#a9b3c1' : '#4b59f7')};
 font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;

 
`

export const Heading = styled.h1`
 margin-bottom: 24px;
 font-size: 48px;
 line-height: 1.1;
 color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};
 letter-spacing: 1.4px;
 
 
`

export const Subtitle = styled.p`
 max-width: 440px;
 margin-bottom: 35px;
 font-size: 18px;
 line-height: 24px;
 color: ${({ lightTextDesc }) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};
  
 
`

export const ImgWrapper = styled.div`
max-width: 500px;
display: flex;
justify-content: ${({ ...start }) => (start ? 'flex-start' : 'flex-start')};


`

export const Img = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
justify-content:center;
display: inline-block;
max-height: 500px;
margin-left: auto;
margin-right: auto;


`



export const Img2 = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
justify-content:center;
display: inline-block;

`
export const IconWrapper2 = styled.div`
max-width: 90px;
display: inline-flex;
align-items:center;
padding: 0 0px;
margin-right: 10px;
justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')}; 


`

export const NavLogo2 = styled.div`
color:#fff; 
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
display: flex;
font-size: 2rem;
align-items: center;


`

export const HeroBtnWrapper = styled.div`
margin-top: 32px;
display: flex;
flex-direction: column;
align-items: center;
`

export const DzIcon = styled.div`
  margin-left: 8px;
  font-size: 20px;

`