import styled, { css } from "styled-components";
import { Link } from 'react-router-dom'
import { dataObject } from './../Data';








export const NavLogoWrapper = styled.div`
color: ${dataObject.rightTopNavCol};            color:black;
background: ${dataObject.rightTopNavBg};        
  justify-content:center;
  align-items: center;
  /*  min-height:100px;*/
 /*  border-radius: 50%; */
 height: 60px;
 position:relative;
 z-index: 6;


  img {
        max-height: 100%;
        height: auto;
        padding: 10px 0px 10px 0px;
    }
 @media screen and (max-width:768px) {
 }

`;



export const NavBalanceWrapper = styled.div`
color: ${dataObject.rightTopNavCol};            
background: ${dataObject.rightTopNavBg};        
display:flex;
  align-items: center;
  height:100px;
  width: 100%;
  padding: 0px 20px 0px 10px;
 /*  overflow-y: auto; */
  font-weight: medium;
    font-size: 1.2rem !important;

   /*  text-align: center; */

 position:relative;
 z-index: 6;
 @media screen and (max-width:768px) {
   height:60px;
 }

`;
//NavLogoRightWrapper   NavProfileWrapper
export const NavLogoRightWrapper = styled.div`
color: ${dataObject.rightTopNavCol};            color:black;
background: ${dataObject.rightTopNavBg};        color:red;
display:flex;
  justify-content:space-between;
  align-items: center;
  /*  min-height:100px;*/
 /*  border-radius: 50%; */
 height: 76px;
 position:relative;
 z-index: 4;


  img {
        max-width: 100%;
        height: auto;
        align-self: center;
    }
 @media screen and (max-width:768px) {
 }

`;
export const StyledRightContainer = styled.div`
 color: ${dataObject.rightCol};                      
  background: ${dataObject.rightBg};
  /* background:cyan; */
  /* border: 5px dotted red; */
  height:100%;
  width:80%;
  display: flex;
   flex-direction:column;
   justify-content: flex-start;
  /* flex-wrap:wrap; */
  align-items: flex-start;
  /* align-self:center;   */
/*  justify-content: flex-start; */
  padding: 5px 0px 60px 0px;
  gap:10px 40px;
  @media screen and (max-width:768px) {
width:95%;
gap:10px 40px;

 }

`;

export const NavProfileWrapper = styled.div`
color: ${dataObject.rightTopNavCol};            
background: ${dataObject.rightTopNavBg};        
display:flex;
  align-items: center;
  min-height:100px;
  width: 100%;
  padding: 0px 0px 0px 10px;
 /*  overflow-y: auto; */
  font-weight: medium;    
    font-size: 1.2rem !important;
   
   /*  text-align: center; */
 
 position:relative;
 z-index: 5;
 @media screen and (max-width:768px) {
 }

`;



export const IconWrapper2 = styled.div`
display: flex;
justify-content: center;
text-align:center;
align-items: center;
margin: 0px 0px 20px 0px;
 z-index: 5;
@media screen and (max-width:768px) {
  margin: 0px 0px 20px 0px;
  
 }

`

export const TopLine = styled.div`
 color: ${dataObject.leftmainCol};
 font-size: 18px;
 line-height: 16px;
 letter-spacing: 1.4px;
 margin-bottom: 16px;
 
`
export const Heading = styled.div`
 color: ${dataObject.leftmainCol};
 margin-bottom: 24px;
 font-size: 48px;
 line-height: 1.1;
 letter-spacing: 1.4px;
 text-align: center;
 padding: 10px 10px 10px 10px;
`
export const Subtitle = styled.p`
color: ${dataObject.leftmainCol};
 max-width: 440px;
 margin-bottom: 35px;
 font-size: 18px;
 line-height: 24px;
 text-align: center;
  padding: 10px 10px 10px 10px;

 
`
export const Welcome = styled.h1`
  color: ${dataObject.leftmainBg};
    font-weight: bold;    
    font-size: 1.5rem !important;
    padding: 0px 0px 40px 0px ;
    text-align: center;
@media screen and (max-width:768px) {
    font-size: 1rem !important;
    padding: 0px 0px 20px 0px ;
  
 }
`

export const WelcomeName = styled.h1`
    font-weight: bold;    
    font-size: 2rem !important;
    color: ${dataObject.leftmainBg};
    padding: 0px 0px 40px 0px ;
    text-align: center;
@media screen and (max-width:768px) {
    font-size: 1.5rem !important;
    padding: 0px 0px 20px 0px ;  
 }
`

export const NewToPd = styled.h1`      
    font-size: 0.8rem !important;
    color :Blue !important;
    padding: 0px 0px 20px 0px ;
    text-align: center;
    text-decoration: none !important;
@media screen and (max-width:768px) {
    font-size: 0.6rem !important;
 }
`
export const NavLinkPw = styled(Link)`
 color : #0000FF !important;
 display: flex;
 flex-direction: column; 
 align-items: center !important;
 text-align: center !important;
 text-decoration: none;
 padding: 0px 0px 30px 0px ;
 width: 100%;
 font-size: 0.9rem !important;
/*  height: 100%;  */
  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 0px 0px 20px 0px ;
    width: 100%;
    display: table;

    &:hover  {
    cursor: pointer !important;
    color: red !important;
    transition: all 0.3s ease;
   
    }
 
 }
`



export const StyledForm = styled.form`
  color: ${dataObject.rightCol};
  background: ${dataObject.rightBg};
  /* display: none; */
  flex-direction: column;
  /* justify-content: space-between !important; */
  height: 100%;
  width: 100%;
  max-width: 700px;
  padding: 40px 20px;
  border-radius: 10px;
  /* box-sizing: border-box; */
  box-shadow: 00px 0px 20px 0px rgba(0, 102, 51, 0.2);
  overflow-y: auto;

  label{
    color:black
  }
@media screen and (max-width:768px) {
  /* max-width: 100%; */
 /*  flex-basis: 100%; */
  /* display: flex;  */
/*   display: flex;
  flex-direction: column;
  justify-content: space-between !important; */
  height: 100%;
  border-radius: 0px;
  padding: 20px 20px;
 }
`;
const sharedStyles = css`
  background-color: #E0FFF0;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: green ;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 0px;
  box-sizing: border-box;
 

  /* height: 100vh;
  width:80%;
  padding: 0 0px;
  background:Coral; */
 /*  @media screen and (max-width:768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  justify-content: space-between;
    background:DarkOrange;
  width:100%;

 } */
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
 /*  ${sharedStyles} */
background-color: Azure;
   height: 40px;
 border-radius: 5px;
  border: 1px solid #ddd;
 margin: 0px 0 20px 0;
   padding: 10px; 
  box-sizing: border-box;

`;


export const ImgWrapper = styled.div`
width: 40px;
height: 40px;
display: flex;
justify-content: flex-end;
align-items: center;
text-align:center;
/* margin-left: -40px; */
position :relative;
/* left: -40px; */
z-index: 99;


background-color: none;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 0px 0 0px 0;
  padding: 0px;
  box-sizing: border-box;

`

export const Img = styled.img`
padding: 8px;
border: 0;
width: 40px;
height: 40px;
vertical-align: middle;
justify-content:center;
display: inline-block;
margin-left: -40px; 
`

export const ButtonWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
align-items: center;
/* max-width:100px; */
margin: 0px 0px 50px 0px;
width: 100% !important;

`

export const StyledButton = styled.button`
  display: block;
  background-color: #006633;
  color: #fff;
  font-size: 0.9rem;
  border: 0;
  border-radius: 10px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
  width: 50% !important;
 
`;

export const StyledError = styled.div`
  color: red;
  font-weight: 800;
  margin: 0 0 40px 0;
  align-items: center;
  @media screen and (max-width:768px) {
    margin: 0 0 20px 0;
 }

`;
