
import React, {useEffect } from 'react';
// import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
// import { Button } from '../../globalStyles';
import { useLocation } from "react-router-dom";
import {
  NavD
  // ,
  // IconWrapper,
  // NavbarContainer, NavLogo, MobileIcon,
  // NavMenu, NavItem, NavLinks,
  // NavItemBtn, NavBtnLink
} from './NavbarD.style'


//https://github.com/CodeFocusChannel/react-sidebar

import {
  // SDivider,
  SLink,
  SLinkContainer,
  SLinkIcon,
  SLinkLabel,
  // SLogo,
  // SSidebar,
  // SSidebarButton,
} from "./NavbarD.style";

//import { ReactComponent as Logo } from '../../Assets/paydoroz_logo.svg';
import {
  // AiOutlineApartment,
  AiOutlineHome,
  // AiOutlineSetting,
} from "react-icons/ai";
import {
  RiSendPlaneFill, RiPaypalFill
} from "react-icons/ri";
import {
  GiSellCard
} from "react-icons/gi";

import { MdMobileFriendly } from "react-icons/md";

// *******************************************
// *******************************************
// **********   No Top Navbar         ********
// **********   Bottom Menu           ********
// **********   For Mobile            ********
// *******************************************
// *******************************************

const Navbar2 = () => {
  // const [sidebarOpen, setSidebarOpen] = useState(true);
  const { pathname } = useLocation();


  // const [click, setClick] = useState(false);
  // const [button, setButton] = useState(true);

  // const handleClick = () => setClick(!click);
  //const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      // setButton(false)
    } else {
      // setButton(true)
    }
  }

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton)

  return (
    <><IconContext.Provider value={{ color: '#fff' }}>
      <NavD>     
          
          <SLinkContainer isActive={pathname === "/Protec/Dashboard"}>
            <SLinkIcon >{<AiOutlineHome />}</SLinkIcon>
            <SLink to={"/Protec/Dashboard"}> <SLinkLabel>Dash</SLinkLabel> </SLink>
          </SLinkContainer>

        <SLinkContainer isActive={pathname === "/Protec/TradeD"}>
          <SLinkIcon >{<GiSellCard />}</SLinkIcon>
          <SLink to={"/Protec/TradeD"}> <SLinkLabel>Trade</SLinkLabel> </SLink>
        </SLinkContainer>

        <SLinkContainer isActive={pathname === "/Protec/SendD"}>
          <SLinkIcon >{<RiSendPlaneFill />}</SLinkIcon>
          <SLink to={"/Protec/SendD"}> <SLinkLabel>Send</SLinkLabel> </SLink>
        </SLinkContainer>

        <SLinkContainer isActive={pathname === "/Protec/PayD"}>
          <SLinkIcon >{<RiPaypalFill />}</SLinkIcon>
          <SLink to={"/Protec/PayD"}> <SLinkLabel>Pay</SLinkLabel> </SLink>
        </SLinkContainer>

        <SLinkContainer isActive={pathname === "/Protec/FlexyD"}>
          <SLinkIcon >{<MdMobileFriendly />}</SLinkIcon>
          <SLink to={"/Protec/FlexyD"}> <SLinkLabel>Flexy</SLinkLabel> </SLink>
        </SLinkContainer>
        
      </NavD>
    </IconContext.Provider>
    </>
  )
}

export default Navbar2