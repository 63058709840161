import styled from "styled-components";
// import { FaMagento } from 'react-icons/fa'
// import { Link } from 'react-router-dom'
// import { Container } from '../../globalStyles';
import { MdClose } from 'react-icons/md';

export const Background = styled.div`
  width: 100%;
  height: 100%;
 /*  background: rgba(0, 0, 0, 0.8); */
 background: white;
 /* border: 3px dotted red; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 72;
`;

export const ModalWrapper = styled.div`
  width: 90%;
  height: 90%;
   /* border: 3px dotted black; */
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: absolute;
  z-index: 73;
  border-radius: 10px;
`;
export const CloseButtonWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:flex-end;
background:red;
 /* background:purple; */
/* height: 60px; */
padding: 20x 10px 5px 10px;
min-width: 350px;
 font-size: 1.8rem !important;
/* margin-bottom: 10px; */
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 1.2rem !important;
   padding: 5px 10px 2px 10px ;
  /* align-items: center; */
  min-width: 80%;
 }
 
`
export const ModalContent = styled.div`
background: #ffffff;
/* border: 3px dotted red; */
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  z-index: 74;
  width: 100%;
  
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  /* position: absolute; */
  /* top: 20px; */
  /* right: 20px; */
  /* width: 32px; */
  /* height: 32px; */
  /* padding: 0; */
  z-index: 55;
`;

export const LabelWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:flex-start;
align-self: left;
/* background:yellow; */
 /* background:purple; */
/* height: 60px; */
padding: 0px 10px;
min-width: 350px;
 font-size: 1.0rem !important;
  font-weight: 500 !important;        
    color :#006633 !important;
/* margin: 0px; */
/* margin-bottom: 10px; */
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 0.9rem !important;
   padding: 0px 5px;
  /* align-items: center; */
  min-width: 80%;
 }
 
`
export const PreviewTextWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:flex-start;
padding: 0px 20px;
min-width: 350px;
margin-top: -4px;
margin-bottom: 5px;
 font-size: 1.0rem !important;
  font-weight: 300 !important;        
    color :#000000 !important;
  @media screen and (max-width:768px) {
    font-size: 0.9rem !important;
   padding: 0px 5px;
  min-width: 80%;
 } 
`

export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  /* background-color: green ; */
  height: 40px;
  min-width: 350px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 5px 0;
  padding: 0px 0px 5px 0px;
  box-sizing: border-box;
  /* height: 100vh;
  width:80%;
  padding: 0 0px;
  background:Coral; */
  @media screen and (max-width:768px) {
  min-width: 80%;
  /* flex-basis: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* justify-content: space-between; */
    /* background:DarkOrange; */
  /* width:100%; */
  }
 
`;

export const PWrapper = styled.div`
  display: flex;
flex-direction: column;
justify-content:center;
align-self: center;
text-align: center;
/* border: 1px solid #063; */
/* background:purple; */
 /* background:purple; */
/* height: 60px; */
/* padding: 0px 10px; */
width: 350px;

/* margin: 0px; */
 font-size: 0.9rem !important;
/* margin-bottom: 10px; */
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 0.9rem !important;
     padding: 5px 10px 2px 10px ;
   /* padding: 0px 10px; */
  /* align-items: center; */
  min-width: 80%;
 }
`;

export const ReceiptItemWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:flex-start;
align-self: left;
/* background:yellow; */
 /* background:purple; */
/* height: 60px; */
padding: 0px 20px;
min-width: 350px;
 font-size: 1rem !important;
  font-weight: 300 !important;        
    color :#000000 !important;
/* margin: 0px; */
margin-top: -4px;
margin-bottom: 5px;
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 1.0rem !important;
   padding: 0px 20px;
  /* align-items: center; */
  min-width: 80%;
 }
 
`



export const AmountInput = styled.input`
  display: block;
  width: 100% !important;

background-color: Azure;
   height: 40px;
 border-radius: 5px;
  border: 1px solid #ddd;
 margin: 0px 0 20px 0;
   padding: 10px;
  box-sizing: border-box;
   font-size: 1.2rem !important;
  font-weight: 500 !important;  
  @media screen and (max-width:768px) {
    font-size: 1.0rem !important;
   padding: 0px 20px;
  /* align-items: center; */
  min-width: 80%;
 }

`;
export const EmailWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:center;
align-self: center;
/* background:purple; */
 /* background:purple; */
/* height: 60px; */
/* padding: 0px 10px; */
min-width: 350px;
/* margin: 0px; */
 font-size: 1.5rem !important;
/* margin-bottom: 10px; */
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 1.2rem !important;
   /* padding: 0px 10px; */
  /* align-items: center; */
  min-width: 80%;
 }
 
`
export const InputWrapper = styled.div` 
 /* border: 3px dotted yellow; */
display: flex;
flex-direction: row;
justify-content:center;
/* align-self: center; */
/* background:purple; */
 font-size: 1.5rem !important;
  @media screen and (max-width:768px) {
    font-size: 1.2rem !important;
   /* padding: 0px 10px; */
  /* align-items: center; */
  min-width: 80%;
 }
 
`

export const StyledInput = styled.input`
  display: block;
  width: 100%;
background-color: Azure;
   height: 40px;
 border-radius: 5px;
  border: 1px solid #ddd;
 /* margin: 0px 0 0px 0; */
   padding: 10px; 
  box-sizing: border-box;
  font-size: 1.2rem !important;
  font-weight: 500 !important;  

`;

export const TextAreaWrapper = styled.div` 
display: flex;
flex-direction: row;
justify-content:center;
align-self: center;
/* background:purple; */
min-width: 250px;
 font-size: 1.2rem !important;
 margin-bottom: 10px;
 padding-bottom: 10px;
  @media screen and (max-width:768px) {
    font-size: 1.2rem !important;
  /* min-width: 80%; */
  width: 200px;
 }
 
`
export const StyledTextArea = styled.textarea`
resize: none;
  display: flex;
  
/* background-color: blue; */
   height: 100px;
   width: 100% !important;
 border-radius: 5px;
  border: 1px solid #ddd;
 /* margin: 0px 0 0px 0; */
   /* padding: 10px;  */
  box-sizing: border-box;

`;

export const ButtonModal = styled.button`
 border-radius:30px;
 border-bottom: 4px;
 background: #006633 !important;
 white-space: nowrap;
 padding: 8px 20px;
 color :#fff;
 font-size: 16px;
 outline: none;
 border: none;
 cursor: pointer;
 margin-top:24px;
 margin-bottom:5px;
 display:inline-flex;
 justify-content: center;
 align-items: center;
 transition: all 0.5s; 
 &:hover {
  color : yellow;
  transition: all 0.3s ease-out;
  background: #fff;
  background: yellow;

 }

@media screen and (max-width: 991) {
 width: 100%;
 
 
}
  
`

export const ChargesWrapper = styled.div` 
/* background:beige;   */
 /* border: 3px dotted red; */
display: flex;
flex-direction: Column;
justify-content:flex-start;
 align-items: center;
 min-width: 350px;
 margin: 0px;
  @media screen and (max-width:768px) {
    /* font-size: 1rem !important; */
   padding: 5px 10px 10px 10px ;
  /* align-items: center; */
  min-width: 80%;
 }
 
`

export const FeeWraper = styled.div` 
display: flex;
flex-direction: row;
justify-content:flex-start;
align-self: center;
padding: 2px 10px 2px 10px;
min-width: 350px;
margin: 0px;
font-size: 1rem !important;
  @media screen and (max-width:768px) {
    font-size: 0.7rem !important;
   padding: 2px 5px;
  min-width: 80%;
 }
 
`



export const NavLogoWrapper = styled.div`
            
color:black;
/* background: yellow;  */
display: flex;
  justify-content:center;
  align-items: center;
  align-content: center;
  /*  min-height:100px;*/
 /*  border-radius: 50%; */
 /* height: 60px; */
 /* height: 95%; */
 position:relative;
 z-index: 6;


  img {
        max-height: 100%;
        height: auto;
        padding: 0px 0px 0px 0px;
    }
 @media screen and (max-width:768px) {
 }

`;

export const BalanceRef = styled.h1`
/* background:cyan; */
    font-weight: 500; 
    color: #000000 ; 
    padding: 0px 0px 0px 10px ;
    text-align: left;
     /* width: 75%; */
     font-size: 1rem !important;
@media screen and (max-width:768px) {
    
    padding: 0px 0px 0px 10px ; 
  color: #000000;  
  font-size: 0.7rem !important;
 
 }
`


export const EmailRef = styled.h1`
    font-weight: 400;    
    font-size: 1rem !important;
    color :black !important;
    padding: 0px 0px 0px 20px ;
    text-align: left;
     width: 75%; 
     align-items: center;  
  
@media screen and (max-width:768px) {
    font-size: 0.7rem !important;
    padding: 0px 0px 0px 10px ;  
  align-items: center;
 }
`


export const TimeRef = styled.h1`
    font-weight: 300;    
    font-size: 0.8rem !important;
    color :black !important;
    padding: 5px 0px 0px 10px ;
    text-align: left;
@media screen and (max-width:768px) {
    font-size: 0.8rem !important;
    padding: 2px 0px 0px 10px ;  
 }
`

export const OwnNoteWrapper = styled.div` 
display: flex;
flex-direction: column;
justify-content:flex-start;
align-self: left;
/* background:red; */
 /* background:purple; */
/* height: 60px; */
padding: 0px 10px;
min-width: 350px;
 font-size: 0.8rem !important;
  font-weight: 200 !important;        
    color :#006633 !important;
/* margin: 0px; */
/* margin-bottom: 10px; */
/* border-radius: 5px; */
  /* border: 1px solid #063; */
  @media screen and (max-width:768px) {
    font-size: 0.7rem !important;
   padding: 0px 10px;
  /* align-items: center; */
  min-width: 80%;
 }
 
`

export const NoteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* background-color: green ; */
  /* height: 24px; */
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
  /* margin: 0px 0 0px 0; */
  /* padding: 0px; */
  box-sizing: border-box;
`;



export const AddUserButtonWrapper = styled.div`
 color: #ffffff;                      
  /* background: grey; */
  /* background:          beige; */
 
 /* border-bottom: 1px dotted grey; */
/* flex:2; */
  /* height:80px; */
 min-width: 350px;
  display: flex;
  flex-direction: column;
  /* flex-wrap:wrap; */
  align-items: center;
  align-items:center;
  /* justify-content: space-between; */

  padding: 2px 0px 2px 0px;
  /* gap:20px 40px; */
  h1{
    color :red;
  }
  @media screen and (max-width:768px) {
min-width: 80%;
height:51px;
/* gap:10px 40px; */

 }

`;

export const StyledAddUserButton = styled.button`
   display: flex;
  flex-shrink: 0;
  align-items:center;
  justify-content:center;
  background-color:${props => (props.bgCol || '#063')} ;
  color: #fff;
  font-size: 1.2rem;
  border: 0;
  border-radius: 10px;
  /* height: 50%; */
  width:70%;
  padding: 10px;
  /* margin: 5px 5px; */
  cursor: pointer;
  box-sizing: border-box;
  /* width: 30px !important; */
 /*  border: 2px solid purple;  */
 @media screen and (max-width:768px) {
  font-size: 0.8rem;
/* gap:10px 40px; */

 }

`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background:red; */
  /* height: 100%; */
  padding: 2px 10px 2px 10px ;
  min-width:350px;
 
  
  @media screen and (max-width:768px) {
  /* max-width: 100%; */
  /* flex-basis: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* justify-content: space-between; */
    /* background:DarkOrange; */
  /* width:100%;  */
   padding: 2px 10px 2px 10px ;
  min-width: 80%;
 
 }

`;

export const StyledForm = styled.form`
  color: red;
  /* background: beige; */
  display: flex;
  flex-direction: column;
  align-items: left;
  /* justify-content: space-between !important; */
  /* height: 100%; */
  width: 100%;
   padding: 5px 10px 20px 10px ;
  /* max-width: 700px; */
  border-radius: 10px;
  /* box-sizing: border-box; */
  box-shadow: 00px 0px 20px 0px rgba(0, 102, 51, 0.2);
  overflow-y: auto;

  p, label{
    color:black
  }
@media screen and (max-width:768px) {
  /* max-width: 100%; */
 /*  flex-basis: 100%; */
  /* display: flex;  */
/*   display: flex;
  flex-direction: column;
  justify-content: space-between !important; */
  /* height: 100%; */
  /* border-radius: 0px; */
   padding: 5px 10px 2px 10px ;
   /* min-width:90%; */
 }
`;

export const ButtonWrapper = styled.div`
/* border: 2px solid green;  */
  display: flex;
  flex:1;
   text-align:center; 
   justify-content:center; 
   align-items:space-between;
    flex-shrink: 0;
width: 100% !important;

@media screen and (max-width:768px) {
  

 }


`


export const StyledButton = styled.button`
   display: flex;
  flex-shrink: 0;
  align-items:center;
  justify-content:center;
  background-color: #006633;
  color: #fff;
  font-size: 0.7rem;
  border: 0;
  border-radius: 5px;
  height: 30px;
  width:70%;
  padding: 0 10px;
  cursor: pointer;
  box-sizing: border-box;
  width: 85% !important;; 
 /*  border: 2px solid purple;  */

`;

export const StyledError = styled.div`
  color: red;
  font-weight: 800;
  margin: 0 0 40px 0;
  align-items: center;
  @media screen and (max-width:768px) {
    margin: 0 0 20px 0;
 }

`;

export const Img = styled.img`
padding: 8px;
border: 0;
width: 40px;
height: 40px;
vertical-align: middle;
justify-content:center;
display: inline-block;
margin-left: -40px; 
`

export const ModalDialogueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;
  width:350px;
  color: ${props => props.color || "#063"} !important;  
  font-size: 0.9rem !important;
   strong {
      font-size: 1.5rem !important;
    font-weight: 500 !important;
    color: ${props => props.colortwo || "#000"} !important;
    }  
`;

export const NavLogoWrapper2 = styled.div`
color: black;   
    display: flex;
    flex-direction: column;   
    justify-content: center;    
    align-items: center;
    position: relative;    
    z-index: 6;
  img {
        max-height: 100%;
        height: auto;
        padding: 5px 0px 5px 0px;
    }
 @media screen and (max-width:768px) {
 }

`;

export const FeeWraper2 = styled.div`
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-self: center;
    padding: 2px 10px;
    width: 100%;
    margin: 0px;
    font-size: 1rem !important;
`;

export const EmailRef2 = styled.h1`
  font-weight: 400;
    padding: 0px 5px;
    text-align: left;
    align-items: center;
    font-size: 1rem !important;
    color: black !important;
`;