import styled from "styled-components";
/* import { FaMagento } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Container, Button } from '../../globalStyles'; */


export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* background-color: green ; */
  padding: 10px 5px 5px 5px;
  min-width: 300px;
  font-size: 0.9rem !important ;
  font-weight: 500 !important;
  color: #ffffff;
`;
export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: azure ;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 0px 0px 10px 0px;
  padding: 0px;
  box-sizing: border-box; 
  width:100%;
  
`;

export const SelectMr = styled.select`
color: #000;
  display: flex;
  flex-direction: column;
   align-items: flex-start;
    width: 100%;
    background-color: azure;
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgb(221, 221, 221);
    margin: 0px 0px 20px;
    padding: 10px;
    box-sizing: border-box;  

    option{
    color: black;
    background: white;
    display: flex;
    }
`;

export const InfoSec = styled.div`
color: #fff;
padding: 50px 0;
background: ${props => (props.gradcolstar || '#063')} ;

background: #063; /* fallback for old browsers */
background: -webkit-linear-gradient(to right, ${props => (props.gradcolstar || '#ee0979')} , ${props => (props.gradcolend || '#063')});  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient( to right, ${props => (props.gradcolstar || '#063')} ,${props => (props.gradcolmiddle || '#063')},${props => (props.gradcolend || '#063') } ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                        
                                       


@media screen and (max-width:768px) {
  padding: 30px 0;

 }

`;

export const InfoRow = styled.div`
 display: flex;
 margin: 0 -15px -15px -15px;
 flex-wrap: wrap;
 align-items: center;
 flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};



`;

export const InfoColumn = styled.div`
 margin-bottom: 15px;
 padding-right: 15px;
 padding-left: 15px;
 flex: 1;
 max-width: 100%;
 flex-basis: 50%;

 @media screen and (max-width:768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;

 }

`;

export const TextWrapper = styled.div`

 max-width: 540px;
 padding-top: 0;
 padding-bottom: 60px;

 @media  screen and (max-width:768) {
  padding-bottom: 65px;
 

 }
`
export const TopLine = styled.div`

 color: ${ ({ lightTopLine }) => (lightTopLine ? '#ffffff' : '#ffffff')};
 font-size: 18px;
 line-height: 16px;
 letter-spacing: 1.4px;
 margin-bottom: 16px;

 
`
export const TopLine2 = styled.div`

 color: ${({ lightTopLine }) => (lightTopLine ? '#ffffff' : '#ffffff')};
 font-size: 18px;
 line-height: 16px;
 letter-spacing: 1.4px;
 margin-bottom: 16px;




 
`



export const Heading = styled.h1`
 margin-bottom: 24px;
 font-size: 28px;
 line-height: 1.1;
 //color:black;
 //color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};
 color:#ffffff;
 letter-spacing: 1.4px;

 @media only screen and (max-width:600px) {
 
  font-size: 20px;


 }
 
`

export const Subtitle = styled.p`
 max-width: 440px;
 margin-bottom: 35px;

 line-height: 24px;
 color: ${({ lightTextDesc }) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};
  
 
`
export const Subtitle2 = styled.p`
 max-width: 440px;
 margin-bottom: 35px;
 padding:10px 100px 10px 10px,
 font-size: 18px;
 line-height: 24px;
 color: ${({ lightTextDesc }) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};
  margin-left: 16px;
 
`

export const ImgWrapper = styled.div`
max-width: 500px;
display: flex;
justify-content: ${({ ...start}) => (start ? 'flex-start' : 'flex-start')};


`

export const Img = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
justify-content:center;
display: inline-block;
max-height: 500px;
margin-left: auto;
margin-right: auto;


`



export const Img2 = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
justify-content:center;
display: inline-block;

`
export const IconWrapper2 = styled.div`
max-width: 90px;
display: inline-flex;
align-items:center;
padding: 0 0px;
margin-right: 10px;
justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')}; 


`

export const NavLogo2 = styled.div`
color:#fff; 
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
display: flex;
font-size: 2rem;
align-items: center;


`

export const HeroBtnWrapper = styled.div`
margin-top: 32px;
display: flex;
flex-direction: column;
align-items: center;
`

export const DzIcon = styled.div`
  margin-left: 8px;
  font-size: 20px;

`