
//deep copy of Arrays
//numbersCopy = JSON.parse(JSON.stringify(nestedNumbers));

export const ConstValues = {
 nf: 10,
 sf: 0.0005,
 sffix: 10,
 chf: 0.01,
	chffix: 10,
	minAmount:50,
};


export const dataObject = {
 mainBg: '#000000',   //black
 mainCol: '#ffffff',  //white

 leftmainBg: '#006633',  //green
 leftmainCol: '#ffffff', //white

 rightmainBg: '#E0FFFF',  //
 rightmainCol: '#fe0b0b',

 rightTopNavBg: '#E0FFFF',
 rightTopNavCol: '#006633',

 rightBg: '#E0FFFF',
 rightCol: '#ffffff',

 topLine: 'Because convinience matter',
 headline: 'Blank text here and here too some shit and more shit ',
 description: ' blank bla bla bla bla bla blank bla bla bla bla bla blank bla bla bla bla bla blank bla bla bla bla bla  ',

 lightBg: false,
 primary: true,
 imgStart: '',
 lightText: true,
 lightTopLine: true,
 lightTextDesc: true,
 
 
 
 buttonlabel: 'Paydoroz',
 buttonlabel2: 'Get Started',
 buttonlink: '/SignUp',
 buttonlink2: '/SignUp',

 //img: require('../Assets/bank.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#063',
 gradcolmiddle: '#063',
 gradcolend: '#063',
};


export const signInObj = {
 lightBg: false,
 primary: true,
 imgStart: 'row',
 lightText: false,
 lightTopLine: false,
 lightTextDesc: false,
 topLine: 'Paydoroz an excel virtual bank',
 headline: 'Put Paydoroz in your Wallet',
 description: ' TEXT: HOW TO GET PAYDOROZ  where from and why  Paydoroz are keyeasy, everywhere, locate a partners',
 buttonlabel: 'Sign Up',

 buttonlink: '/SignUp',
 buttonlink2: '/SignUp',
 /* img: require('../Assets/undercon.png'), */
 alt: 'jamImage',
 start: '',
 gradcolstar: '#b8f7fc',
 gradcolmiddle: '#26ebfc',
 gradcolend: '#b8f7fc',
 buttonlabel2: 'How Much ?',
};


export const userTest = {
 "uid": "rdIbyYSZfPhgmT4U7K4irT0Yv9O2",
 "email": "d.chekroud@gmail.com",
 "emailVerified": false,
 "isAnonymous": false,
 "providerData": [
  {
   "providerId": "password",
   "uid": "sarah@sarah.tit",
   "displayName": null,
   "email": "sarah@sarah.tit",
   "phoneNumber": null,
   "photoURL": null
  }
 ],
 "stsTokenManager": {
  "refreshToken": "AIwUaOkftghP4EbYBY6jHZUukrMKHTxbwuqcyjHw6iB95QBI8vi1l-Dl8XXzk4qVBDp_6qR9Je7B-V-oyj0Rn_ubo5qLxvi8S2nUEowR_aeaxDsw2QIRhp-hbe-RFC1FL7x-GdGrIXFzowy2pdmdzy2Y79tRAr4oRQQnfMSWqn4kf5Wl41z-STqDrXN-MVWd-hGdu2554WOQ",
  "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImY0ZTc2NDk3ZGE3Y2ZhOWNjMDkwZDcwZTIyNDQ2YTc0YjVjNTBhYTkiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZHpkaW5hci1jMDJmMSIsImF1ZCI6ImR6ZGluYXItYzAyZjEiLCJhdXRoX3RpbWUiOjE2NTQyNzgwNTEsInVzZXJfaWQiOiJyZElieVlTWmZQaGdtVDRVN0s0aXJUMFl2OU8yIiwic3ViIjoicmRJYnlZU1pmUGhnbVQ0VTdLNGlyVDBZdjlPMiIsImlhdCI6MTY1NDI3ODA1MSwiZXhwIjoxNjU0MjgxNjUxLCJlbWFpbCI6InNhcmFoQHNhcmFoLnRpdCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJzYXJhaEBzYXJhaC50aXQiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.CTXlo0-cRPMgTaMywC7WPKFx6OKkcsqZxrpE1TSHKYaE8-wsyAmmEMMXcJDeDJHVxn3FwpSYsYFB4qtlm4RLKwfPm_CB65G2kMdnTOKl34Qvlx24lNfDywMBvE4avvBWWzOnqUK2_5E1ar0XmX1bbuYHgP29_vuprbGSsLci3i3-JF8RP8eAc8xdq83GuJAtwdPYCHY7l-EVMxkA7v45QH0eeqXg5GbuZYEuJ3PUPSkoMmDXYXPRkIxdm3XGiq0nVwAQJKl4KDy5Q537WnCZjBxw5E--Vk5zp8oqaBH9X5A7t1Ia_hP35EMhXguiT8RyJJ9eBUq311jX_5r3XEQpHw",
  "expirationTime": 1654281652257
 },
 "createdAt": "1653773972075",
 "lastLoginAt": "1654278051801",
 "apiKey": "AIzaSyAVOiQ97q2nuJoRmpdKydyduHmqBTwhcYQ",
 "appName": "[DEFAULT]"

};


export const userDataRow = 
{
		"balance": 0,
		"mrorms": "",
		"fname": "",
		"lname": "",
		"dob": "",
		"addressLine": "",
		"baladiya": "",
		"daira": "",				
		"willaya": "",
		"email": "",
		"answer": "",					 
		"pinnumber": "",						
		"question": "",						 
		"phone": "",							
		"password": "",							 
		"secretanswer": "",								
		"acnumber": "",								 
		"useriddoc": "url to scaned document",									
		"useravatar": "url to user avatar image",									 
		"active": false,
		"emailverified": false,
		"phoneverified": false,
		"idverified": true,
		"transactions": [
			{
			"type":"Balance Brought Forward",
			"from": '',
			"to":'',
			"id": "",
			"amount": 0,
			"createdAt": "",
			"ref": "Account Start"
			}
		]
};

export const userTranRow = {

 "createdAt": "",
 "from": "",
	"to": "",
	"type": "Transfert",	
	"id": "",
	"amount":0,
	"nf": 0,	
	"sf": 0,	
	"chf": 0,	
	"ref": "Account Start"

};


export const typeOfTransactions =
	[
		"Transfer:Send",
		"Transfer:Receive",
		"Trade:Buy", 									
		"Trade:Sell", 									
		"Pay:Send",					
		"Pay:Receive",					
		"Flexy:Pay",
		"Flexy:Receive",	
		"Balance Brought Forward",	
		"User Transfer",	
		"Partner Trade",	
		"Partner Pay",	

	]
;





// function copy(aObject) {
// 	// Prevent undefined objects
// 	// if (!aObject) return aObject;

// 	let bObject = Array.isArray(aObject) ? [] : {};

// 	let value;
// 	for (const key in aObject) {

// 		// Prevent self-references to parent object
// 		// if (Object.is(aObject[key], aObject)) continue;

// 		value = aObject[key];

// 		bObject[key] = (typeof value === "object") ? copy(value) : value;
// 	}

// 	return bObject;
// }



// let userList = [];
// for (var i = 0; i < transactions.length; i++) {
// 	userList[i] = transactions[i].ref;
// }


//let copy = JSON.parse(JSON.stringify(object to copy));

export const jezzyEmail = "pdz.djezzy@gmail.com";
export const mobilisEmail = "pdz.mobilis@gmail.com";
export const ooredooEmail = "pdz.ooredoo@gmail.com";


export const jezzyMenu = [
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},

	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
];

export const mobilisMenu = [
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},

	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
];


export const ooredooMenu = [
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},

	{
		id: "1",
		value: 100,
		name: "offer100",
		imgName: require('../../Assets/PD_logo_green.svg'),
	},
];