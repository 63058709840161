// import { startAfter } from 'firebase/firestore';
import React, { useState }  from 'react';
//import { FaBars, FaBlackberry, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
//import { Button } from '../../globalStyles';
import {
  TranWraper, TranRowWraper, TranRowImgWraper, RefWraper,ImgT,
  // EmailRefTo,
  EmailRefFrom, BalanceRef, TimeRef,

} from './Transaction.style'

//import { ReactComponent as Logo } from '../../Assets/paydoroz_logo.svg';

import ModAlertTransaction from './ModAlerTransaction';
import {
  // logoSVG_red, logoSVG_White,
  logoSVG_green, logoSVG_yellow, logoSVG_White,
  logo_TrSend, logo_TrRec, logo_TdSend,
  logo_TdRec, logo_PySend, logo_PyRec, logo_FlJez,
  logo_FlMob, logo_FlOrd
} from "../../Assets/Index";
// import { logoSVG_red } from "../../Assets/PD_logo_red.svg";

const Transaction = ({ selectedTransac }) => {

  const [showModAlertTransaction, setShowModAlertTransaction] = useState(false);
 

  
 /* let formatAmount = new Intl.NumberFormat().format;
 console.log('tranAmount', tranAmount, '  formated :', formatAmount); */



 
  

  // const getAmountColorBg = (typeV) => {
  //   // console.log(number);   
  //   switch (typeV) {
  //     case "Transfer:Send":
  //       return "#FFEDED";
    
  //     case "Transfer:Receive":
  //       return "#D3F9D0";
      
  //     default:
  //       return "#ffffff";
  //   }
  // };//============ getAmountColor


  const getTranLabel = (tranX) => {
    let txLabel = {
      fromTo: '',
      other: '',
      money: 0,
      bgCol: '',
      bCol: '',
      time: '',      
      imageName:''
    }
    // console.log(number);   
    switch (tranX.type) {
      case "Transfer:Send":
        txLabel.fromTo= 'To';
        txLabel.other= tranX.to;
        txLabel.money = -1 * tranX.amount;      
        txLabel.bgCol = '#FFEDED';      //pink
        txLabel.bCol = "#FE0B0B";       //Red
        txLabel.time = tranX.createdAt;  
        // txLabel.imageName = "../../Assets/PD_logo_red.svg"; 
        txLabel.imageName = logo_TrSend; 
        
        return txLabel
     
      case "Transfer:Receive":
        txLabel.fromTo = 'From';
        txLabel.other = tranX.from;
        txLabel.money = Number(tranX.amount - tranX.nf - tranX.sf);
        txLabel.bgCol = "#D3F9D0";        //lime green
        txLabel.bCol = "#006633";         // green
        txLabel.time = tranX.createdAt;
        txLabel.imageName = logo_TrRec; 
        return txLabel
     
      case "Trade:Buy":    // not sorted yet
        txLabel.fromTo= 'From';
        txLabel.other= tranX.from;
        txLabel.money = Number(tranX.amount - tranX.nf - tranX.sf-tranX.chf);  
        txLabel.bgCol = "#ebc6eb";//light purple
        txLabel.bCol = "#006633"; // green
        txLabel.time = tranX.createdAt; 
        txLabel.imageName = logo_TdRec;
        return txLabel

      case "Trade:Sell":   // not sorted yet
        txLabel.fromTo = 'To';
        txLabel.other = tranX.to;
        txLabel.money = -1 * Number(tranX.amount);
        txLabel.bgCol = '#d17bd1';////dark purple
        txLabel.bCol = "#FE0B0B"; //Red
        txLabel.time = tranX.createdAt;
        txLabel.imageName =  logo_TdSend;
        return txLabel
   
      case "Pay:Send":    // not sorted yet
        txLabel.fromTo= 'To';
        txLabel.other= tranX.to;
        txLabel.money = -1 * tranX.amount;      
        txLabel.bgCol = '#ffa64d'; //dark orange
        txLabel.bCol = "#FE0B0B";
        txLabel.time = tranX.createdAt;    
        txLabel.imageName = logo_PySend;
        return txLabel

      case "Pay:Receive":   // not sorted yet
        txLabel.fromTo = 'From';
        txLabel.other = tranX.from;
        txLabel.money = Number(tranX.amount - tranX.nf - tranX.sf);
        txLabel.bgCol = "#ffd9b3";//light orange 
        txLabel.bCol = "#006633";
        txLabel.time = tranX.createdAt;
        txLabel.imageName = logo_PyRec;
        return txLabel
   
      case "Flexy:Pay":   // not sorted yet
        txLabel.fromTo = 'From';
        txLabel.other = tranX.to;
        txLabel.money = -1 * Number(tranX.amount + tranX.sf);
        txLabel.bgCol = "#FFFFE0"; //pink
        txLabel.bCol = "#FE0B0B";
        txLabel.time = tranX.createdAt;        

        switch (tranX.to) {
          case "pdz.djezzy@gmail.com":
            txLabel.imageName = logo_FlJez ;
            break;        
          case "pdz.mobilis@gmail.com":
            txLabel.imageName = logo_FlMob;
            break;        
          case "pdz.ooredoo@gmail.com":
            txLabel.imageName = logo_FlOrd;
            break;        
          default:
            txLabel.imageName = logoSVG_White;
            break;
        }



        return txLabel
      
      case "Flexy:Receive":   // not sorted yet
        txLabel.fromTo = 'From';
        txLabel.other = tranX.from;
        txLabel.money = Number(tranX.amount);
        txLabel.bgCol = "#DDA0DD"; //pink
        txLabel.bCol = "#006633";
        txLabel.time = tranX.createdAt;
        txLabel.imageName = logoSVG_White;
        return txLabel
 
      case "Balance Brought Forward":
        txLabel.fromTo = 'From';
        txLabel.other = "Paydoroz system";
        txLabel.money = tranX.amount;
        txLabel.bgCol = '#FFFFFF';
        txLabel.bCol = "#000000";
        txLabel.time = tranX.createdAt;
        txLabel.imageName = logoSVG_green;
        return txLabel

      
      default:
        txLabel.fromTo = 'To';
        txLabel.other = tranX.to;
        txLabel.money = -1 * tranX.amount;
        txLabel.bgCol = '#FFEDED';
        txLabel.bCol = "#FE0B0B";
        txLabel.time = tranX.createdAt;
        txLabel.imageName = logoSVG_yellow;
        return txLabel
 
    }
  };//============ getTranLabel




  // let amountColor = getAmountColor(selectedTransac.amount);
  // let bgColorMy = getAmountColorBg(selectedTransac.type);
  let tLabel = getTranLabel(selectedTransac);
//  let fromToLabel=get

  const ShowTranDetails =() => {
    console.log('Alert from Transaction  :', selectedTransac.type);
    setShowModAlertTransaction(true);
  };//============ ShowTranDetails


  // 
 return ( 
  <>
     <IconContext.Provider value={{ color: '#fff' }}>
       
       <TranWraper onClick={ShowTranDetails} color={'#e6fff3'}>
       {/* <TranWraper onClick={ShowTranDetails} bgColor={tLabel.bgCol}> */}
      
         
         {/* {console.log('tLabel.imageName  '.tLabel.imageName)} */}
        <TranRowImgWraper>  
           {/* <ImgT src={`${tLabel.imageName}`} alt="logo" />  */}
           <ImgT src={tLabel.imageName} alt="logo" /> 
         </TranRowImgWraper> 
         
        <TranRowWraper>           
          <RefWraper>
            {/* {(selectedTransac.amount <= 0) ? <EmailRefTo>To {selectedTransac.to}</EmailRefTo> :
             <EmailRefFrom>From {selectedTransac.from}</EmailRefFrom>
            } */}
           
              <EmailRefFrom>{tLabel.other}</EmailRefFrom>
              {/* <EmailRefFrom>{tLabel.fromTo} {tLabel.other}</EmailRefFrom> */}
           
              <BalanceRef balanceColor={tLabel.bCol}>
             {Intl.NumberFormat().format(tLabel.money)}
              </BalanceRef>
          </RefWraper>
          <TimeRef>  {selectedTransac.createdAt} </TimeRef>

         </TranRowWraper>
       
       </TranWraper>
       
       <ModAlertTransaction showModal={showModAlertTransaction} setShowModal={setShowModAlertTransaction} selectedTransacP={selectedTransac} />


   </IconContext.Provider> 
  </>
 )
}

export default Transaction;