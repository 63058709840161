export const ConstValues = {
  nf:10,
  sf: 0.01,
  sffix:10,
  chf: 0.01,
  chffix:10,
};



export const homeObjOne = {
 lightBg: false,
 primary: true,
 imgStart: '',
 lightText: true,
 lightTopLine: true,
 lightTextDesc: true,
  topLine: `1H_Top_Line`,
  headline: '1H_head_Line',
  description: `1H_description`, //' Makeing your money work for you in ways you never thought its possible ',
 
 buttonlabel: 'Paydoroz',
 buttonlabel2: '1H_Sign_Up',
  isbutton2: true,
 buttonlink: '/SignUp',
 buttonlink2: '/SignUp',

 img: require('../Assets/bank.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#063',
 gradcolmiddle:'#063',
 gradcolend: '#063',
 
};
 

export const homeObjTwo = {
 lightBg: false,
 primary: true,
 imgStart: 'row',
 lightText: false,
 lightTopLine: false,
 lightTextDesc: false,
 topLine: 'Paydoroz your virtual Asset',
 headline: 'Load your wallet with Paydoroz at one of the many partners outlets',
 description: 'Once in your wallet, use Paydoroz to send money an make payments',
 buttonlabel: 'Trade: BUY',
 buttonlabel2: 'Find out more',
 buttonlink: '/SignUcleap',
 buttonlink2: '/Trade',
 img: require('../Assets/transfers.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#d4fad3',
  gradcolmiddle: '#d4fad3',
  gradcolend: '#d4fad3',
  /* gradcolstar: '#d4fad3',
  gradcolmiddle: '#89ed71',
  gradcolend: '#dffbd5', */
};

export const homeObjThree = {
 lightBg: true,
 primary: true,
 imgStart: '',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: false,
 topLine: 'Paydoroz convert to cash at will',
  headline: 'Guaranteed exchange of your Paydoroz for cash hasle free, local & convenient',
 description: ' Guaranteed exchange of paydoroz for cash at will at one of our many partner sites all over the country and frorm our head office easy',
 buttonlabel: 'Trade: SELL',
 buttonlabel2: 'Find out more',
 buttonlink: '/SignUp',
 buttonlink2: '/Trade',
 img: require('../Assets/transfers.png'),
 alt: 'jamImage',
 start: '',
  gradcolstar: '#c5ffff',
  gradcolmiddle: '#c5ffff',
  gradcolend: '#c5ffff',
  /* gradcolstar: '#e0f5f6',
  gradcolmiddle: '#58fcfe',
  gradcolend: '#c5ffff', */

};

export const homeObjFour = {
 lightBg: false,
 primary: true,
 imgStart: 'row',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: true,
 topLine: 'Convinience made simple',
 headline: 'Fast, efficient, secure in real time transfers ',
 description: 'Anytime , form anywhere to everywhere hassle free, transparent, safe , saves time , very cost effective using web or mobile app  ',
 buttonlabel: 'Transfer : SEND',
  buttonlabel2: 'Find out more',
 buttonlink: '/SignUp',
 buttonlink2: '/Transfer',
 img: require('../Assets/transfers_send.png'),
 alt: 'jamImage',
 start: '',
  gradcolstar: '#d4fad3',
  gradcolmiddle: '#d4fad3',
  gradcolend: '#d4fad3',

};

export const homeObjFive = {
 lightBg: false,
 primary: true,
 imgStart: '',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: true,
 topLine: 'Paydoroz can be sent to your wallet ',
 headline: 'Receive Paydoroz in Real time  from other Paydoroz members',
 description: ' Need to cash in your Paydoroz, No problems, our partners are here to help ',
 buttonlabel: 'Transfer : RECEIVE',
  buttonlabel2: 'Find out more',
  isButton2:true,
 buttonlink: '/SignUp',
 buttonlink2: '/Transfer',
 img: require('../Assets/pay.png'),
 alt: 'jamImage',
 start: '',
  gradcolstar: '#58fcfe',
 gradcolmiddle: '#58fcfe',
  gradcolend: '#58fcfe',

};

export const homeObjSix = {
 lightBg: false,
 primary: true,
 imgStart: 'row',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: true,
 topLine: 'Paydoroz, making life simpler',
 headline: ' A modern way of paying for your goods ',
 description: 'Pay for goods at participating outlets, locally , at a distance, over the phone and on the web easy, simple, efficient and affordable',
 buttonlabel: 'Pay',
  buttonlabel2: 'Find out more',
 buttonlink: '/SignUp',
 buttonlink2: '/Pay',

 img: require('../Assets/pay.png'),
 alt: 'jamImage',
 start: '',
  gradcolstar: '#89ed71',
 gradcolmiddle: '#89ed71',
  gradcolend: '#89ed71',

};

export const homeObjSeven = {
 lightBg: false,
 primary: true,
 imgStart: '',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: true,
 topLine: 'Paydoroz - for your convinience',
 headline: ' With Paydoroz Flexy has never been more flexible',
 description: 'Top up your phone from home , on the move anytime no more waiting for a shops to open ',
 buttonlabel: 'Flexy',
  buttonlabel2: 'Find out more',
 buttonlink: '/Flexy',
 buttonlink2: '/Flexy',

 img: require('../Assets/flexy.png'),
 alt: 'jamImage',
 start: '',
  gradcolstar: '#c5ffff',
  gradcolmiddle: '#c5ffff',
 gradcolend: '#c5ffff',

};

export const homeObjlogo = {
 imglogo: require('../Assets/paydoroz_logo.svg'),
 alt: 'jamImageLogo',
 start: ''
};

export const buttonLogo = {
 imglogo: require('../Assets/paydoroz_logo.svg'),
 alt: 'jamLogobutton',
 start: ''
};



/* ********************************** trade ******************** */

export const tradeObjOne = {
 lightBg: false,
 primary: true,
 imgStart: '',
 lightText: true,
 lightTopLine: true,
 lightTextDesc: true,
  topLine: `1Tr_Top_Line`,
  headline: '1Tr_head_Line',
  description: `1Tr_description`, //' Makeing your money work for you in ways you never thought its possible ',

 buttonlabel: 'Trade : BUY or SELL',
  buttonlabel2: '1Tr_Sign_Up',
 isbutton2:false,
 buttonlink: '/Trade',
 buttonlink2: '/Trade',
 img: require('../Assets/cash_partner.webp'),
 alt: 'jamImage',
 start: '',
  gradcolstar: '#063',
  gradcolmiddle: '#063',
  gradcolend: '#063',
 
};


export const tradeObjTwo = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: `2Tr_Top_Line`,
  headline: '2Tr_head_Line',
  description: `2Tr_description`, 

  buttonlabel: 'Trade: BUY',
  buttonlabel2: '2Tr_Sign_Up',
  isbutton2: true,
  buttonlink: '/SignUp',
  buttonlink2: '/Partners',
  img: require('../Assets/trade.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#d4fad3',
  gradcolmiddle: '#d4fad3',
  gradcolend: '#d4fad3',
  /* gradcolstar: '#d4fad3',
  gradcolmiddle: '#89ed71',
  gradcolend: '#dffbd5', */
 
};

export const tradeObjThree = {
  lightBg: true,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: `3Tr_Top_Line`,
  headline: '3Tr_head_Line',
  description: `3Tr_description`, 

  buttonlabel: 'Trade: SELL',
  buttonlabel2: '3Tr_Sign_Up',
  isbutton2: true,
  buttonlink: '/SignUp',
  buttonlink2: '/Partners',
  img: require('../Assets/Trade_sell.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#c5ffff',
  gradcolmiddle: '#c5ffff',
  gradcolend: '#c5ffff',
  /* gradcolstar: '#e0f5f6',
  gradcolmiddle: '#58fcfe',
  gradcolend: '#c5ffff', */
 
};
//******************************************** */
export const partnersObjOne = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Because convinience matter',
  headline: 'Find your local partner here ',
  description: ' Select your wilaya , daira and Baladiya ',

  buttonlabel: 'Find Your Local Partner here',
  buttonlabel2: 'Nothing here',
  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',

  img: require('../Assets/bank.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#063',
  gradcolmiddle: '#063',
  gradcolend: '#063',

};

/* ********************************** transfer ******************** */
export const transferObjOne = {
 lightBg: false,
 primary: true,
 imgStart: '',
 lightText: true,
 lightTopLine: true,
 lightTextDesc: true,
  topLine: `1Ts_Top_Line`,
  headline: '1Ts_head_Line',
  description: `1Ts_description`, //' Makeing your money work for you in ways you never thought its possible ',
 
  buttonlabel: 'Transfer: SEND or RECEIVE ',  
  buttonlabel2: '1Ts_Sign_Up',
  isbutton2: false,
  buttonlink: '/SignUp',
   buttonlink2: '/SignUp',

  img: require('../Assets/transfers_send.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#063',
 gradcolmiddle: '#063',
 gradcolend: '#063',

};


export const transferObjTwo = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: `2Ts_Top_Line`,
  headline: '2Ts_head_Line',
  description: `2Ts_description`, buttonlabel: 'Transfer : SEND',
  buttonlabel2: '2Ts_Sign_Up',
  isbutton2: false,
  buttonlink: '/SignUp',
  buttonlink2: '/Transfer',
  img: require('../Assets/transfers_send.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#d4fad3',
  gradcolmiddle: '#d4fad3',
  gradcolend: '#d4fad3',
 
};

export const transferObjThree = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: `3Ts_Top_Line`,
  headline: '3Ts_head_Line',
  description: `3Ts_description`,
  buttonlabel: 'Transfer : RECEIVE',
  buttonlabel2: '3Ts_Sign_Up',
  isbutton2: false,
  buttonlink: '/SignUp',
  buttonlink2: '/Transfer',
  img: require('../Assets/pay.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#58fcfe',
  gradcolmiddle: '#58fcfe',
  gradcolend: '#58fcfe',

};
/* 
export const transferObjFour = { 
 topLine: 'Sending Paydoroz is a brease',
 headline: 'Fast efficient transfers ',
 description: ' anytime , form anywhere to everywhere hassle free, transparent, safe , saves time , very cost effective web or mobile  ',
 topLine: 'Receive Paydoroz is easy ',
 headline: ' Receive Paydoroz in Your Wallet anytime',
 description: ' Need to cash in your Paydoroz, No problems, our partners are here to help ',
 buttonlabel: 'Review Costs',

 topLine: 'Trade your paydoroz for cash everywhere',
 headline: ' A modern way of paying for your goods ',
 description: 'Pay for goods at participating outlets, locally , at a distance, over the phone and on the web easy, simple, efficient and affordable',

topLine: 'Trade your paydoroz for cash everywhere',
 headline: ' Flexy has never been more flexible',
 description: 'Top up your phone from home , on the move anytime no more waiting for a shops to open ',

};



/* ********************************** signUp ******************** */
export const signUpObjOne = {
 lightBg: false,
 primary: true,
 imgStart: '',
 lightText: true,
 lightTopLine: true,
 lightTextDesc: true,
 topLine: 'An excellent virtual bank',
 headline: ' This page is for registration  ',
 description: ' Make your money work for you in ways you never thought its possible ',
 buttonlabel: 'This is sign Up Page',

 buttonlink: '/SignUp',
 buttonlink2: '/SignUp',

 img: require('../Assets/undercon.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#063',
 gradcolmiddle: '#063',
 gradcolend: '#063',
};


export const signUpObjTwo = {
 lightBg: false,
 primary: true,
 imgStart: 'row',
 lightText: false,
 lightTopLine: false,
 lightTextDesc: false,
 topLine: 'Paydoroz an excel virtual bank',
 headline: 'Put Paydoroz in your Wallet',
 description: ' TEXT: HOW TO GET PAYDOROZ  where from and why  Paydoroz are keyeasy, everywhere, locate a partners',
 buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
   buttonlink2: '/SignUp',
 img: require('../Assets/undercon.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#d4fad3',
 gradcolmiddle: '#89ed71',
 gradcolend: '#dffbd5',
 buttonlabel2: 'Trade: BUY',
};

export const signUpObjThree = {
 lightBg: true,
 primary: true,
 imgStart: '',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: false,
 topLine: 'Selling Paydoroz is easy',
 headline: ' Paydoroz for cash at will',
 description: ' Guaranteed exchange of paydoroz for cash at will at many partner sites all over the country and form our head office easy, hasle free, local',
 buttonlabel: 'Sign Up',
 buttonlink: '/SignUp',
 buttonlink2: '/SignUp',
 img: require('../Assets/undercon.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#e0f5f6',
 gradcolmiddle: '#58fcfe',
 gradcolend: '#c5ffff',
 buttonlabel2: 'Trade: SELL',
};

export const signUpObjFour = {
 lightBg: false,
 primary: true,
 imgStart: 'row',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: true,
 topLine: 'Sending Paydoroz is a brease',
 headline: 'Fast efficient signUps ',
 description: ' anytime , form anywhere to everywhere hassle free, transparent, safe , saves time , very cost effective web or mobile  ',
 buttonlabel: 'Sign Up',
 buttonlink: '/SignUp',
 buttonlink2: '/SignUp',
 img: require('../Assets/undercon.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#d4fad3',
 gradcolmiddle: '#89ed71',
 gradcolend: '#dffbd5',
 buttonlabel2: 'signUp: send',
};

export const signUpObjFive = {
 lightBg: false,
 primary: true,
 imgStart: '',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: true,
 topLine: 'Receive Paydoroz is easy ',
 headline: ' Receive Paydoroz in Your Wallet anytime',
 description: ' Need to cash in your Paydoroz, No problems, our partners are here to help ',
 buttonlabel: 'Sign Up',
 buttonlink: '/SignUp',
 buttonlink2: '/SignUp',
 img: require('../Assets/undercon.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#e0f5f6',
 gradcolmiddle: '#58fcfe',
 gradcolend: '#c5ffff',
 buttonlabel2: 'Receive',
};

export const signUpObjSix = {
 lightBg: false,
 primary: true,
 imgStart: 'row',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: true,
 topLine: 'Trade your paydoroz for cash everywhere',
 headline: ' A modern way of paying for your goods ',
 description: 'Pay for goods at participating outlets, locally , at a distance, over the phone and on the web easy, simple, efficient and affordable',
 buttonlabel: 'Sign Up',
 buttonlink: '/SignUp',
 buttonlink2: '/SignUp',
 img: require('../Assets/undercon.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#d4fad3',
 gradcolmiddle: '#89ed71',
 gradcolend: '#dffbd5',
 buttonlabel2: 'Pay',
};

export const signUpObjSeven = {
 lightBg: false,
 primary: true,
 imgStart: '',
 lightText: false,
 lightTopLine: true,
 lightTextDesc: true,
 topLine: 'Trade your paydoroz for cash everywhere',
 headline: ' Flexy has never been more flexible',
 description: 'Top up your phone from home , on the move anytime no more waiting for a shops to open ',
 buttonlabel: 'Sign Up',


 buttonlink: '/SignUp',
 buttonlink2: '/SignUp',
 img: require('../Assets/undercon.png'),
 alt: 'jamImage',
 start: '',
 gradcolstar: '#e0f5f6',
 gradcolmiddle: '#58fcfe',
 gradcolend: '#c5ffff',
 buttonlabel2: 'Flexy',
};


/* ********************************** Flexy ******************** */

export const flexyObjOne = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Annnnnnnnnn excellent virtual bank',
  headline: ' Trade is when you Exchange Paydoroz for Cash',
  description: ' Make your money work for you in ways you never thought its possible ',
  buttonlabel: 'Flexy Page',
 
  buttonlink: '/Flexy',
  buttonlink2: '/Flexy',
  img: require('../Assets/undercon.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#006633',
  gradcolmiddle: '#006633',
  gradcolend: '#006633',
 
};


export const flexyObjTwo = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: 'Paydoroz an excel virtual bank',
  headline: 'Put Paydoroz in your Wallet',
  description: ' TEXT: HOW TO GET PAYDOROZ  where from and why  Paydoroz are keyeasy, everywhere, locate a partners',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/undercon.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'How Much ?',
};

export const flexyObjThree = {
  lightBg: true,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: 'Selling Paydoroz is easy',
  headline: ' Paydoroz for cash at will',
  description: ' Guaranteed exchange of paydoroz for cash at will at many partner sites all over the country and form our head office easy, hasle free, local',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/transfers.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#f0f0f0',
  gradcolmiddle: '#b1b5b5',
  gradcolend: '#f0f0f0',
  buttonlabel2: 'When ?',
};

export const flexyObjFour = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Sending Paydoroz is a brease',
  headline: 'Fast efficient transfers ',
  description: ' anytime , form anywhere to everywhere hassle free, transparent, safe , saves time , very cost effective web or mobile  ',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',

  img: require('../Assets/transfers_send.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'Is it Safe ?',
};

export const flexyObjFive = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Receive Paydoroz is easy ',
  headline: ' Receive Paydoroz in Your Wallet anytime',
  description: ' Need to cash in your Paydoroz, No problems, our partners are here to help ',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/pay.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#f0f0f0',
  gradcolmiddle: '#b1b5b5',
  gradcolend: '#f0f0f0',
  buttonlabel2: 'Guarantee ?',
};

export const flexyObjSix = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Trade your paydoroz for cash everywhere',
  headline: ' A modern way of paying for your goods ',
  description: 'Pay for goods at participating outlets, locally , at a distance, over the phone and on the web easy, simple, efficient and affordable',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/pay.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'How much does it cost ?',
};

export const flexyObjSeven = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Trade your paydoroz for cash everywhere',
  headline: ' Flexy has never been more flexible',
  description: 'Top up your phone from home , on the move anytime no more waiting for a shops to open ',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/flexy.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#f0f0f0',
  gradcolmiddle: '#b1b5b5',
  gradcolend: '#f0f0f0',
  buttonlabel2: 'add something else here ',
};

/* ********************************** Flexy ******************** */


/* ********************************** Pay ******************** */

export const payObjOne = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'excellent virtual bank',
  headline: ' Trade is when you Exchange Paydoroz for Cash',
  description: ' Make your money work for you in ways you never thought its possible ',
  buttonlabel: 'Pay Page',
  
  buttonlink: '/Pay',
  buttonlink2: '/Pay',
  img: require('../Assets/undercon.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#006633',
  gradcolmiddle: '#006633',
  gradcolend: '#006633',

};


export const payObjTwo = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: 'Paydoroz an excel virtual bank',
  headline: 'Put Paydoroz in your Wallet',
  description: ' TEXT: HOW TO GET PAYDOROZ  where from and why  Paydoroz are keyeasy, everywhere, locate a partners',
  buttonlabel: 'Sign Up',
 
  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/undercon.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'How Much ?',
};

export const payObjThree = {
  lightBg: true,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: 'Selling Paydoroz is easy',
  headline: ' Paydoroz for cash at will',
  description: ' Guaranteed exchange of paydoroz for cash at will at many partner sites all over the country and form our head office easy, hasle free, local',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/transfers.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#f0f0f0',
  gradcolmiddle: '#b1b5b5',
  gradcolend: '#f0f0f0',
  buttonlabel2: 'When ?',
};

export const payObjFour = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Sending Paydoroz is a brease',
  headline: 'Fast efficient transfers ',
  description: ' anytime , form anywhere to everywhere hassle free, transparent, safe , saves time , very cost effective web or mobile  ',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',

  img: require('../Assets/transfers_send.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'Is it Safe ?',
};

export const payObjFive = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Receive Paydoroz is easy ',
  headline: ' Receive Paydoroz in Your Wallet anytime',
  description: ' Need to cash in your Paydoroz, No problems, our partners are here to help ',
  buttonlabel: 'Sign Up',
 
  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/pay.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#f0f0f0',
  gradcolmiddle: '#b1b5b5',
  gradcolend: '#f0f0f0',
  buttonlabel2: 'Guarantee ?',
};

export const payObjSix = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Trade your paydoroz for cash everywhere',
  headline: ' A modern way of paying for your goods ',
  description: 'Pay for goods at participating outlets, locally , at a distance, over the phone and on the web easy, simple, efficient and affordable',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/pay.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'How much does it cost ?',
};

export const payObjSeven = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Trade your paydoroz for cash everywhere',
  headline: ' Flexy has never been more flexible',
  description: 'Top up your phone from home , on the move anytime no more waiting for a shops to open ',
  buttonlabel: 'Sign Up',
 
  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/flexy.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#f0f0f0',
  gradcolmiddle: '#b1b5b5',
  gradcolend: '#f0f0f0',
  buttonlabel2: 'add something else here ',
};

/* ********************************** Pay ******************** */


/* ********************************** Sign IN ******************** */

export const signInObjOne = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Welcome back...',
  headline: 'Sign In Please ',
  description: 'And take advantage of a great service',
  buttonlabel: 'sign in page ',

  buttonlink: '/Pay',
  buttonlink2: '/Pay',
  img: require('../Assets/undercon.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#006633',
  gradcolmiddle: '#006633',
  gradcolend: '#006633',

};


export const signInObjTwo = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: 'Paydoroz an excel virtual bank',
  headline: 'Put Paydoroz in your Wallet',
  description: ' TEXT: HOW TO GET PAYDOROZ  where from and why  Paydoroz are keyeasy, everywhere, locate a partners',
  buttonlabel: 'Sign Up',
 
  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/undercon.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'How Much ?',
};

export const signInObjThree = {
  lightBg: true,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: 'Selling Paydoroz is easy',
  headline: ' Paydoroz for cash at will',
  description: ' Guaranteed exchange of paydoroz for cash at will at many partner sites all over the country and form our head office easy, hasle free, local',
  buttonlabel: 'Sign Up',
 
  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/transfers.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#f0f0f0',
  gradcolmiddle: '#b1b5b5',
  gradcolend: '#f0f0f0',
  buttonlabel2: 'When ?',
};

export const signInObjFour = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Sending Paydoroz is a brease',
  headline: 'Fast efficient transfers ',
  description: ' anytime , form anywhere to everywhere hassle free, transparent, safe , saves time , very cost effective web or mobile  ',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',

  img: require('../Assets/transfers_send.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'Is it Safe ?',
};

export const signInObjFive = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Receive Paydoroz is easy ',
  headline: ' Receive Paydoroz in Your Wallet anytime',
  description: ' Need to cash in your Paydoroz, No problems, our partners are here to help ',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/pay.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#f0f0f0',
  gradcolmiddle: '#b1b5b5',
  gradcolend: '#f0f0f0',
  buttonlabel2: 'Guarantee ?',
};

export const signInObjSix = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Trade your paydoroz for cash everywhere',
  headline: ' A modern way of paying for your goods ',
  description: 'Pay for goods at participating outlets, locally , at a distance, over the phone and on the web easy, simple, efficient and affordable',
  buttonlabel: 'Sign Up',
 
  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/pay.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'How much does it cost ?',
};

export const signInObjSeven = {
  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: false,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Trade your paydoroz for cash everywhere',
  headline: ' Flexy has never been more flexible',
  description: 'Top up your phone from home , on the move anytime no more waiting for a shops to open ',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  img: require('../Assets/flexy.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#f0f0f0',
  gradcolmiddle: '#b1b5b5',
  gradcolend: '#f0f0f0',
  buttonlabel2: 'add something else here ',
};

/* ********************************** Sign IN ******************** */

//deep copy of Arrays
//numbersCopy = JSON.parse(JSON.stringify(nestedNumbers));



export const dataObject = {
  mainBg: '#000000',   //black
  mainCol: '#ffffff',  //white

  leftmainBg: '#006633',  //green
  leftmainCol: '#ffffff', //white

  rightmainBg: '#E0FFFF',  //
  rightmainCol: '#fe0b0b',

  rightTopNavBg: '#E0FFFF',
  rightTopNavCol: '#006633',

  rightBg: '#E0FFFF',
  rightCol: '#ffffff',

  topLine: 'Because convinience matter',
  headline: 'Blank text here and here too some shit and more shit ',
  description: ' blank bla bla bla bla bla blank bla bla bla bla bla blank bla bla bla bla bla blank bla bla bla bla bla  ',

  lightBg: false,
  primary: true,
  imgStart: '',
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,



  buttonlabel: 'Paydoroz',
  buttonlabel2: 'Get Started',
  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',

  //img: require('../Assets/bank.png'),
  alt: 'jamImage',
  start: '',
  gradcolstar: '#063',
  gradcolmiddle: '#063',
  gradcolend: '#063',
};


export const signInObj = {
  lightBg: false,
  primary: true,
  imgStart: 'row',
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: 'Paydoroz an excel virtual bank',
  headline: 'Put Paydoroz in your Wallet',
  description: ' TEXT: HOW TO GET PAYDOROZ  where from and why  Paydoroz are keyeasy, everywhere, locate a partners',
  buttonlabel: 'Sign Up',

  buttonlink: '/SignUp',
  buttonlink2: '/SignUp',
  /* img: require('../Assets/undercon.png'), */
  alt: 'jamImage',
  start: '',
  gradcolstar: '#b8f7fc',
  gradcolmiddle: '#26ebfc',
  gradcolend: '#b8f7fc',
  buttonlabel2: 'How Much ?',
};


export const userTest = {
  "uid": "rdIbyYSZfPhgmT4U7K4irT0Yv9O2",
  "email": "d.chekroud@gmail.com",
  "emailVerified": false,
  "isAnonymous": false,
  "providerData": [
    {
      "providerId": "password",
      "uid": "sarah@sarah.tit",
      "displayName": null,
      "email": "sarah@sarah.tit",
      "phoneNumber": null,
      "photoURL": null
    }
  ],
  "stsTokenManager": {
    "refreshToken": "AIwUaOkftghP4EbYBY6jHZUukrMKHTxbwuqcyjHw6iB95QBI8vi1l-Dl8XXzk4qVBDp_6qR9Je7B-V-oyj0Rn_ubo5qLxvi8S2nUEowR_aeaxDsw2QIRhp-hbe-RFC1FL7x-GdGrIXFzowy2pdmdzy2Y79tRAr4oRQQnfMSWqn4kf5Wl41z-STqDrXN-MVWd-hGdu2554WOQ",
    "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImY0ZTc2NDk3ZGE3Y2ZhOWNjMDkwZDcwZTIyNDQ2YTc0YjVjNTBhYTkiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZHpkaW5hci1jMDJmMSIsImF1ZCI6ImR6ZGluYXItYzAyZjEiLCJhdXRoX3RpbWUiOjE2NTQyNzgwNTEsInVzZXJfaWQiOiJyZElieVlTWmZQaGdtVDRVN0s0aXJUMFl2OU8yIiwic3ViIjoicmRJYnlZU1pmUGhnbVQ0VTdLNGlyVDBZdjlPMiIsImlhdCI6MTY1NDI3ODA1MSwiZXhwIjoxNjU0MjgxNjUxLCJlbWFpbCI6InNhcmFoQHNhcmFoLnRpdCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJzYXJhaEBzYXJhaC50aXQiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.CTXlo0-cRPMgTaMywC7WPKFx6OKkcsqZxrpE1TSHKYaE8-wsyAmmEMMXcJDeDJHVxn3FwpSYsYFB4qtlm4RLKwfPm_CB65G2kMdnTOKl34Qvlx24lNfDywMBvE4avvBWWzOnqUK2_5E1ar0XmX1bbuYHgP29_vuprbGSsLci3i3-JF8RP8eAc8xdq83GuJAtwdPYCHY7l-EVMxkA7v45QH0eeqXg5GbuZYEuJ3PUPSkoMmDXYXPRkIxdm3XGiq0nVwAQJKl4KDy5Q537WnCZjBxw5E--Vk5zp8oqaBH9X5A7t1Ia_hP35EMhXguiT8RyJJ9eBUq311jX_5r3XEQpHw",
    "expirationTime": 1654281652257
  },
  "createdAt": "1653773972075",
  "lastLoginAt": "1654278051801",
  "apiKey": "AIzaSyAVOiQ97q2nuJoRmpdKydyduHmqBTwhcYQ",
  "appName": "[DEFAULT]"

};


export const userDataRow =
{
  "balance": 0,
  "mrorms": "",
  "fname": "",
  "lname": "",
  "dob": "",
  "addressLine": "",
  "baladiya": "",
  "daira": "",
  "willaya": "",
  "email": "",
  "answer": "",
  "pinnumber": "",
  "question": "",
  "phone": "",
  "password": "",
  "secretanswer": "",
  "acnumber": "",
  "useriddoc": "url to scaned document",
  "useravatar": "url to user avatar image",
  "active": false,
  "emailverified": false,
  "phoneverified": false,
  "idverified": true,
  "transactions": [
    {
      "type": "Balance Brought Forward",
      "from": '',
      "to": '',
      "id": "",
      "amount": 0,
      "createdAt": "",
      "ref": "Account Start"
    }
  ]
};

export const userTranRow = {

  "createdAt": "",
  "from": "",
  "to": "",
  "type": "Transfert",
  "id": "",
  "amount": 0,
  "nf": 0,
  "sf": 0,
  "chf": 0,
  "ref": "Account Start"

};


export const typeOfTransactions =
  [
    "Transfer:Send",
    "Transfer:Receive",
    "Trade:Buy",
    "Trade:Sell",
    "Pay:Send",
    "Pay:Receive",
    "Flexy:Pay",
    "Flexy:Receive",
    "Balance Brought Forward",
    "User Transfer",
    "Partner Trade",
    "Partner Pay",

  ]
  ;





// function copy(aObject) {
// 	// Prevent undefined objects
// 	// if (!aObject) return aObject;

// 	let bObject = Array.isArray(aObject) ? [] : {};

// 	let value;
// 	for (const key in aObject) {

// 		// Prevent self-references to parent object
// 		// if (Object.is(aObject[key], aObject)) continue;

// 		value = aObject[key];

// 		bObject[key] = (typeof value === "object") ? copy(value) : value;
// 	}

// 	return bObject;
// }



// let userList = [];
// for (var i = 0; i < transactions.length; i++) {
// 	userList[i] = transactions[i].ref;
// }


//let copy = JSON.parse(JSON.stringify(object to copy));