import React, {
  useState
  // , useRef
} from 'react';
import { validate } from 'react-email-validator';
import emailjs from "@emailjs/browser";
// import { useNavigate } from "react-router-dom";
import { 
// auth 
  dbb
} from "../../Firebase";
//import { signInWithEmailAndPassword } from "firebase/auth";
//import { usersCollectionRef } from '../../Firebase';
import {
  collection, getDocs, addDoc, updateDoc, doc,
  // deleteDoc,
  query, where
} from 'firebase/firestore'
// import { useUserAuth } from "../../context/UserAuthContex"
/* import styled from "styled-components"; */
// import Transaction from '../../Components/Transaction/Transaction';
// import Contact from '../../Components/Contact/Contact';
import { useNavigate } from "react-router-dom";
// import { Transactions, TransWraper } from './Protec.style';
// import Transaction from '../../Components/Transaction/Transaction';
import {
  // icon_plus,
  icon_minus, icon_equal
} from "../../Assets/Index";
import { ConstValues, userTranRow } from '../../Pages/Protec/Data';

import {
  ChargesWrapper, FeeWraper, FeeWraper2,
  // BalanceRef,
  LabelWrapper,
  LabelWrapperLeft,
  ButtonModal, EmailWrapper,
  // StyledInput,
  StyledInput2,
  AmountWrapper, AmountInput,
  TitleWrapper,
 
  ModalDialogueWrapper,
  // ModalContent,
  Img, StyledError,
  // ContactWraper, ContactWraperInner,
  // InfoRow, InfoColumnLeft, InfoColumnRight,
  // RightColTopNavWrapper, NavLeftWrapper,
  // NavRightWrapper, 
  NavLogoWrapper,
  NavLogoWrapper2, NavBalanceWrapper,
  // StyledRightWrapper, NaveSettingLogo,
  // EmailRef,
  EmailRef2,
  NewContactWrapper,
  // IconAddUser, TitleAddUser,
  AddUserButtonWrapper, PWrapper,
  // StyledAddUserButton,
  StyledTradeBuyButton,
  // StyledTradeBuyButtonRed,
  // SendStyledRightContainer,
  // ListContactWrapper, TitleSelectUser
} from '../Protec/Protec.style';

import {
  Container2, InfoRow, InfoColumnLeft, InfoColumnRight,
  RightColTopNavWrapper, NavLeftWrapper,
  NavRightWrapper,
  RightColBottomWrapper, StyledRightContainer
} from '../../globalStyles';
/* import InfoSectionSignin from '../../Components/InfoSectionSignIn/InfoSectionSignIn';
 */

import SidebarD from '../../Components/SidebarD/SidebarD';
import NavbarD from '../../Components/NavbarD/NavbarD';
// import { userData } from './Data';
import { logoSVG_red } from "../../Assets/Index";

import { useUserAuth } from "../../context/UserAuthContex"

import ModSendInvite from '../../Components/ModSend/ModSendInvite';
import ModSellToPartner from '../../Components/ModSend/ModSellToPartner';
// import { PWrapper } from '../../Components/ModSend/ModSend.style';


const TradeD = () => {


  const { signIn } = useUserAuth();
  // const modalRefX = useRef();
  const navigate = useNavigate();

  const { userData } = useUserAuth();
  // const { userContacts } = useUserAuth();

  const [showMenuBar, setShowMenuBar] = useState(true);
  const [showAskForTrade, setShowAskForTrade] = useState(true);
  const [showTradeBuySelected, setShowTradeBuySelected] = useState(false);
  const [showTradeSellSelected, setShowTradeSellSelected] = useState(false);
  // const [showCalculator, setShowCalculator] = useState(false);
  const [showCalculator2, setShowCalculator2] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const [showContinue2, setShowContinue2] = useState(false);
  const [showContinue3, setShowContinue3] = useState(false);
  const [showCheckEmail, setShowCheckEmail] = useState(false);
  const [showNotFoundResult, setShowNotFoundResult] = useState(false);
  const [showFoundResult, setShowFoundResult] = useState(false);

  const [partnerEmail, setPartnerEmail] = useState('');
  const [isError, setIsError] = useState(true);
  const [Error, setError] = useState("");
  const [amount, setAmount] = useState();
  const [amount2, setAmount2] = useState();
  // const [amountNet, setAmountNet] = useState();
  const [amountNet2, setAmountNet2] = useState();
  const [nf, setNf] = useState(0);
  const [sf, setSf] = useState(0);
  const [chf, setChf] = useState(0);
  // const [feez, setFeez] = useState(0);
  const [feez2, setFeez2] = useState(0);
  // const [pTouch, setPTouch] = useState('');
  // const { setUserContacts } = useUserAuth();

  // const [email, setEmail] = useState("jamel@tesco.net");
  // const [password, setPassword] = useState("");

  // const [error, setError] = useState("");
  // const [isError, setIsError] = useState(true);
  // const [isPsError, setIsPsError] = useState(true);
  // const navigate = useNavigate();

  // const { signIn } = useUserAuth();

  // const [contactsArray, setContactsArray] = useState([]);
  // const [emailTo, setEmailTo] = useState('');


  const [showModSendInvite, setShowModSendInvite] = useState(false);
  const [showModSellToPartner, setShowModSellToPartner] = useState(false);

  // const openModAddContact = () => {
  //   // console.log('clicked add new contact');
  //   setShowModAddContact(prev => !prev);
  // };//====openModAddContact

  const openModSellToPartner = e => {
    console.log('clicked send to selected contact:', e.target.innerText);
    setPartnerEmail(e.target.innerText);
    setShowModSellToPartner(prev => !prev);
  };//====openModSendToContact 



  function netFees() {
    return Number(ConstValues.nf)
  };//=========    sendAmountToContact ================

  function serviceFees(amountToSend) {
    // let tsfee = Math.round(amountToSend * ConstValues.sf);
    if (amountToSend <= 1000) {
      return Number(ConstValues.sffix);
    } else {
      return Number(Math.round(amountToSend * ConstValues.sf) + Number(ConstValues.sffix));
    }
  };//=========    serviceFees ================

  function cashHandlingFees(amountToSend) {
    if (amountToSend <= 1000) {
      return Number(ConstValues.sffix);
    } else {
      return Number(Math.round(amountToSend * ConstValues.chf) + Number(ConstValues.chffix));
    }
  };//=========    serviceFees ================

  function createTransaction(timeF, fromF, toF, typeF, idF, amountF, refF) {
    //  let time = new Date().toString().slice(0, 25);    
    let tran = JSON.parse(JSON.stringify(userTranRow));
    tran.createdAt = timeF;
    tran.from = fromF;
    tran.to = toF;
    tran.type = typeF;
    tran.id = idF;
    tran.amount = Number(amountF);
    tran.nf = netFees(amountF);
    tran.sf = serviceFees(amountF);
    tran.chf = cashHandlingFees(amountF)
    tran.ref = refF;

    return tran
  };//==========   createTransaction

  const continueToDash = () => {
    navigate('/Protec/Dashboard');
  };//========  openPreview

  async function sendPaydorozToBuyer(){

    let tempId = '';

    // setShowAskForAmountButton(false);
    // setShowAskForAmount(false);
    // setShowPreview(false);
     // createTransaction(time, from, to, type, id, amount,ref)
    let time = new Date().toString().slice(0, 25);
    let nfC = netFees(); console.log('nf :', nfC);
    let sfC = serviceFees(amount2); console.log('sfC :', sfC);

    let chfC = cashHandlingFees(amount2); console.log('chfC :', chfC);

    let mainTransR = createTransaction(time, userData.email, partnerEmail, "Partner Trade", '', amount2, "Partner Trade");
    let mainTrans = JSON.parse(JSON.stringify(mainTransR));
    //  setTimeOfTransaction(mainTrans.createdAt);
    let sendTransR = createTransaction(time, userData.email, partnerEmail, "Trade:Sell", '', amount2, "Thanks For buying my Paydoroz");
    let sendTrans = JSON.parse(JSON.stringify(sendTransR));
                  //createTransaction(timeF,  fromF,  toF,  typeF, idF, amountF,refF)

    let recTrans = createTransaction(time, userData.email, partnerEmail, "Trade:Buy", '', amount2, "Thanks for The Service");

    //save tranTransfert to  transCollection 
    //get doc id from transCollection    
    //====== start part 1   
    console.log('*********** Start tranCollection ************');
    try {
      const docRef = addDoc(collection(dbb, "tranCollection"), mainTrans);
      tempId = (await docRef).id;
      sendTrans.id = tempId;
      recTrans.id = tempId;
    } catch (e) {
      setIsError(true); setError(e);
    }

    console.log('*********** end tranCollection ************');
    // ==============   
    let tSenderData = {};
    // let tTransactions = [];
    let tSendToken = ';'
    let tBalance = 0;
    //update sender balance and record 

    const qSender = query(collection(dbb, "users"), where("email", "==", userData.email));
    const querySnapshot = await getDocs(qSender);
    querySnapshot.docs.forEach((doc) => {
      tSendToken = doc.id;
      tSenderData = JSON.parse(JSON.stringify(doc.data()));
    });

    tBalance = tSenderData.balance;


    // tTransactions = JSON.parse(JSON.stringify(tSenderData.transactions));      
    //***********************************************
    // check balance positive before continue
    //***********************************************

    // tTransactions.unshift(sendTrans);
    tSenderData.transactions.unshift(sendTrans);

    tSenderData.balance = Number(tBalance) - Number(amount2);
    const sendRef = doc(dbb, 'users', tSendToken);
    // updateDoc(sendRef, { balance: Number(tBalance) - Number(amount2), transactions: tTransactions }).then(() => { });
    updateDoc(sendRef, tSenderData).then(() => { console.log('shit') });
    //catch any errors     
    //====== end  part 1

    //====== start  part 2


    // ==============   
    let tRecData = {};
    let tRecTransactions = [];
    let tRecToken = '';
    let tRecBalance = 0;
    //update sender balance and record 
    const qRec = query(collection(dbb, "users"), where("email", "==", partnerEmail));
    const queryRecSnapshot = await getDocs(qRec);
    queryRecSnapshot.docs.forEach((doc) => {
      tRecToken = doc.id;
      tRecData = JSON.parse(JSON.stringify(doc.data()));
      tRecToken = doc.id;
    });
    tRecBalance = tRecData.balance;

    tRecTransactions = JSON.parse(JSON.stringify(tRecData.transactions));
    //***********************************************
    // check balance positive before continue
    //***********************************************

    tRecTransactions.unshift(recTrans);
    tRecData.balance = Number(tRecBalance) + Number(amount2) - Number(nfC) - Number(sfC);
    const RecRef = doc(dbb, 'users', tRecToken);
    updateDoc(RecRef, { balance: Number(tRecBalance) + Number(amount2), transactions: tRecTransactions }).then(() => { });
    //catch any errors
    //====== end  part 2
    localStorage.setItem("userData", JSON.stringify((tSenderData)));
    //===============    
//sendEmailSellReceiptViaEmailJs(toEmail,atTime,fromEmail,netPaydoroz,forTotalCash)
    sendEmailSellReceiptViaEmailJs(partnerEmail, time, userData.email, amountNet2, amount2);
    // update both sender and receiver as transaction or batch
    // ************************    setShowCompleted(true);  *****************************
   //return to
    //  navigate('/Protec/Dashboard');
    setShowContinue3(true);
    setShowContinue2(false);
    setShowCalculator2(false);
    setShowFoundResult(false);
    setShowTradeSellSelected(false);

  };//========  sendPaydorozToBuyer

  // const openModAddContactAdd = () => {
  //   // console.log('clicked add new contact');
  //   setShowModAddContact(prev => !prev);
  // };//====openModAddContact

  const tradeBuySelected = e => {
    console.log('clicked tradeBuySelected');
    //Hide The Sell Section 
    setShowTradeBuySelected(true);    
    setShowAskForTrade(false);
    setShowMenuBar(false);


    // setEmailTo(e.target.innerText);
    // setShowModSellToContact(prev => !prev);
  };//====tradeBuySelected

  const tradeSellSelected = e => {
    console.log('clicked tradeSellSelected');
    //hide the Buy Section 
    setShowTradeSellSelected(true);
    setShowAskForTrade(false);
    setShowMenuBar(false);




  };//====tradeSellSelected



  const handleInputBuy = e => {
    const value = e.currentTarget.value;

    let nfV = netFees();
    let sfV = serviceFees(value);
    let chfV = cashHandlingFees(value);
    let feezV = Number(nfV + sfV + chfV);
    setAmountNet2(Number(0)); 
    setShowContinue(false);
    //=============== 
    if (value < ConstValues.minAmount) {
      setIsError(true); setError('Min Amount to Spend ' + ConstValues.minAmount); setShowContinue(false);
      setAmount(value); setAmountNet2(0); 
      setNf(nfV); setSf(sfV); setChf(chfV);
      // setFeez(feezV); 
    
    }
    else if (value > 9999) {
      setIsError(true); setError('Max 9999'); setShowContinue(false);
      setAmount(value);
      setNf(nfV); setSf(sfV); setChf(chfV);
      // setFeez(feezV);
    }
    else {
      setIsError(false); setError(''); setShowContinue(true);
      setAmount(value);
      setNf(nfV); setSf(sfV);
      setChf(chfV);
      // setFeez(feezV); 
      setAmountNet2(Number(value) - feezV);

    }
    
    // 


    //================ 
  };//=======  handleInputBuy



  const handleInput2 = e => {
    const value = e.currentTarget.value;

    let nfV = netFees();
    let sfV = serviceFees(value);
    let chfV = cashHandlingFees(value);
    let feezV = Number(nfV + sfV + chfV);
    setAmountNet2(Number(value) + feezV);  
    setShowContinue(false);
    setNf(nfV);
    setSf(sfV);
    setChf(chfV);
    //=============== 
    // let regex = /^[1-9]\d*$/;
    if (value < ConstValues.minAmount) {
    setAmount(value);
    setIsError(true);
    setError('Min Amount to Send ' + ConstValues.minAmount);
    // setShowCalculator2(false);
    // setShowAskForPreview(false)
    setShowContinue(false);
    }
     else if (value < 0) {
      setIsError(true); setError('No Negatif Numbers please'); setShowContinue2(false);
      setAmount2(value);
      setNf(nfV); setSf(sfV); setChf(chfV); setFeez2(feezV); 
    
    }
    else if (value > 9999) {
      setIsError(true); setError('Max 9999'); setShowContinue2(false);
      setAmount2(value);
      setNf(nfV); setSf(sfV); setChf(chfV); setFeez2(feezV);
    }
    else {
      setIsError(false); setError(''); setShowContinue2(true);
      setAmount2(value);
      setNf(nfV); setSf(sfV);
      setChf(chfV);
      setFeez2(feezV); 
      setAmountNet2(Number(value) - feezV);

    }
    
    // 


    //================ 
  };//=======  handleInput2

  const handleEmailInput = e => {
    const value = e.currentTarget.value;
    if (validate(value)) {
      setIsError(false); setError('');     // the email is valid
      setShowCheckEmail(true);
    } else {
      setIsError(true); setError('Please Enter a valid Email');     // the email is invalid
      setShowCheckEmail(false);
    }
    setPartnerEmail(value);    
  };//=======  handleEmailInput


  function findEmail() {
    console.log(' *£££££ checkEmail £££££*');
    // let answer = '';
    //  Password input 12345678
    signIn(partnerEmail, '1234aTKzT5678')
      .then((userCredential) => { })
      .catch((error) => {
        console.log('Jam checkEmail : we have an ERROR at signing in ');
        console.log('error code ', error.code);

        setError(error.code); setIsError(true);
        if (error.code === "auth/wrong-password") {
          console.log(' exist =======');
          // setIsMember(true);
          setError(''); setIsError(true);
          // setShowAskForEmail(false);
          setShowNotFoundResult(false);
          setShowModSellToPartner(prev => !prev);
          // setShowFoundResult(true);
          // setShowCheckEmail(false);
          // setShowCalculator2(true);
        } else if (error.code === "auth/user-not-found") {
          console.log(' DOES not exist =======');
          // setIsMember(false);
          setError(error.code); setIsError(true);
          // setShowAskForEmail(false);   
          setShowNotFoundResult(true);
          setShowFoundResult(false);
          setShowModSendInvite(prev => !prev);
        }
        else if (error.code === "auth/too-many-requests") {
        console.log(' Too Many attempts =======');
        // setIsMember(false);
        setError(error.code); setIsError(true);
        // setShowAskForEmail(false);
        // setShowModSendInvite(prev => !prev);
        // setShowNotFoundResult(true);
        // setShowFoundResult(false);
      }  
        else {
          setError("Sorry can't find the Email, ask them yourself to join");
          setIsError(true);
        }
      });
    // //=====simulation
    // // setIsMember(true);
    // setError(''); setIsError(true);
    // setShowAskForEmail(false);
    // setShowNotFoundResult(false);
    // setShowFoundResult(false);//change these after you allow email    
    // //=====simulation
  };//=========    findEmail ================



  const sendInvitation = e => {
    // setShowNotFoundResult(false);
    // setError(''); setIsError(true);
    // setShowInvitationSent(true);
    // setShowContinueToDash(true);
    //attention *********************************************************
    //sendEmailInviteViaEmailJs(toEmail,from_emaile)
    //sendEmailInviteViaEmailJs(partnerEmail,userData.email)
    //attention *********************************************************
    console.log("sendInvitation : Sending an invitation to :", partnerEmail);
    navigate("/Protec/Dashboard");
  };//====== sendInvitation

  // const addContactToList = () => {
  //   console.log("adding contact to lst of Contacts ");
  // };//======= addContactToList

  // const openModSendToContact = () => {
  //   console.log('clicked add new contact');
  //   setShowModSellToContact(prev => !prev);
  // };//=========

  function sendEmailSellReceiptViaEmailJs(toEmail,atTime,fromEmail,netPaydoroz,forTotalCash) {
    let templateParams = {
      to_email: toEmail,
      from_email: fromEmail,
      at_time: atTime,
      net_bought: netPaydoroz,
      total_paid: forTotalCash,
      fees_paid: forTotalCash - netPaydoroz      
    };
    console.log("templateParams : ", templateParams);
   
    emailjs
      .send(
        "service_195ei3n", //YOUR_SERVICE_ID
        "template_p9qktu9", //YOUR_TEMPLATE_ID
        templateParams, //own parameters
        "31JSp7p9rt0D9nmq9" //YOUR_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  } //===========  sendEmailSellReceiptViaEmailJs  ============


  return (
    <Container2>
      <InfoRow>
        {/*   =================left Column=============== */}
        <InfoColumnLeft> <SidebarD />  </InfoColumnLeft>
        {/*   =================Right column=============== */}
        <InfoColumnRight>
          {/*   =================Right column=============== */}
          <RightColTopNavWrapper>

            <NavLeftWrapper>
              {/* <NavLogoWrapper>
                  <img src={logoSVG_red} alt="logo" />
                  </NavLogoWrapper>  */}
              <NavBalanceWrapper>
                {userData.fname}
              </NavBalanceWrapper>
            </NavLeftWrapper>

          <NavRightWrapper>
            <NavLogoWrapper>
              <img src={logoSVG_red} alt="logo" />
            </NavLogoWrapper>
            <NavBalanceWrapper>
              {Intl.NumberFormat().format(userData.balance)}
            </NavBalanceWrapper>
          </NavRightWrapper>

        </RightColTopNavWrapper>
        {/*   =================Right Bottom column=============== */}
        <RightColBottomWrapper>
          <StyledRightContainer>  

          
        {showAskForTrade &&
                <>
                <br/>
                <TitleWrapper>Choose your Trade</TitleWrapper>     
                {/* <LabelWrapper>Choose your Trade</LabelWrapper> */}
            {/* <NavBalanceWrapper>Choose your Trade</NavBalanceWrapper>     */}
            <NewContactWrapper> 
                <AddUserButtonWrapper>
                  <StyledTradeBuyButton onClick={tradeBuySelected}>Buy</StyledTradeBuyButton>
                </AddUserButtonWrapper>
                <AddUserButtonWrapper>
                    <StyledTradeBuyButton onClick={tradeSellSelected}>Sell</StyledTradeBuyButton>
                </AddUserButtonWrapper>
              </NewContactWrapper>  
          </>      
        }     
 
        {showTradeBuySelected &&
          <>
            <TitleWrapper>Trade Buy</TitleWrapper>
            <ModalDialogueWrapper color={`#006633`} colortwo={`#FE0B0B`}>
              <br />
              Please Choose the ammount and show your Email to The seller and give the cash to them. They will send you your Paydoroz.
              check you received them.  <br />
              <strong >{userData.email} </strong>
            </ModalDialogueWrapper>
            <br />
            <LabelWrapperLeft>You Are Paying </LabelWrapperLeft>
            <AmountWrapper>
              <AmountInput autoFocus
                type="number"
                    name="amount"
                value={amount}
                onChange={handleInputBuy} />

              {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
              }
            </AmountWrapper>
                
                <ChargesWrapper>
                  

                  <FeeWraper>
                    <NavLogoWrapper2> <img src={icon_minus} alt="minus" />
                    </NavLogoWrapper2>
                    <FeeWraper2>
                      <EmailRef2> {nf} </EmailRef2>
                      <EmailRef2> Network Fee </EmailRef2>
                    </FeeWraper2>
                  </FeeWraper>
                  
                  <FeeWraper>    <NavLogoWrapper2> <img src={icon_minus} alt="minus" /> </NavLogoWrapper2>
                    <FeeWraper2> <EmailRef2> {sf} </EmailRef2> <EmailRef2> Service Fee </EmailRef2> </FeeWraper2> </FeeWraper>
                  <FeeWraper>
                    <NavLogoWrapper2>
                      <img src={icon_minus} alt="minus" />
                    </NavLogoWrapper2>
                    <FeeWraper2>
                      <EmailRef2> {chf} </EmailRef2>
                      <EmailRef2> Cash Handling Fee </EmailRef2>
                    </FeeWraper2>
                  </FeeWraper>
                  <FeeWraper>  <NavLogoWrapper2> <img src={icon_equal} alt="equal" /> </NavLogoWrapper2>
                    <FeeWraper2> <EmailRef2>{nf + sf+chf} </EmailRef2><EmailRef2> Total Fees </EmailRef2> </FeeWraper2>  </FeeWraper>
                </ChargesWrapper>

                <LabelWrapperLeft>You Get Paydoroz </LabelWrapperLeft>
                <AmountWrapper> <AmountInput readOnly type="number" name="amountNet2" value={amountNet2} />
                  {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                    (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)}
                </AmountWrapper>
                {<br />}  
           

                {isError && <StyledError>{Error}</StyledError>}
    
              {showContinue && <ButtonModal onClick={continueToDash}>Continue</ButtonModal>} <br /> <br /> <br/> <br/>
   
                
        </>      
        }  
   
        {/* ========Selling Paydoroz===================== */}
        {showTradeSellSelected &&
          <>
            <TitleWrapper>Trade Sell</TitleWrapper>   <br/>
            {/* <NavBalanceWrapper>Trade Sell jjjjj</NavBalanceWrapper> */}
            <LabelWrapperLeft>Please Enter Email of Partner</LabelWrapperLeft>            
            <EmailWrapper>
              <StyledInput2 autoFocus
                type="email"
                name="shopEmail"
                value={partnerEmail}
                onChange={handleEmailInput} />
              {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
              }
            </EmailWrapper>  
            {showCheckEmail &&
              <NewContactWrapper> 
              <AddUserButtonWrapper>
              <StyledTradeBuyButton bgCol={'#006633'} onClick={findEmail}>let us Find Email</StyledTradeBuyButton>
              </AddUserButtonWrapper>
              </NewContactWrapper> 
            }               
            </>
          }
          
              {showNotFoundResult && <>
                <NewContactWrapper>
                  <AddUserButtonWrapper>
                    <StyledTradeBuyButton bgCol={'#FE0B0B'} onClick={sendInvitation}>Can't Send</StyledTradeBuyButton>
                  </AddUserButtonWrapper>
                </NewContactWrapper>  
              </>
              }

              {showFoundResult && <>
                <NewContactWrapper>
                  <AddUserButtonWrapper>
                    <StyledTradeBuyButton bgCol={'#006633'} onClick={openModSellToPartner}>Email Ok</StyledTradeBuyButton>
                  </AddUserButtonWrapper>
                </NewContactWrapper> 
              </>
              }
              {showCalculator2 &&
                <>
                  <LabelWrapper>You are Selling Paydoroz</LabelWrapper>
                  <AmountWrapper>
                    <AmountInput autoFocus type="number" name="amount2" value={amount2}
                      onChange={handleInput2} />
                    {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                      (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)
                    }
                  </AmountWrapper>

                <ChargesWrapper>
                  <FeeWraper>    <NavLogoWrapper2> <img src={icon_minus} alt="minus" /> </NavLogoWrapper2>
                      <FeeWraper2> <EmailRef2> {chf} </EmailRef2> <EmailRef2> Cash Handling Fee </EmailRef2></FeeWraper2> </FeeWraper>
                  {/* <FeeWraper>  <NavLogoWrapper2> <img src={icon_equal} alt="equal" /> </NavLogoWrapper2>
                    <FeeWraper2> <EmailRef2>{amount2 - chf} </EmailRef2><EmailRef2> Amount To Send </EmailRef2> </FeeWraper2>  </FeeWraper> */}

                    <FeeWraper>    <NavLogoWrapper2> <img src={icon_minus} alt="minus" /> </NavLogoWrapper2>
                      <FeeWraper2> <EmailRef2> {nf} </EmailRef2> <EmailRef2> Network Fee </EmailRef2> </FeeWraper2> </FeeWraper>
                  <FeeWraper>    <NavLogoWrapper2> <img src={icon_minus} alt="minus" /> </NavLogoWrapper2>
                      <FeeWraper2> <EmailRef2> {sf} </EmailRef2> <EmailRef2> Service Fee </EmailRef2> </FeeWraper2> </FeeWraper>
                  
                    <FeeWraper>  <NavLogoWrapper2> <img src={icon_equal} alt="equal" /> </NavLogoWrapper2>
                      <FeeWraper2> <EmailRef2>{feez2} </EmailRef2><EmailRef2> Total Fees </EmailRef2> </FeeWraper2>  </FeeWraper>
                  </ChargesWrapper>

                  <LabelWrapper>You will get Cash</LabelWrapper>
                  <AmountWrapper> <AmountInput readOnly type="number" name="amountNet2" value={amountNet2} />
                    {isError ? (<Img src={require('../../Assets/cancel1.jpg')} alt={'cancel'} />) :
                      (<Img src={require('../../Assets/check.png')} alt={'cancel'} />)}
                  </AmountWrapper>

                  {isError && <StyledError>{Error}</StyledError>}

                  {showContinue2 && <ButtonModal onClick={sendPaydorozToBuyer}>Send Paydoroz</ButtonModal>}  <br /> <br />
                </>
              }    

{/* ======================================================================================= */}
              {showContinue3 &&
                <> 
                <PWrapper color={`#000000`}>                  
                  <br /><br /> <strong> Receipt</strong> <br />
                </PWrapper>
                <PWrapper color={`#000000`}>
                  Please let the customer know that an a Receipt has been sent to his email
                  <br /><br /> <strong> {partnerEmail}</strong> <br />
                </PWrapper>
                  <ButtonModal onClick={continueToDash}>Continue To Dash</ButtonModal>  </>
              }  <br /> <br />
              
              {/* {(!showModAddContact && !showModSellToContact) && <NavbarD />} */}
              {showMenuBar && <NavbarD />} 

        
          </StyledRightContainer>
          <NavbarD />
        </RightColBottomWrapper> 
      </InfoColumnRight>

        <ModSellToPartner showModal={showModSellToPartner} setShowModal={setShowModSellToPartner} selectedEmail={partnerEmail} />
        <ModSendInvite showModalAdd={showModSendInvite} setShowSendInvite={setShowModSendInvite} />
        {/* <ModSendToContact showModal={showModSellToContact} setShowModal={setShowModSellToContact} /> */}

      </InfoRow>
    </Container2>
  )


};

export default TradeD;