import React from 'react';
import Navbar from '../../Components/Navbar/Navbar'; 
//import { tradeObjOne, tradeObjTwo, tradeObjThree, tradeObjFour, tradeObjFive, tradeObjSix, tradeObjSeven } from '../Data';

import { useTranslation} from 'react-i18next';


const Help = () => {

 const [t, i18n] = useTranslation('');

 return (
  <>
   <Navbar />
   <br />
   <h1>***** help page ****</h1><br />
   <br />
 
 {/*   <h1> {t("home.body")} </h1>  */}
  
   <h3>** I forgot my password</h3><br />
   <h3>** I forgot my Email</h3><br />
   <h3>** I dont have an account</h3><br />
   <h3>** My phone Number Changed</h3><br />
   <h3>** My Details Changed</h3><br />
   <h3>** i need to sell my Paydoroz </h3><br />
   <h3>** I forgot my password</h3><br />
  </>
 )
}

export default Help;