import React, { useState, useRef } from 'react';
// import { useNavigate } from "react-router-dom";
//import { auth } from "../../Firebase";
//import { signInWithEmailAndPassword } from "firebase/auth";
//import { usersCollectionRef } from '../../Firebase';
//import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore'
// import { useUserAuth } from "../../context/UserAuthContex"
/* import styled from "styled-components"; */
// import Transaction from '../../Components/Transaction/Transaction';
// import Contact from '../../Components/Contact/Contact';

// import { Transactions, TransWraper } from './Protec.style';
// import Transaction from '../../Components/Transaction/Transaction';


import {
  // Transactions,
  // OfferWraper,
  // RefWraper, EmailRefFrom, BalanceRef,
  // TransWraper,
  // OffersWraper,
  // ChargesWrapper, FeeWraper, FeeWraper2,
  // BalanceRef,
  // LabelWrapper,
  // ButtonModal, StyledError,
  // EmailWrapper,
  // StyledInput,
  // StyledInput2, Img, Img2,
  // AmountWrapper, AmountInput,
  // ModalContent,
  // ,  
  // StyledAddUserButton,
  ContactWraper, ContactWraperInner, 
  NavLogoWrapper,
  // NavLogoWrapper2,
  NavBalanceWrapper,
  // TitleWrapper,
  // StyledRightWrapper,
  // NaveSettingLogo,
  EmailRef,
  // EmailRef2,
  NewContactWrapper, AddUserButtonWrapper,
  // StyledTradeBuyButton,
  IconAddUser, 
  TitleAddUser,
  // PWrapper, RDialogueWrapper,
  StyledAddUserButton,
  // StyledAddUserButtonOffer,
  // AddUserButtonWrapperOffer,
  // StyledTradeBuyButtonRed,
  // SendStyledRightContainer,
  ListContactWrapper, TitleSelectUser
} from './Protec.style';
import {
  Container2, InfoRow, InfoColumnLeft, InfoColumnRight,
  RightColTopNavWrapper, NavLeftWrapper,
  NavRightWrapper,
  RightColBottomWrapper, StyledRightContainer
} from '../../globalStyles';
/* import InfoSectionSignin from '../../Components/InfoSectionSignIn/InfoSectionSignIn';
 */

import SidebarD from '../../Components/SidebarD/SidebarD';
import NavbarD from '../../Components/NavbarD/NavbarD';
// import { userData } from './Data';
import { logoSVG_red } from "../../Assets/Index";

import { useUserAuth } from "../../context/UserAuthContex"

import ModAddContact from '../../Components/ModSend/ModAddContact';
import ModSendToContact from '../../Components/ModSend/ModSendToContact';


const SendD = () => {

  const modalRefX = useRef();


  const { userData } = useUserAuth();
  const { userContacts } = useUserAuth();
  // const { setUserContacts } = useUserAuth();

  // const [email, setEmail] = useState("jamel@tesco.net");
  // const [password, setPassword] = useState("");

  // const [error, setError] = useState("");
  // const [isError, setIsError] = useState(true);
  // const [isPsError, setIsPsError] = useState(true);
  // const navigate = useNavigate();

  // const { signIn } = useUserAuth();

  // const [contactsArray, setContactsArray] = useState([]);
  const [emailTo, setEmailTo] = useState('');


  const [showModAddContact, setShowModAddContact] = useState(false);
  const [showModSendToContact, setShowModSendToContact] = useState(false);
  // const [showModSendPreview, setShowModSendPreview] = useState(false);


  const openModAddContact = () => {
    // console.log('clicked add new contact');
    setShowModAddContact(prev => !prev);
  };//====openModAddContact

  const openModSendToContact = e=> {    
    console.log('clicked send to selected contact:', e.target.innerText);
    setEmailTo(e.target.innerText);    
    setShowModSendToContact(prev => !prev);
  };//====openModSendToContact 





  return (

    <Container2>
      <InfoRow>
        {/*   =================left Column=============== */}
        <InfoColumnLeft> <SidebarD />  </InfoColumnLeft>
        {/*   =================Right column=============== */}
        <InfoColumnRight>
          {/*   =================Right column=============== */}
          <RightColTopNavWrapper>

            <NavLeftWrapper>
              {/* <NavLogoWrapper>
                 <img src={logoSVG_red} alt="logo" />
                </NavLogoWrapper>  */}
              <NavBalanceWrapper>
                {userData.fname}
              </NavBalanceWrapper>
            </NavLeftWrapper>

            <NavRightWrapper>
              <NavLogoWrapper>
                <img src={logoSVG_red} alt="logo" />
              </NavLogoWrapper>
              <NavBalanceWrapper>
                {Intl.NumberFormat().format(userData.balance)}
              </NavBalanceWrapper>
            </NavRightWrapper>

          </RightColTopNavWrapper>
      {/*   =================Right Bottom column=============== */}
          <RightColBottomWrapper>
            <StyledRightContainer>     
           
              <NewContactWrapper>
          
                <IconAddUser >
                  <svg width="28" height="20" viewBox="0 0 64 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M45.2319 22.8732V19.9508H53.1547V12.0278H56.0771V19.9508H64V22.8732H56.0771V30.7957H53.1547V22.8732H45.2319Z" fill="#006633" />
                    <path d="M13.4656 29.6965H35.5831C43.0081 29.6965 49.0488 35.7373 49.0488 43.1623V50.9412H-0.000110462V43.1623C-0.000110462 35.7375 6.04063 29.6965 13.4656 29.6965ZM2.92226 48.019H46.1265V43.1624C46.1265 37.3489 41.3969 32.6191 35.5831 32.6191H13.4658C7.65223 32.6191 2.92246 37.3489 2.92246 43.1624L2.92226 48.019Z" fill="#006633" />
                    <path d="M24.5243 28.3131C16.7185 28.3131 10.368 21.9624 10.368 14.1566C10.368 6.35071 16.7185 0 24.5243 0C32.3302 0 38.6809 6.35051 38.6809 14.1566C38.6809 21.9626 32.3302 28.3131 24.5243 28.3131ZM24.5243 2.92257C18.3299 2.92257 13.2903 7.96211 13.2903 14.1568C13.2903 20.3514 18.3299 25.391 24.5243 25.391C30.719 25.391 35.7585 20.3512 35.7585 14.1568C35.7585 7.9623 30.719 2.92257 24.5243 2.92257Z" fill="#006633" />
                  </svg>
                   
                </IconAddUser>
                  <TitleAddUser >Please Add New User </TitleAddUser>
             
                <AddUserButtonWrapper>
                  <StyledAddUserButton onClick={openModAddContact}>Add</StyledAddUserButton>
                </AddUserButtonWrapper>
              </NewContactWrapper>

              <ListContactWrapper>

                <TitleSelectUser >
                  Or Select User From List
                </TitleSelectUser>

                {/* <Transactions> Transactions   </Transactions> */}

                {/* <AddUserButtonWrapper>
                  <StyledAddUserButton>Add</StyledAddUserButton>
                </AddUserButtonWrapper> */}

                <ContactWraper >
                  <div>
                    {userContacts.map((tr, index) =>
                    (<div key={index}>
                      <ContactWraperInner color={'#e6fff3'}>
                        <EmailRef onClick={openModSendToContact} ref={modalRefX}> {tr} </EmailRef>
                      </ContactWraperInner>

                    </div>))
                    }
                  </div>
                </ContactWraper>

              

              </ListContactWrapper>
              {(!showModAddContact && !showModSendToContact) && <NavbarD /> }
              
              {/*  </InfoRow>    */}
        
           
          </StyledRightContainer>
          <NavbarD />
        </RightColBottomWrapper> 
        </InfoColumnRight>

        <ModSendToContact showModal={showModSendToContact} setShowModal={setShowModSendToContact} selectedEmail={ emailTo} />
        <ModAddContact showModalAdd={showModAddContact} setShowModalAdd={setShowModAddContact} />
        {/* <ModSendPreview showModal={showModSendPreview} setShowModal={setShowModSendPreview} selectedEmail={emailTo} /> */}

      </InfoRow>
    </Container2>
  )


};

export default SendD;