import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Button } from '../../globalStyles';
import {
  IconWrapper, FlagWrapper, FlagIcon, DropWrapper,
 Nav, NavbarContainer, NavLogo, MobileIcon,
 NavMenu, NavItem, NavLinks,
 NavItemBtn, NavBtnLink, NavDropUl
} from './Dropdown.style'

/* import { useTranslation } from 'react-i18next'; */
import i18next from 'i18next'
import gbFlag from './../../Assets/gb.svg';// Replace this with your flag SVG files
import dzFlag from './../../Assets/dz.svg';



import { MenuItems } from './MenuItems'; import './Dropdown.style.js';
import { Link } from 'react-router-dom';



const flagsMap = {
  en: gbFlag,
  ar: dzFlag,
  // Add other country codes and flag imports
};

const CountryFlag = ({ code }) => {
  const flag = flagsMap[code];

  if (!flag) {
    return <div>Flag not found</div>; // Handle missing flags
  }

  return <FlagIcon src={flag} alt={`Flag of ${code}`} />;
};



const DropdownButton = styled.button`
  background-color: #f1f1f1;
  color: #333;
  padding: 10px;
  border: none;
  cursor: pointer;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  margin-top: 60px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownItem = styled.div`
  padding: 10px;
  display: flex;
  justify-content:space-between;
  &:hover {
    background-color: #ddd;
  }
`;

const Dropdown = (props) => {

  

 /*  const [lng,setLng]=useState('en') */

   
  const [lng, setLng] = useState(props.lng);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleItemClick = (code, name, country_code) => {
    console.log('name : ', name, '    code : ',code); 
    //setSelectedOption(name);
    //setIsOpen(false);
    switch (code) {
      case "en": { setLng("ar"); i18next.changeLanguage("ar") }
        // code block
        break;
      case "ar": { setLng("en"); i18next.changeLanguage("en") }
        // code block
        break;
      default: { setLng("en"); i18next.changeLanguage("en") }
      // code block
    }
    

    
  };

  return (
    <DropWrapper>
     
      <FlagWrapper onClick={() => handleItemClick(lng)} >      
      {console.log('country rrrrrr code here  : ',lng)} 
      <CountryFlag code={lng} />
      
      {isOpen && <> 
        <DropdownContent isOpen={isOpen}>
         {/*  {props.lngsetLng(props.lng)} */}
          {props.Languages.map(
            
            ({ code, name, dir, country_code }) =>
            ( <>
                <DropdownItem key={country_code} onClick={() => handleItemClick(code, name, country_code)} >
                <CountryFlag code={code} />
                {name}
                </DropdownItem>                
              </>
            )
            
          )} 

        </DropdownContent>
      </>
      }
      </FlagWrapper> 
      </DropWrapper>
  );
};

export default Dropdown;

