import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContex";

const ProtectedRoute = () => {

 const { user } = useUserAuth();

 /*  console.log("Check user in Private: ", user); */

 return user? <Outlet /> : <Navigate to="/SignIn" />
 
}
export default ProtectedRoute;